export const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2},
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
]
  
export const  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const  currentYear = new Date().getFullYear();
  
  
 
  