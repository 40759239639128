import styled from "styled-components";
import {
    colors
} from "../../../components/common/element/elements.js";

export const ModalStyles = styled.div`
    .sidebar-style {
        width: 230px;
        height: 100%;
        background: #2A3F54;
        display: flex
    }

    .error-message {
        color      : #e70606;
        font-weight: 300;
        position   : relative;
        bottom     : 11px;
        // left       : 0;
    }

    .menu-item {
        display: block;
        padding: 10px;
        color: #fdfdfd;
        margin: 15px;
        border-bottom: 1px solid rgb(35, 50, 66);
    }

    .modal-padding {
        padding: 1rem 6rem !important;
      }

    .modal-header {
        padding: 3rem 1rem !important;
        border-bottom: none !important;
    }  
    select option{
        text-transform: capitalize;
    }
    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: 0.75rem;
        border-top: none !important;
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);
    }

    .react-time-picker__wrapper {
        display: flex;
        border: none !important;
    }

    .react-time-picker__button svg {
        display: none;
    }

    @media(max-width: 576px) {
        .modal-padding {
            padding: 1rem 1rem !important;
          }
    }
`;