import {
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  GET_COURSES,
  UPDATE_COURSE,
  GET_COURSE_TYPES,
  DELETE_COURSE,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  GET_BATCHES,
  UPDATE_BATCH,
  DELETE_BATCH,
  GET_BATCH_TYPES,
  GET_USERS,
  UPDATE_ROLE,
  GET_TOPICS,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  UPDATE_BATCH_TYPE,
  DELETE_BATCH_TYPE,
  ASSIGN_COURSE,
  GET_ADMIN_COURSES,
  GET_DEACTIVATED_COURSES,
  GET_HOME_DETAILS,
  GET_ENROLL,
  GET_CATEGORIES,
  GET_CURRENCIES,
  ADD_COURSE, 
  GET_CONTACT
} from "../actions/types";

const adminReducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case UPDATE_CATEGORY: {
      return {
        ...state,
        updateCategory: payload
      }
    }
    case DELETE_CATEGORY: {
      return {
        ...state,
        deleteCategory: payload
      }
    }
    case GET_COURSES: {
      return {
        ...state,
        courses: payload
      }
    }

    case GET_COURSE_TYPES: {
      return {
        ...state,
        courseTypes: payload
      }
    }
    case DELETE_COURSE: {
      return {
        ...state,
        deleteCourse: payload
      }
    }
    case ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        loginSuccess: payload
      }
    }
    case ADMIN_LOGIN_ERROR: {
      return {
        ...state,
        loginError: payload
      }
    }
    case GET_BATCHES: {
      return {
        ...state,
        batches: payload
      }
    }
    case UPDATE_BATCH: {
      return {
        ...state,
        updateBatch: payload
      }
    }
    case DELETE_BATCH: {
      return {
        ...state,
        deleteBatch: payload
      }
    }
    case GET_BATCH_TYPES: {
      return {
        ...state,
        batchTypes: payload
      }
    }
    case GET_USERS: {
      return {
        ...state,
        users: payload
      }
    }
    case UPDATE_ROLE: {
      return {
        ...state,
        role: payload
      }
    }
    case GET_TOPICS: {
      return {
        ...state,
        topics: payload
      }
    }
    case UPDATE_TOPIC: {
      return {
        ...state,
        updateTopic: payload
      }
    }
    case DELETE_TOPIC: {
      return {
        ...state,
        deleteTopic: payload
      }
    }
    case UPDATE_BATCH_TYPE: {
      return {
        ...state,
        updateBatchType: payload
      }
    }
    case DELETE_BATCH_TYPE: {
      return {
        ...state,
        deleteBatchType: payload
      }
    }
    case ASSIGN_COURSE: {
      return {
        ...state,
        assignCourse: payload
      }
    }
    case GET_ADMIN_COURSES: {
      return {
        ...state,
        adminCourses: payload
      }
    }
    case GET_DEACTIVATED_COURSES: {
      return {
        ...state,
        deactivatedCourses: payload
      }
    }
    case UPDATE_COURSE: {
      return {
        ...state,
        adminCourses: state.adminCourses.map(item => item.course_id === payload.course_id ? {
          ...item,
          ...payload
        } : item)
      }
    }
    case GET_HOME_DETAILS: {
      return {
        ...state,
        home: payload
      }
    }
    case GET_ENROLL: {
      return {
        ...state,
        enroll: payload
      }
    }
    case GET_CATEGORIES: {
      return {
        ...state,
        adminCategories: payload
      }
    }
    case ADD_COURSE: {
      return {
        ...state,
        addCourse: {
          payload,
          ...state.addCourse
        }
      }
    }
    case GET_CONTACT: {
      return {
        ...state,
        contacts: payload
      }
    }
    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: payload
      }
    }
    default:
      return state;
  }
}

export default adminReducer;