import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Card, Row, Col } from 'react-bootstrap';

import { getHomeDetails } from '../../actions/admin';
import Layout from './layout/Layout';

const HomeComponent = (props) => {
  const [homeData, setHomeData] = useState([]);

  useEffect(() => {
    props.getHomeDetails();
  }, [])

  useEffect(() => {
    setHomeData(props.homeDetails)
  }, [props.homeDetails])

  return (
    <Layout>
      <Container fluid>
        <div className='m-4'>
          {(homeData) ?
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      Courses
                    </Card.Title>
                    <Card.Text>
                      {homeData.course}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      Batches
                    </Card.Title>
                    <Card.Text>
                      {homeData.batch}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>
                      Enrollment
                    </Card.Title>
                    <Card.Text>
                      {homeData.enroll}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> : null}
        </div>

      </Container>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  homeDetails: state.admin.home,
});

export default connect(mapStateToProps, { getHomeDetails })(HomeComponent);