import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Loader from 'react-loader-spinner';
import Datas from '../data/course/filter.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/courseFilter.js";
import { getCourses } from '../actions/course';

const CourseFilter = (props) => {

  const [filterCourseData, setFilterCourseData] = useState([]);

  useEffect(() => {
    props.getCourses();
  }, [])

  useEffect(() => {
    if (props.courses) {
      let newcategoryCourse = props.courses.data.map((data) => {
        return data
      })
      setFilterCourseData(newcategoryCourse)
    }
  }, [props.courses])

    return (
        <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{Datas.secTitle}</h4>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="filter-btns text-center d-none">
                                <ul className="filter-btn-list list-unstyled list inline">
                                    <li data-target="*" className="active list-inline-item">All Courses</li>
                                    <li data-target="desi" className="list-inline-item">Web Design</li>
                                    <li data-target="deve" className="list-inline-item">Web Development</li>
                                    <li data-target="seo" className="list-inline-item">Seo</li>
                                    <li data-target="prog" className="list-inline-item">Programming</li>
                                </ul>
                            </div>
                            <Row className="filter-items">
                                {
                                    (filterCourseData && filterCourseData.length) ?
                                    filterCourseData.map((data, i) => (
                                        <Col lg="4" md="6" key={i}>
                                            <div className="course-item">
                                                <Link to={`/course/${data.course_id}/${data.course_name}`.split(" ").join("-").toLowerCase()}>
                                                    <div className="course-image" style={{backgroundImage: `url(${data.course_image_url})`}}>
                                                    </div>
                                                
                                                    <div className="course-content">
                                                        <h6 className="heading"><Link to={''}>{data.course_name}</Link></h6>
                                                        <p className="desc text-truncate">{data.course_shortdescription}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
                                    ))
                                    : <Loader.ThreeDots type="ThreeDots" color="#0071c1" height={50} width={50} className="loader text-center" />
                                }
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={process.env.PUBLIC_URL + "/course-grid"}>View All Courses</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

const mapStateToProps = (state) => ({
  courses: state.course.userCourses
});

export default connect(mapStateToProps, { getCourses })(CourseFilter);