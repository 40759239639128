import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";
import { getUser, removeUserSession } from '../../utils/UserDetails.js';
import { logoutUser } from '../../actions/auth.js';

const StickyMenu = (props) => {
    
    const history = useHistory();
    const currentUser = getUser();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });

    const onLogoutHandler = () => {
        removeUserSession();
        props.logoutUser(history);
      };

    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container className='sticky-container'>
                    <Row>
                        <Col md="3">
                            <div className="logo" style={{margin : '-13px 0px 0px 0px'}}>
                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/COGNiTUDE Academy Logo3.jpg"} alt="" /></Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="menu-box d-flex justify-content-end">
                                <ul className="nav menu-nav">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/"}>Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/about") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Courses</Link>
                                        {/* <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                        </ul> */}
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/contact") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                                    </li>
                                    {(!currentUser) ?
                                        <>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/login") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/login"}>Sign In</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/registration") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/registration"}>Sign Up</Link>
                                            </li>
                                        </>
                                    :
                                    <>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/my-learnings") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/my-learnings"}>My learnings</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle sticky-nav" data-toggle="dropdown" >{<img className="profile-icon rounded-circle currentprfl img-padding"
                                                alt="profile-logo" src={'/assets/images/profile1.jpg'}
                                                onError={(e)=>{e.target.onerror = null; e.target.src = '/assets/images/profile1.jpg'}}
                                            />}</Link>
                                        <ul className="dropdown list-unstyled">
                                            <li className="nav-item">
                                                <Link className="nav-link " to={""} >{currentUser ? currentUser?.user_name : ''}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link " to={""} onClick={() => onLogoutHandler()}>Sign Out</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    </>
                                    }
                                    
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { logoutUser })(StickyMenu);