import React from 'react';
import DataTable from 'react-data-table-component';

const DataTableComponent = (props) => {
  return (
    <DataTable
      title={props.title}
      columns={props.columns}
      data={props.data}
      pagination
      responsive
      progressPending={props.pending}
    />
  )
}

export default DataTableComponent;