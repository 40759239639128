import {
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  GET_COURSES,
  GET_COURSE_TYPES,
  UPDATE_COURSE,
  DELETE_COURSE,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR,
  GET_BATCHES,
  UPDATE_BATCH,
  DELETE_BATCH,
  GET_BATCH_TYPES,
  GET_USERS,
  UPDATE_ROLE,
  GET_TOPICS,
  UPDATE_TOPIC,
  DELETE_TOPIC,
  UPDATE_BATCH_TYPE,
  DELETE_BATCH_TYPE,
  ASSIGN_COURSE,
  GET_ADMIN_COURSES,
  GET_DEACTIVATED_COURSES,
  GET_HOME_DETAILS,
  GET_ENROLL,
  GET_CATEGORIES,
  ADD_COURSE, 
  GET_CONTACT,
  GET_CURRENCIES
} from './types';
import AdminService from '../services/admin.service';
import axiosInstance from "../config/adminAxios";
import {
  setAdminSession, removeAdminSession
} from "../utils/UserDetails";

export const updateCategory = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateCategory(data);
    dispatch({
      type: UPDATE_CATEGORY,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const deleteCategory = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.deleteCategory(data);
    dispatch({
      type: DELETE_CATEGORY,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getCourses = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getCourses();
    dispatch({
      type: GET_COURSES,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getCourseTypes = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getCourseTypes();
    dispatch({
      type: GET_COURSE_TYPES,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const updateCourse = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateCourse(data);
    dispatch({
      type: UPDATE_COURSE,
      payload: resultData?.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const addCourse = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.addCourse(data);
    dispatch({
      type: ADD_COURSE,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const deleteCourse = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.deleteCourse(data);
    dispatch({
      type: DELETE_COURSE,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const loginUser = (loginInput, setSubmitting, setFieldValue) => async (dispatch) => {
  try {
    const resultData = await AdminService.adminLogin(loginInput);

    if (resultData.data && resultData.data.status === true) {
      let userData = resultData?.data ? resultData.data : "";
      setAdminSession(userData);
      if (userData?.api_token && userData.api_token) {
        axiosInstance.interceptors.request.use(function (config) {
          config.headers.Authorization = `Bearer ${userData.api_token}`;
          return config;
        });
      }
      dispatch(setLoginSuccessState({
        'user_role': 'admin'
      }));
    } else {
      dispatch(setLoginErrorState(resultData.message));
      setFieldValue('password', '', false)
      setSubmitting(false);
    }
  } catch (err) {
    dispatch(setLoginErrorState('Please Try Agian'));
    setFieldValue('password', '', false)
    setSubmitting(false);
  }
}

export const getBatch = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getBatch();
    dispatch({
      type: GET_BATCHES,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const updateBatch = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateBatch(data);
    dispatch({
      type: UPDATE_BATCH,
      payload: resultData?.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const deleteBatch = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.deleteBatch(data);
    dispatch({
      type: DELETE_BATCH,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getBatchTypes = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getBatchTypes();
    dispatch({
      type: GET_BATCH_TYPES,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const setLoginSuccessState = (data) => {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    payload: data,
  };
};

const setLoginErrorState = (data) => {
  return {
    type: ADMIN_LOGIN_ERROR,
    payload: data,
  };
};

export const getUsers = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getUsers();
    dispatch({
      type: GET_USERS,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const updateRole = (info) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateRole(info);
    dispatch({
      type: UPDATE_ROLE,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getTopics = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getTopics();
    dispatch({
      type: GET_TOPICS,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const updateTopic = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateTopic(data);
    dispatch({
      type: UPDATE_TOPIC,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const deleteTopic = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.deleteTopic(data);
    dispatch({
      type: DELETE_TOPIC,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const updateBatchType = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.updateBatchType(data);
    dispatch({
      type: UPDATE_BATCH_TYPE,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const deleteBatchType = (data) => async (dispatch) => {
  try {
    const resultData = await AdminService.deleteBatchType(data);
    dispatch({
      type: DELETE_BATCH_TYPE,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const userLogout = (history) => async (dispatch) => {
  try {
    const resultData = await AdminService.userLogout();
    dispatch({ type: 'DESTROY_SESSION' });
    removeAdminSession();
    history.push('/admin');
  } catch (err) {
    console.log(err)
  }
}

export const assignCourse = (courseInfo) => async (dispatch) => {
  try {
    const resultData = await AdminService.assignCourse(courseInfo);

    dispatch({
      type: ASSIGN_COURSE,
      payload: resultData.data
    });

  } catch (err) {
    console.log(err)
  }
}

export const getAdminCourses = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getAdminCourses();

    dispatch({
      type: GET_ADMIN_COURSES,
      payload: resultData.data
    });
  } catch (err) {
    console.log(err)
  }
}

export const getDeactivatedCourses = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getDeactivatedCourses();

    dispatch({
      type: GET_DEACTIVATED_COURSES,
      payload: resultData.data
    });
  } catch (err) {
    console.log(err)
  }
}

export const getHomeDetails = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getHomeDetails();

    dispatch({
      type: GET_HOME_DETAILS,
      payload: resultData.data
    });
  } catch (err) {
    console.log(err)
  }
}

export const getEnroll = (id) => async (dispatch) => {
  try {
    const resultData = await AdminService.getEnroll(id);

    dispatch({
      type: GET_ENROLL,
      payload: resultData.data
    });
  } catch (err) {
    console.log(err)
  }
}

export const getCategories = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getCategories();

    dispatch({
      type: GET_CATEGORIES,
      payload: resultData.data
    });
  } catch (err) {
    console.log(err)
  }
}

export const getContact = () => async (dispatch) => {
  try {
    const resultData = await AdminService.getContact();
    dispatch({
      type: GET_CONTACT,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getCurrencies = () => async (dispatch) => {
  try {
    const res = await AdminService.getCurrencies();

    dispatch({
      type: GET_CURRENCIES,
      payload: res
    });
  } catch (err) {
    console.log(err);
  }
}