import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

.sidebar-style {
    width: 250px !important;
    height: 100%;
    background: #2A3F54;
    display: flex
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1260px;
    }
}

.cAyhpY {
    display: flex !important;
}

.menu-items {
    display: block;
    padding: 10px 10px 10px 0px;
    color: #fdfdfd;
    font-weight: 500;
    margin: 15px;
    border-bottom: 1px solid rgb(35, 50, 66);
}

.font-style {
    font-weight : 500 !important;
}

.modal-padding {
    padding: 1rem 6rem !important;
  }

h5{
    font-size: 22px !important;
    font-family: 'Roboto',sans-serif !important;
    padding-left: 16px !important;
    font-weight: 400 !important;
}

select option{
    text-transform: capitalize;
}


li.nav-item {
    position: relative;

    a.nav-link {
        font-size     : 14px;
        color         : #ffff;
        text-transform: uppercase;
        font-weight   : 400;
        padding       : 10px 10px;

        i {
            font-size: 12px;
            margin-left: 115px;
        }

        &:after {
            content: none;
        }

        &:hover {
            color: ${colors.green};
        }

        @media(max-width: 1199px) {
            padding: 10px 2px 10px;
            letter-spacing: 0;
        }
    }
    button.nav-link {
        font-size     : 14px;
        color         : #ffff;
        text-transform: uppercase;
        font-weight   : 400;
        padding       : 10px 10px;
        background: transparent;
        border: none;

        i {
            font-size: 12px;
            margin-left: 115px;
        }

        &:after {
            content: none;
        }

        &:hover {
            color: ${colors.green};
        }

        @media(max-width: 1199px) {
            padding: 10px 2px 10px;
            letter-spacing: 0;
        }
    }

    ul.dropdown {
        position  : absolute;
        left      : 25px;
        top       : 100%;
        min-width : 190px;
        background: #fff;
        border    : 1px solid ${colors.border1};
        text-align: left;
        padding   : 0;
        border-radius : 5px;
        transition : 0.2s ease;
        opacity         : 0;
        transform       : scaleY(0);
        visibility      : hidden;
        z-index         : 999;
        transform-origin: center top 0;

        li.nav-item {
            position: relative;

            a.nav-link {
                font-size     : 13px;
                color         : ${colors.text1};
                padding       : 10px 20px;
                text-transform: capitalize;
                font-weight   : 400;
                margin-right  : 0;
                border-bottom : 1px solid ${colors.border1};

                &:hover {
                    color: ${colors.green};

                    i {
                        color: #fff;
                    }
                }

                i {
                    float     : right;
                    margin-top: 4px;
                }
            }

            &:last-child {
                margin-left: 0;

                a.nav-link {
                    border-bottom: none;
                }
            }

            ul.dropdown2 {
                position  : absolute;
                top       : 0;
                left      : 100%;
                min-width : 180px;
                background: #fff;
                border    : 1px solid ${colors.border1};
                transition : 0.2s ease;
                opacity         : 0;
                transform       : scaleY(0);
                visibility      : hidden;
                z-index         : 999;
                transform-origin: center top 0;
            }

            &:hover {
                ul.dropdown2 {
                    opacity   : 1;
                    transform : scaleY(1);
                    visibility: visible;
                }
            }
        }
    }

    &:hover {
        ul.dropdown {
            opacity   : 1;
            transform : scaleY(1);
            visibility: visible;
        }
    }
}

a.menu-items.active {
    display: block;
    padding: 10px 10px 10px 0px;
    background-color: #fff !important;
    color: #2a3f54 !important;
    font-weight: 500;
    margin: 15px;
    border-bottom: 1px solid rgb(35, 50, 66);

}

.toogle-menu {
    position: absolute;
    top: 18px;
    z-index: 1000;
    width: 40px;
}

.navbar-brand {
    margin-left : 3rem
}

.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    // content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

`;