import AdminAxios from '../config/adminAxios';

class AdminService {
  updateCategory = (data) => {
    return AdminAxios.post('add-category', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  deleteCategory = (data) => {
    return AdminAxios.post('delete-category', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getCourses = () => {
    return AdminAxios.get('get-courses')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getCourseTypes = () => {
    return AdminAxios.get('get-course-types')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getCurrencies = () => {
    return AdminAxios.get('get-currencies')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateCourse = (data) => {
    return AdminAxios.post('add-course', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  addCourse = (data) => {
    return AdminAxios.post('add-course', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  deleteCourse = (data) => {
    return AdminAxios.post('delete-course', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  adminLogin = (loginData) => {
    return AdminAxios.post("admin-login", loginData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getBatch = () => {
    return AdminAxios.get("get-batch")
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateBatch = (data) => {
    return AdminAxios.post('add-batch', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  deleteBatch = (data) => {
    return AdminAxios.post('delete-batch', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getBatchTypes = () => {
    return AdminAxios.get('get-batch-types')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getUsers = () => {
    return AdminAxios.get('get-users')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateRole = (data) => {
    return AdminAxios.post('update-user-role', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getTopics = () => {
    return AdminAxios.get('get-admin-topics')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateTopic = (data) => {
    return AdminAxios.post('add-topic', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  deleteTopic = (data) => {
    return AdminAxios.post('delete-topic', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateBatchType = (data) => {
    return AdminAxios.post('add-batch-type', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  deleteBatchType = (data) => {
    return AdminAxios.post('delete-batch-type', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  userLogout = () => {
    return AdminAxios.post('/admin-logout', [])
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  assignCourse = (info) => {
    return AdminAxios.post('/assign-course', info)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getAdminCourses = () => {
    return AdminAxios.get('get-admin-courses')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getDeactivatedCourses = () => {
    return AdminAxios.get('get-deactivated-courses')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getHomeDetails = () => {
    return AdminAxios.get('home')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getEnroll = (id) => {
    return AdminAxios.get('get-enroll?batchId=' + id)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getCategories = () => {
    return AdminAxios.get('get-categories')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getContact = () => {
    return AdminAxios.get('get-contacts')
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }
  
}

export default new AdminService();