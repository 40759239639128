import React from 'react'
import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import { Styles } from '../styles/admin';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import AdminHeader from './AdminHeader';
import LeftMenu from './LeftMenu';

const Layout = (props) => {
  
  return (
    <Styles>
      <div >
        <AdminHeader />
      
        <div className="d-sm-block  d-md-block d-lg-none toogle-menu">
          <NavDropdown title={<FontAwesomeIcon icon={faBars} className="text-muted" />}>
            <LeftMenu />
            </NavDropdown>
        </div>
      
        {/* <Container fluid  className="feed-layout">
          <Row className="m-0">
            <div className='d-flex'>
              <Col className="col-auto d-none d-lg-block"  id="sidebar-wrapper">  
                <div className='layout-left-side w-25'>
                  <LeftMenu />
                </div>
              </Col>
              <Col className="col main-frame"  id="page-content-wrapper">
                <div className='layout-center-side flex-grow-1 w-75'>
                  {props.children}
                </div>
              </Col>
            </div>
          </Row>
        </Container> */}

        <div className="container-fluid primary-bg-color feed-layout">
          <div className="row m-0">
            <div className="col-lg-3 d-none d-lg-block"  id="sidebar-wrapper">  
              <div className='layout-left-side'>
                <LeftMenu />
              </div>
              </div>
              <div className="col-lg-9 main-frame"  id="page-content-wrapper">
              <div className='layout-center-side'>
                {props.children}
              </div>
              </div>
            </div>
        </div>
      </div>
    </Styles>
  )
}

export default Layout

