// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('userData');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('userData');
}

// set the token and user from the session storage
export const setUserSession = (user) => {
  localStorage.setItem('userData', JSON.stringify(user));
}

export const setAdminSession = (admin) => {
  localStorage.setItem('adminData', JSON.stringify(admin));
  localStorage.setItem('role', JSON.stringify('admin'));
}

export const getAdmin = () => {
  const userStr = localStorage.getItem('adminData');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const removeAdminSession = () => {
  localStorage.removeItem('adminData');
  localStorage.removeItem('role');
}