import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

import { Styles } from '../styles/admin';
import { Container, Row, Col, Button, Form, Navbar, Nav } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap-accordion';
import 'react-bootstrap-accordion/dist/index.css';
import { userLogout } from '../../../actions/admin';

const AdminHeader = (props) => {
  const history = useHistory();

  const onLogoutHandler = () => {
    props.userLogout(history);
  };

  return (
    <div className="main-wrapper">
      <Styles>
      <section className="logo-area2">
      
        <Navbar bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#"> <img src={"/assets/images/new logo.jpg"} width="200" height="50" alt="academy" /></Navbar.Brand>
            <div className="d-flex flex-end" >
            <Form >
              <Button variant="secondary" className='ml-3' onClick={() => onLogoutHandler()}>Logout</Button>
            </Form>
            </div>
          </Container>
        </Navbar>
        </section>

      </Styles>
    </div >
  );
}

const mapStateToProps = (state) => ({
  // 
});

export default connect(mapStateToProps, { userLogout })(AdminHeader);
