import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Styles } from './styles/admin';
import { Container, Card, Row, Col, Button, Modal } from 'react-bootstrap';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";

import { getBatchTypes, updateBatchType, deleteBatchType } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { ModalStyles } from './styles/home';
import Layout from './layout/Layout';


const BatchTypes = (props) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [batchType, setBatchType] = useState({}); //particular row
  const [batchTypes, setBatchTypes] = useState([]);

  useEffect(() => {
    props.getBatchTypes();
  }, []);

  useEffect(() => {
    setBatchTypes(props.allBatchTypes)
  }, [props.allBatchTypes])

  const columns = useMemo(clickHandler => [
    {
      name: 'ID',
      selector: row => row.batch_type_id,
      sortable: true,
    },
    {
      name: 'Batch Type Name',
      selector: row => row.batch_type_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (row.status_id === 1) ? 'Active' : 'Deactivated',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.batch_type_id)}>Edit</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Delete',
      cell: (row) => <Button variant="danger" onClick={() => handleDelete(row.batch_type_id)}>Delete</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]);

  const initialValues = {
    batch_type_id: "",
    batch_type_name: "",
    status_id: 1
  }

  const handleButtonClick = (state) => {
    let batchType = props.allBatchTypes.filter(item => {
      return item.batch_type_id === state
    });
    setBatchType(batchType[0]);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const batchTypeSchema = Yup.object().shape({
    batch_type_name: Yup.string()
      .required('Enter batch type name'),
  });

  const onSubmitHandler = (formData) => {
    let typeData = {};

    if (formData.batch_type_name) {
      typeData.batch_type_name = formData.batch_type_name;
    }

    if (formData.status_id) {
      typeData.status_id = batchType.status_id;
    }

    typeData.batch_type_id = formData.batch_type_id;

    let newBatchTypes = [...batchTypes];
    let index = newBatchTypes.findIndex(item => item.batch_type_id === formData.batch_type_id);
    newBatchTypes[index]['batch_type_name'] = formData.batch_type_name;
    newBatchTypes[index]['status_id'] = batchType.status_id;

    setBatchTypes(newBatchTypes);

    props.updateBatchType(typeData);
    setShow(false);
  }

  const handleRadioChange = (val) => {
    let newBatchType = { ...batchType };
    newBatchType.status_id = val;
    setBatchType(newBatchType);
  }

  const handleDelete = (data) => {
    let newBatchTypes = [...batchTypes];
    let index = newBatchTypes.findIndex(item => item.batch_type_id === data);

    newBatchTypes[index].status_id = 2;
    setBatchTypes(newBatchTypes);

    let delInfo = {
      batch_type_id: data
    }
    props.deleteBatchType(delInfo);
  }

  const onCreateHandler = (formData) => {
    props.updateBatchType(formData);
    setTimeout(() => {
      props.getBatchTypes();
      setShowModal(false);
    }, 3000);
  }

  return (
    <Styles>
      <Layout>

        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Batch Types List</h5>
                </div>
                <div className='col' style={{textAlign : 'end'}}>
                <Button variant="primary"  onClick={() => setShowModal(true)}>Add New</Button>
                </div>
              </div>
              <Card>
                <Card.Body>
                <DataTableComponent
                  columns={columns}
                  data={batchTypes}
                />
                </Card.Body>
                </Card>
            </Col>
          </Row>
        </Container>

        {/* edit batchType modal */}
        <Modal show={show} onHide={handleClose}>
        <ModalStyles > 
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Edit Batch Type</Modal.Title>
          </Modal.Header>
          <Modal.Body >
          
            <Formik
              initialValues={batchType}
              validationSchema={batchTypeSchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onSubmitHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Batch Type Name</label>
                        <Field type="text" name="batch_type_name" id="batchTypeName" className="form-control mb-3" placeholder="batchType Name" defaultValue={batchType.batch_type_name} />
                        <span className="error-message">{(errors.batch_type_name && touched.batch_type_name) ? errors.batch_type_name : null}</span>
                      </p>

                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className="mb-3 mr-3">
                          <Field type="radio" name="status_id" defaultValue="1" checked={(batchType.status_id === 1) ? 'checked' : null} onChange={() => handleRadioChange(1)} />
                          Yes
                        </label>
                        <label className="mb-3">
                          <Field type="radio" name="status_id" defaultValue="2" checked={(batchType.status_id === 2) ? 'checked' : null} onChange={() => handleRadioChange(2)} />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles > 
        </Modal>

        {/* create batchType modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <ModalStyles >
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Add Batch Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Formik
              initialValues={initialValues}
              validationSchema={batchTypeSchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onCreateHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Batch Type Name</label>
                        <Field type="text" name="batch_type_name" id="batchTypeName1" className="form-control mb-3" placeholder="Batch Type Name" />
                        <span className="error-message">{(errors.batch_type_name && touched.batch_type_name) ? errors.batch_type_name : null}</span>
                      </p>
                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className='mb-3 mr-3'>
                          <Field type="radio" name="status_id" value="1" checked />
                          Yes
                        </label>
                        <label className='mb-3'>
                          <Field type="radio" name="status_id" value="2" />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Create</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles >
        </Modal>

      </Layout>
    </Styles >
  )
}

const mapStateToProps = (state) => ({
  allBatchTypes: state.admin.batchTypes
});

export default connect(mapStateToProps, { getBatchTypes, updateBatchType, deleteBatchType })(BatchTypes);