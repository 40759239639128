import Axios from '../config/axios';

class PaymentService {
  createOrder = (data) => {
    return Axios.post('create-order', data)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  updateOrder = (info) => {
    return Axios.post('update-order', info)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }
}

export default new PaymentService();