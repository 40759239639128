import axiosInstance from "../config/axios";
import AuthService from "../services/auth.service";
import { removeUserSession, setUserSession } from "../utils/UserDetails";
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  CONTACT_SUCCESS

} from './types';


export const loginUser = (loginInput, setSubmitting, setFieldValue) => async(dispatch) => {

  try {
    const resultData = await AuthService.userLogin(loginInput);
    
      if (resultData?.status && resultData.status === true) {
         let userData = resultData?.data ? resultData.data : "";
         setUserSession(userData);
        
        if (userData?.api_token && userData.api_token) {
          axiosInstance.interceptors.request.use(function (config) {
            config.headers.Authorization =  `Bearer ${userData.api_token}`;
            return config;
          });
        }

         dispatch(setLoginSuccessState({'user_role': userData.user_role}));
      } else {
        dispatch(setLoginErrorState(resultData.message));
        setFieldValue('password', '', false)
        setSubmitting(false);
      }
  } catch (err) {
    dispatch(setLoginErrorState('Please Try Agian'));
    setFieldValue('password', '', false)
    setSubmitting(false);
  }
}

export const userRegister = (formData, resetForm, setSubmitting, setFieldValue) => async(dispatch) => {

  let errorStatus = "Please Try Agian";
  
try {
    const resultData = await AuthService.registerLearningHub(formData);

    if(resultData?.status && resultData.status === true) {
      dispatch(setRegisterSuccessState('Success'));
      resetForm();
    } else { 
      errorStatus = (resultData?.error) ? resultData.error : errorStatus; 
      dispatch(setRegisterErrorState(errorStatus));
      setFieldValue('password', '', false)
      setSubmitting(false);
    } 
  } catch (err) {
    dispatch(setRegisterErrorState(errorStatus));
    setFieldValue('password', '', false)
    setSubmitting(false);
  }
}

export const userContact = (formData, resetForm, setSubmitting, setFieldValue) => async(dispatch) => {

  let errorStatus = "Please Try Agian";
  
try {
    const resultData = await AuthService.addContact(formData);
    if(resultData?.status && resultData.status === true) {
      dispatch(setContactSuccessState('Success'));
      resetForm();
    } else { 
      errorStatus = (resultData?.error) ? resultData.error : errorStatus;
      console.log(errorStatus) 
    } 
  } catch (err) {
    console.log(err)
  }
}

export const logoutUser = (history) => async (dispatch) => {
  try {
    const resultData = await AuthService.logoutUser();
    if (resultData?.status && resultData.status === true) {
      dispatch({ type: 'DESTROY_SESSION' });
      removeUserSession()
    }
    history.push("/login")
  } catch (err) {
    console.log(err)
  }
}

export const setLoginSuccessState = (data) => {
  return {
      type: LOGIN_SUCCESS,
      payload: data,
  };
};

const setLoginErrorState = (data) => {
  return {
      type: LOGIN_ERROR,
      payload: data,
  };
};

const setRegisterSuccessState = (data) => {
  return {
      type: REGISTER_SUCCESS,
      payload: data,
  };
};

const setRegisterErrorState = (data) => {
  return {
      type: REGISTER_ERROR,
      payload: data,
  };
};

const setContactSuccessState = (data) => {
  return {
      type: CONTACT_SUCCESS,
      payload: data,
  };
};