import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from "redux-thunk";

import authReducer from '../reducers/auth';
import courseReducer from '../reducers/course';
import adminReducer from '../reducers/admin';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      course: courseReducer,
      admin: adminReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};