import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Styles } from './styles/admin';
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";

import { updateTopic, deleteTopic, getTopics, getCategories } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { ModalStyles } from './styles/home';
import Layout from './layout/Layout';

const Topics = (props) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [topic, setTopic] = useState({}); //particular row
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    props.getCategories();
    props.getTopics();
  }, []);

  useEffect(() => {
    setTopics(props.allTopics)
  }, [props.allTopics])

  const columns = useMemo(clickHandler => [
    {
      name: 'ID',
      selector: row => row.topic_id,
      sortable: true,
    },
    {
      name: 'Topic Name',
      selector: row => row.topic_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (row.status_id === 1) ? 'Active' : 'Deactivated',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.topic_id)}>Edit</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Delete',
      cell: (row) => <Button variant="danger" onClick={() => handleDelete(row.topic_id)}>Delete</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]);

  const initialValues = {
    topic_id: "",
    topic_name: "",
    category_id: "",
    status_id: 1
  }

  const handleButtonClick = (state) => {
    let topic = props.allTopics.filter(item => {
      return item.topic_id === state
    });
    setTopic(topic[0]);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const topicSchema = Yup.object().shape({
    topic_name: Yup.string()
      .required('Enter topic Name'),

    category_id: Yup.number()
      .typeError('Must be a number')
      .required("Topic Parent is required"),
  });

  const onSubmitHandler = (formData) => {
    let topicData = {};

    if (formData.topic_name) {
      topicData.topic_name = formData.topic_name;
    }

    if (formData.status_id) {
      topicData.status_id = topic.status_id;
    }

    topicData.topic_id = formData.topic_id;
    topicData.category_id = formData.category_id;

    let newTopics = [...topics];
    let index = newTopics.findIndex(item => item.topic_id === formData.topic_id);
    newTopics[index]['topic_name'] = formData.topic_name;
    newTopics[index]['category_id'] = formData.category_id;
    newTopics[index]['status_id'] = topic.status_id;

    setTopics(newTopics);
    props.updateTopic(topicData);
    setShow(false);
  }

  const handleRadioChange = (val) => {
    let newTopic = { ...topic };
    newTopic.status_id = val;
    setTopic(newTopic);
  }

  const handleDelete = (data) => {
    let newTopics = [...topics];
    let index = newTopics.findIndex(item => item.topic_id === data);

    newTopics[index].status_id = 2;
    setTopics(newTopics);

    let delInfo = {
      topic_id: data
    }
    props.deleteTopic(delInfo);
  }

  const onCreateHandler = (formData) => {
    props.updateTopic(formData);
    setTimeout(() => {
      props.getTopics();
      setShowModal(false);
    }, 3000);
  }

  return (
    <Styles>
      <Layout>  

        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Topics List</h5>
                </div>
                <div className='col' style={{textAlign : 'end'}}>
                <Button variant="primary"  onClick={() => setShowModal(true)}>Add New</Button>
                </div>
              </div>
              <Card>
                <Card.Body>
                <DataTableComponent
                  columns={columns}
                  data={topics}
                />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* edit topic modal */}
        <Modal show={show} onHide={handleClose}>
        <ModalStyles >
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Edit Topic</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           
            <Formik
              initialValues={topic}
              validationSchema={topicSchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onSubmitHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Topic Name</label>
                        <Field type="text" name="topic_name" id="topicName" className="form-control mb-3" placeholder="topic Name" defaultValue={topic.topic_name} />
                        <span className="error-message">{(errors.topic_name && touched.topic_name) ? errors.topic_name : null}</span>
                      </p>
                      <p>
                        <label htmlFor="category" style={{fontWeight: 500}}>Category</label>
                        <Field as="select" name="category_id" className="form-control mb-3">
                          <option disabled value="">Select</option>
                          {(props.allCategories && props.allCategories.length) ? props.allCategories.map((cate, index) => {
                            return <option value={cate.category_id} key={index} selected={(cate.category_id === topic.category_id) ? 'selected' : null}>
                              {cate.category_name}</option>
                          }) : ''}
                        </Field>
                        <span className="error-message">{(errors.category_id && touched.category_id) ? errors.category_id : null}</span>
                      </p>
                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className='mb-3 mr-3'>
                          <Field type="radio" name="status_id" defaultValue="1" checked={(topic.status_id === 1) ? 'checked' : null} onChange={() => handleRadioChange(1)} />
                          Yes
                        </label>
                        <label className='mb-3'>
                          <Field type="radio" name="status_id" defaultValue="2" checked={(topic.status_id === 2) ? 'checked' : null} onChange={() => handleRadioChange(2)} />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
         
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles>
        </Modal>

        {/* create topic modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <ModalStyles >
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Add Topic</Modal.Title>
          </Modal.Header>
          <Modal.Body>
         
            <Formik
              initialValues={initialValues}
              validationSchema={topicSchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onCreateHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Topic Name</label>
                        <Field type="text" name="topic_name" id="topicName1" className="form-control mb-3" placeholder="Topic Name" />
                        <span className="error-message">{(errors.topic_name && touched.topic_name) ? errors.topic_name : null}</span>
                      </p>
                      <p>
                        <label htmlFor="parent" style={{fontWeight: 500}}>Topic Parent</label>
                        <Field as="select" name="category_id" className="form-control mb-3">
                          <option disabled value="">Select</option>
                          {props.allCategories.map((cate, index) => {
                            return <option value={cate.category_id} key={index}>
                              {cate.category_name}</option>
                          })}
                        </Field>

                        <span className="error-message">{(errors.category_id && touched.category_id) ? errors.category_id : null}</span>
                      </p>
                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className='mb-3 mr-3'>
                          <Field type="radio" name="status_id" value="1" checked />
                          Yes
                        </label>
                        <label className='mb-3'>
                          <Field type="radio" name="status_id" value="2" />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Create</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles >
        </Modal>

      </Layout>
    </Styles >
  )
}

const mapStateToProps = (state) => ({
  allCategories: state.admin.adminCategories,
  allTopics: state.admin.topics
});

export default connect(mapStateToProps, { getCategories, updateTopic, deleteTopic, getTopics })(Topics);