import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, Link, useParams, useLocation } from "react-router-dom";

import useRazorpay from "react-razorpay";

import * as Loader from 'react-loader-spinner';
import { Container, Row, Col, Tab, Nav, Button } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import ReviewForm from './components/ReviewForm';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import { PopularStyles } from './styles/popularCourse.js';
import toast, { Toaster } from 'react-hot-toast';
import renderHTML from 'react-render-html';

import { getCourses, user } from '../../actions/course';
import { getUser } from '../../utils/UserDetails';
import PaymentService from "../../services/payment.service";
import { getDate, getDays, getPriceFormatter } from '../../utils/Common';
import moment from 'moment';

const CourseDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const currentUser = getUser();
  const Razorpay = useRazorpay();

  const [courseData, setCourseData] = useState([]);
  const [filterBatchData, setFilterBatchData] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [showHide, setShowHide] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [enrollFlag, setEnrollFlag] = useState(false);
  const [todayDate,setTodayDate] = useState(moment().format('DD-MMM-YYYY'));

  useEffect(() => {
    props.getCourses();
    props.user();
  }, [])

  useEffect(() => {

    if (props.courses && props.courses.data.length) {
      let getCourseData = props.courses.data.find(item => item.course_id === parseInt(id));
      setCourseDetails(getCourseData);
      setCourseData(props.courses.data);
      setFilterBatchData(getCourseData?.batch);
      setShowHide(getCourseData?.batch[0]?.coursebatch_id);
    }
  }, [props.courses])

  useEffect(() => {
    if (props.users && Object.keys(props.users).length) {
      let id = props.users.user_id;
      const temp = courseDetails?.enroll?.filter((data, key) => {
        return data.user_id === id;
      })

      if (temp && temp.length) {
        setEnrollFlag(true);
      }
    }
  }, [props.users, courseDetails])

  const handleShowHide = (id) => {
    const getbatch = id;
    setShowHide(getbatch);
  }

  const handlePageNavigation = (data) => {
    if (currentUser) {
      handlePayment(data)
    } else {
      history.push({
        pathname: "/login",
        state: { from: location },
      });
    }
  }

  const handlePayment = async (data) => {
    let purchaseAmount = (data.discounted_fee !== null) ? data.discounted_fee : data.coursebatch_fee;

    let orderInfo = {
      amount: purchaseAmount * 100
    }
    
    const order = await PaymentService.createOrder(orderInfo);
    setOrderData(order.data);

    const options = {
      key: process.env.RAZOR_KEY,
      amount: purchaseAmount * 100,
      currency: data.coursebatch_currency,
      name: "Cognitude Academy",
      description: "Payment for " + data.coursebatch_name,
      image: "",
      order_id: order.data.orderId,
      handler: function (response) {
        let successObj = {
          course_id: data.coursebatch_courseid,
          batch_id: data.coursebatch_id,
          amount: purchaseAmount,
          razorpay_payment_id: response.razorpay_payment_id,
          paymentId: order.data.paymentId,
          status: 'SUCCESS',
          level: 'COMPLETED',
          response_order_id: response.razorpay_order_id,
          response: response
        }

        PaymentService.updateOrder(successObj);
        toast.success('Payment Success');
        setTimeout(() => {
          history.push('/course-grid')
        }, 4000);
      },
      prefill: {
        name: order.data.name,
        email: order.data.email,
        contact: order.data.phone,
      },
      notes: {
        address: "Cognitude Academy",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      let errorObj = {
        course_id: data.coursebatch_courseid,
        batch_id: data.coursebatch_id,
        amount: purchaseAmount,
        razorpay_payment_id: response.error.metadata.payment_id,
        paymentId: order.data.paymentId,
        status: 'FAILED',
        level: 'FAILED',
        response_order_id: response.error.metadata.order_id,
        response: response.error
      }

      PaymentService.updateOrder(errorObj);
      toast.error('Payment Failed')
    });

    rzp1.open();
  };

  return (
    <>
      <div className="main-wrapper course-details-page" >
        <Toaster />
        {/* Header 2 */}
        < HeaderTwo />

        {/* Breadcroumb */}
        < BreadcrumbBox title="Course Details" />

        <Styles>
          {/* Course Details */}
          <section className="course-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12" >
                  <div className="course-details-top">
                    <div className="heading" >
                      <h4>{courseDetails?.course_name}</h4>
                    </div>
                    <div className="course-top-overview">
                      <div className="d-flex overviews">
                        {/* <div className="author">
                            <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" />
                            <div className="author-name">
                                <h6>Author</h6>
                                <p>Andy Robert</p>
                            </div>
                        </div> */}
                        <div className="category">
                          <h6>Category</h6>
                          <p>{courseDetails?.category?.category_name}</p>
                        </div>
                        <div className="category">
                          <h6>Topic</h6>
                          {/* <p>{courseDetails?.topic?.topic_name}</p> */}
                          <span className='display-flex'>
                            {
                              courseDetails?.topic?.map((item, index) => {
                                return (
                                  <p>{(index ? ', ' : '') + item.topic_name}</p>
                                )
                              })
                            }
                          </span>
                        </div>
                        <div className="category">
                          <h6>Level</h6>
                          <p>{courseDetails?.type?.course_type_name}</p>
                        </div>
                        <div className="category">
                          <h6>Trainer</h6>
                          <p>{courseDetails?.trainer ? (courseDetails?.trainer?.first_name + ' ' + courseDetails?.trainer?.last_name) : (filterBatchData?.[0]?.trainer?.first_name + ' ' + filterBatchData?.[0]?.trainer?.last_name)}</p>
                        </div>
                        {/* <div className="category">
                        <h6>Trainer Name</h6>
                        </div> */}
                        {/* <div className="rating">
                            <h6>Rating</h6>
                            <ul className="list-unstyled list-inline">
                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                </li>
                                <li className="list-inline-item">(4.5)</li>
                            </ul>
                        </div> */}
                        {/* <div className="price">
                            <h6>Price</h6>
                            <p>$29.00</p>
                        </div> */}
                      </div>
                    </div>
                    <div className="course-details-banner">
                      <img src={courseDetails?.course_image_url} alt="" className="img-fluid" />
                    </div>

                    <div className="course-tab-list">
                      <Tab.Container defaultActiveKey="overview">
                        <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="overview">Overview</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="prerequisite">Prerequisites</Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                                <Nav.Link eventKey="instructor">Instructors</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="review">Reviews</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="overview" className="overview-tab">
                            <div className="course-desc">
                              <p>{courseDetails?.course_shortdescription}</p>
                            </div>
                            <div className="course-desc">
                              <h5>Course Description</h5>
                              <p>{(courseDetails?.course_description) ? renderHTML(courseDetails.course_description) : null}</p>
                            </div>
                            <div className="course-feature">
                              <h5>What You Will Learn ?</h5>
                              <p>{(courseDetails?.course_goal) ? renderHTML(courseDetails.course_goal) : null}</p>
                            </div>
                            {/* <div className="course-learn">
                                <h5>Learning Outcome</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi, quidem facere quisquam aperiam neque dolorem saepe. Laboriosam, quam aliquam odit modi harum libero culpa distinctio.</p>
                                <ul className="list-unstyled">
                                    <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                    <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                    <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                    <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                </ul>
                            </div>
                            <div className="course-share">
                                <h5>Share This Course</h5>
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                </ul>
                            </div> */}
                          </Tab.Pane>
                          <Tab.Pane eventKey="prerequisite" className="curriculum-tab">
                            <div className="course-curriculum">
                              <p>{(courseDetails?.prerequisite) ? renderHTML(courseDetails.prerequisite) : null}</p>
                            </div>
                            {/* <div className="course-element">
                              <h5>Course Content</h5>
                              <div className="course-item">
                                <button className="course-button active">Part 1: Introduction of Javsscript <span>04 Lectures - 30 Min</span></button>
                                <div className="course-content show">
                                  <ul className="list-unstyled">
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 01</span>
                                      <span className="lecture-title">Javascript functional components</span>
                                      <span className="lecture-duration">02:36</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 02</span>
                                      <span className="lecture-title">Javascript api integration</span>
                                      <span className="lecture-duration">04:20</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 03</span>
                                      <span className="lecture-title">Javscript project Setup</span>
                                      <span className="lecture-duration">03:39</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 04</span>
                                      <span className="lecture-title">React app setup project</span>
                                      <span className="lecture-duration">01:56</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="course-item">
                                <button className="course-button active">Part 2: Javascript Project Setup <span>03 Lectures - 43 Min</span></button>
                                <div className="course-content show">
                                  <ul className="list-unstyled">
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 01</span>
                                      <span className="lecture-title">Javascript functional components</span>
                                      <span className="lecture-duration">11:36</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 02</span>
                                      <span className="lecture-title">Javascript api integration</span>
                                      <span className="lecture-duration">19:20</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 03</span>
                                      <span className="lecture-title">Javscript project Setup</span>
                                      <span className="lecture-duration">10:39</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="course-item">
                                <button className="course-button active">Part 3: React Application Setup Project <span>04 Lectures - 59 Min</span></button>
                                <div className="course-content show">
                                  <ul className="list-unstyled">
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 01</span>
                                      <span className="lecture-title">Javascript functional components</span>
                                      <span className="lecture-duration">24:36</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 02</span>
                                      <span className="lecture-title">Javascript api integration</span>
                                      <span className="lecture-duration">21:20</span>
                                    </li>
                                    <li>
                                      <span className="play-icon"><i className="las la-play"></i> Lecture: 03</span>
                                      <span className="lecture-title">Javscript project Setup</span>
                                      <span className="lecture-duration">15:39</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div> */}
                          </Tab.Pane>
                          <Tab.Pane eventKey="instructor" className="instructor-tab">
                            <h5>Course Instructors</h5>
                            <div className="instructor-item">
                              <Row>
                                <Col md="4">
                                  <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`} alt="" className="img-fluid" />
                                  </div>
                                </Col>
                                <Col md="8">
                                  <div className="instructor-content">
                                    <div className="instructor-box">
                                      <div className="top-content d-flex justify-content-between">
                                        <div className="instructor-name">
                                          <h6>Mark Shadow</h6>
                                          <p>Senior Lecturer</p>
                                        </div>
                                        <div className="instructor-social">
                                          <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="instructor-desk">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae perferendis delectus voluptate reiciendis animi nisi nemo tenetur sequi cum laudantium sit totam libero quasi ducimus accusantium numquam eaque.</p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="instructor-item">
                              <Row>
                                <Col md="4">
                                  <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/instructor-2.jpg`} alt="" className="img-fluid" />
                                  </div>
                                </Col>
                                <Col md="8">
                                  <div className="instructor-content">
                                    <div className="instructor-box">
                                      <div className="top-content d-flex justify-content-between">
                                        <div className="instructor-name">
                                          <h6>Katrin Kay</h6>
                                          <p>Senior Lecturer</p>
                                        </div>
                                        <div className="instructor-social">
                                          <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="instructor-desk">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae perferendis delectus voluptate reiciendis animi nisi nemo tenetur sequi cum laudantium sit totam libero quasi ducimus accusantium numquam eaque.</p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="instructor-item">
                              <Row>
                                <Col md="4">
                                  <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/instructor-3.jpg`} alt="" className="img-fluid" />
                                  </div>
                                </Col>
                                <Col md="8">
                                  <div className="instructor-content">
                                    <div className="instructor-box">
                                      <div className="top-content d-flex justify-content-between">
                                        <div className="instructor-name">
                                          <h6>David Show</h6>
                                          <p>Senior Lecturer</p>
                                        </div>
                                        <div className="instructor-social">
                                          <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                            <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="instructor-desk">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae perferendis delectus voluptate reiciendis animi nisi nemo tenetur sequi cum laudantium sit totam libero quasi ducimus accusantium numquam eaque.</p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="review" className="review-tab">
                            <Row>
                              <Col md="12">
                                <div className="review-comments">
                                  <h5>Course Reviews</h5>
                                  <div className="comment-box d-flex">
                                    <div className="comment-image">
                                      <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                    </div>
                                    <div className="comment-content">
                                      <div className="content-title d-flex justify-content-between">
                                        <div className="comment-writer">
                                          <h6>Mark Shadow</h6>
                                          <p>Mar 26, 2020 | 06:30pm</p>
                                          <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                            <li className="list-inline-item">(4.5)</li>
                                          </ul>
                                        </div>
                                        <div className="reply-btn">
                                          <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                        </div>
                                      </div>
                                      <div className="comment-desc">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="comment-box d-flex">
                                    <div className="comment-image">
                                      <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-1.jpg`} alt="" />
                                    </div>
                                    <div className="comment-content">
                                      <div className="content-title d-flex justify-content-between">
                                        <div className="comment-writer">
                                          <h6>Katrin Kay</h6>
                                          <p>Mar 26, 2020 | 06:30pm</p>
                                          <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                            <li className="list-inline-item">(4.5)</li>
                                          </ul>
                                        </div>
                                        <div className="reply-btn">
                                          <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                        </div>
                                      </div>
                                      <div className="comment-desc">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="comment-box d-flex">
                                    <div className="comment-image">
                                      <img src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`} alt="" />
                                    </div>
                                    <div className="comment-content">
                                      <div className="content-title d-flex justify-content-between">
                                        <div className="comment-writer">
                                          <h6>David Show</h6>
                                          <p>Mar 26, 2020 | 06:30pm</p>
                                          <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                            <li className="list-inline-item">(4.5)</li>
                                          </ul>
                                        </div>
                                        <div className="reply-btn">
                                          <button type="button"><i className="las la-reply-all"></i> Reply</button>
                                        </div>
                                      </div>
                                      <div className="comment-desc">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto laborum quas placeat perspiciatis est, nisi expedita consectetur sit minus illum laudantium nostrum dolore odit asperiores quisquam ad enim iusto laborum quas placeat perspiciatis saepe.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="review-form">
                                  <h5>Submit Review</h5>
                                  <ReviewForm />
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12" >
                  <div className="single-details-sidbar">
                    <Row>
                      <Col md="12">
                        <div className="course-details-feature" style={{ margin: '0px 0px 30px 0px' }}>
                          <h6 className="title">Instructor Led - Online</h6>
                        </div>
                      </Col>
                      <>
                        {
                          (filterBatchData && filterBatchData.length) ?
                            filterBatchData.map((data, index) => {
                              return (
                                <Col md="12" key={index}>
                                  {(showHide === data.coursebatch_id) && (
                                    <div className="course-details-feature">
                                      <h5 className="title">Batch Details</h5>
                                      <ul className="list-unstyled feature-list">
                                        <li><i className="las la-book"></i> Batch Name : <span class="label">{data.coursebatch_name}</span></li>
                                        <li><i className="las la-calendar"></i> Starts At :<span>{getDate(data.coursebatch_startdate)}</span></li>
                                        <li><i className="las la-clock"></i> Duration : <span>{courseDetails?.duration} Days</span></li>
                                        {/* { data.join_by_date ?
                                        <li><i className="las la-calendar"></i> Join By Date :<span>{getDate(data.join_by_date)}</span></li> : <li><i className="las la-calendar"></i> Join By Date :<span>  -  </span></li> }
                                        <li><i className="las la-money-bill-wave"></i> Currency : <span>{data.coursebatch_currency}</span></li> */}
                                        {data.discounted_fee ?
                                          <li><i className="las la-money-bill"></i> Price : <span style={{ color: '#0071c1', textDecorationLine: 'line-through' }}><b>{data.coursebatch_currency} { getPriceFormatter(courseDetails?.course_fee, "INR")}</b></span></li>
                                          :
                                          <li><i className="las la-money-bill"></i> Price : <span style={{ color: '#0071c1' }}><b>{data.coursebatch_currency} { getPriceFormatter(courseDetails?.course_fee, "INR")}</b></span></li>
                                        }

                                        {data.discounted_fee ?
                                          <li><i className="las la-money-bill"></i> Discounted Price : <span style={{ color: '#0071c1' }}><b>{data.coursebatch_currency} { getPriceFormatter(data.discounted_fee, "INR")}</b></span></li> : ''}

                                        {/* <li><i className="las la-clock"></i> No.of Students: <span>{data.coursebatch_max_students}</span></li> */}
                                        {/* <li><i className="las la-graduation-cap"></i> Subject: <span></span></li>
                                                                    <li><i className="las la-book"></i> Lectures: <span></span></li> */}
                                      </ul>
                                      { (getDate(data?.join_by_date) >= todayDate) ?
                                        <Button type="button" className="enroll-btn mb-4" onClick={(e) => {
                                          e.preventDefault();
                                          window.location.href = 'https://cognitudeacademy.com/contact';
                                        }}>Wish to Join</Button>  
                                        :
                                        <>
                                          <Button type="button" className="enroll-btn" disabled={(enrollFlag) ? true : false} onClick={() => handlePageNavigation(data)}>{(enrollFlag) ? 'Already Enrolled' : 'Enroll Course'}</Button>
                                          <a className='d-flex justify-content-center mt-1 mb-4' href='/terms'>Terms & Conditions</a>
                                          <Toaster />
                                        </>
                                       
                                      }
                                      <ul className="list-unstyled">
                                        { data.join_by_date ?
                                          <li> Last date of joining : <span>{getDate(data.join_by_date)}</span></li> : 
                                          <li> Last date of joining : <span>  -  </span></li>
                                        }
                                      </ul>
                                    </div>
                                  )
                                  }

                                </Col>
                              )
                            })
                            : <Col md="12">
                              <div className="course-details-feature">
                                <h5 className="title">Batch Details</h5>
                                <ul className="list-unstyled feature-list">
                                  <li><i className="las la-book"></i> Batch Name : <span>  -  </span>
                                  </li>
                                  <li><i className="las la-calendar"></i> Starts At :<span>  -  </span></li>
                                  <li><i className="las la-clock"></i> Duration : <span>{courseDetails?.duration} Days</span></li>
                                  {/* <li><i className="las la-calendar"></i> Join By Date :<span>  -  </span></li>
                                  <li><i className="las la-globe"></i> Currency : <span>  -  </span></li> */}
                                 <li><i className="las la-money-bill"></i> Price : <span style={{ color: '#0071c1' }}><b>{courseDetails?.currency} { getPriceFormatter(courseDetails?.course_fee, "INR")}</b></span></li>
                                  {/* <li><i className="las la-clock"></i> No.of Students: <span>{data.coursebatch_max_students}</span></li> */}
                                  {/* <li><i className="las la-graduation-cap"></i> Subject: <span></span></li>
                                                                    <li><i className="las la-book"></i> Lectures: <span></span></li> */}

                                </ul>
                                {/* <p className="text-center no-data-found my-2"> Currently no batches available for this course.</p> */}
                                <Button type="button" className="enroll-btn" onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = 'https://cognitudeacademy.com/contact';
                                }}>Wish to join</Button>
                              </div>
                            </Col>
                        }
                      </>
                      <Col md="12">
                        <PopularStyles>
                          {/* Popular Course */}
                          {
                            (filterBatchData && filterBatchData?.length > 1) ?
                              <div className="popular-course">
                                <h5>Other Batches</h5>
                                <div className="popular-items">
                                  {
                                    (filterBatchData && filterBatchData.length) ?
                                      filterBatchData.map((item) => {
                                        return (
                                          (item.status_id === 1) ?
                                          ((getDate(item.coursebatch_startdate) >= todayDate) || getDate(item.join_by_date) >= todayDate) ?
                                          <div className="item-box d-flex">
                                            {/* <div className="item-img">
                                            <Link to={process.env.PUBLIC_URL + item.courseLink}><img src={process.env.PUBLIC_URL + `/assets/images/pop-course-1.jpg`} alt="" /></Link>
                                        </div> */}
                                            <div className="item-content">
                                              <p className="title" style={{ cursor: 'pointer' }} onClick={() => (handleShowHide(item.coursebatch_id))}><b>{item.coursebatch_name}</b></p>
                                              <p className="title">{getDate(item.coursebatch_startdate)}</p>

                                              {/* <ul className="list-unstyled list-inline rating">
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                            </ul> */}
                                              {/* <p className="price">{getPriceFormatter(item.coursebatch_fee)}</p> */}
                                            </div>
                                          </div>
                                          :
                                          ''
                                          :
                                          ''
                                        )
                                      })
                                      : <p className="text-center no-data-found my-2"> No Data Found</p>
                                  }
                                </div>
                              </div>
                              : ''
                          }
                        </PopularStyles>
                      </Col>
                      {/* <Col md="12">
                        <PopularCourse />
                    </Col>
                     <Col md="12">
                            <CourseTag />
                        </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>

        {/* Footer 2 */}
        <FooterTwo />
      </div >
    </>
  )
}
const mapStateToProps = (state) => ({
  courses: state.course.userCourses,
  users: state.course.userDetails
});

export default connect(mapStateToProps, { getCourses, user })(CourseDetails);