import React, { useEffect, useState } from 'react';
import { Styles } from './styles/admin';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";

import { getUsers, getCourses, assignCourse } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { ModalStyles } from './styles/home';
import Layout from './layout/Layout';

const StudentToCourse = (props) => {
  const [usersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [batches, setBatches] = useState([]);
  const [courseId, setCourseId] = useState(null);

  useEffect(() => {
    props.getUsers();
    props.getCourses();
  }, [])

  useEffect(() => {
    setUsersData(props.allUsers);
  }, [props.allUsers])

  useEffect(() => {
    if (props.allCourses && props.allCourses.length) {
      setBatches(props.allCourses[0].batch)
    }
  }, [props.allCourses])

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'First Name',
      selector: row => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.last_name,
      sortable: true,
    },
    {
      name: 'User Role',
      selector: row => row.user_role,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.user_id)}>Assign Course</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const initialValues = {
    course_id: "",
    batch_id: "",
  }

  const courseSchema = Yup.object().shape({
    course_id: Yup.number()
      .required("Course is required"),

    batch_id: Yup.number()
      .required("Batch is required"),
  });

  const handleButtonClick = (id) => {
    setUserId(id)
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleSelectChange = (id) => {
    let temp = props.allCourses.find(item => item.course_id === parseInt(id));
    setBatches(temp.batch)
    setCourseId(id)
  }

  const onSubmitHandler = (info) => {
    let assignInfo = {
      courseId: courseId,
      batchId: info.batch_id,
      userId: userId
    }
    props.assignCourse(assignInfo)
    setShow(false);
    setBatches([]);
  }

  return (
    <Styles>
      <Layout>

        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Users List</h5>
                </div>
              </div>
              <div className='card'>
                <DataTableComponent
                  columns={columns}
                  data={usersData}
                />
              </div>
            </Col>
          </Row>
        </Container>
      
        {/* assign course modal */}
        <Modal show={show} onHide={handleClose}>
        <ModalStyles>
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Assign Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Formik
              initialValues={initialValues}
              validationSchema={courseSchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onSubmitHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="course" style={{fontWeight: 500}}>Course</label>
                        <select name="course_id" className="form-control mb-3" onChange={(event) => handleSelectChange(event.target.value)}>
                          <option value="">Select</option>
                          {(props.allCourses && props.allCourses.length) ? props.allCourses.map((cate, index) => {
                            return <option value={cate.course_id} key={index}>
                              {cate.course_name}</option>
                          }) : ''}
                        </select>
                        <span className="error-message">{(errors.course_id && touched.course_id) ? errors.course_id : null}</span>
                      </p>

                      <p>
                        <label htmlFor="batch" style={{fontWeight: 500}}>Batches</label>
                        <Field as="select" name="batch_id" className="form-control mb-3">
                          <option value="">Select</option>
                          {(batches && batches.length) ? batches.map((batch, index) => {
                            return <option value={batch.coursebatch_id} key={index}>
                              {batch.coursebatch_name}</option>
                          }) : null}
                        </Field>
                        <span className="error-message">{(errors.batch_id && touched.batch_id) ? errors.batch_id : null}</span>
                      </p>

                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Assign Course</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles>
        </Modal>

      </Layout>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  allUsers: state.admin.users,
  allCourses: state.admin.courses,
});

export default connect(mapStateToProps, { getUsers, getCourses, assignCourse })(StudentToCourse);