import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Loader from 'react-loader-spinner';
import { getCategories } from '../../../actions/course';
import { Styles } from '../styles/courseCategory.js';

const CourseCategory = (props) => {
  const [courseCategory, setCourseCategory] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);

  useEffect(() => {
    props.getCategories();
  }, [])

  useEffect(() => {
    if (props.categories && props.categories.length) {
      let newCourseData = [...props.courseData]
      let result = props.categories.filter(o1 => newCourseData.some(o2 => o1.category_id === o2.category.category_id));
      setCourseCategory(result)
    }
  }, [props.categories, props.courseData])

  const handleCheck = (e, id) => {
    let newCourseData = [...props.courseData];

    if (e.target.checked) {
      let temp = newCourseData.map((data) => {
        if (data.category.category_id === id) {
          data.isVisible = true;
        }

        return data;
      })
      props.setFilterCourseData(temp)
    } else {
      let temp = newCourseData.map((data) => {
        if (data.category.category_id === id) {
          data.isVisible = false;

        }
        return data;
      })
      props.setFilterCourseData(temp)
    }
  };

  return (
    <Styles>

      <div className="course-category overflow-auto">
        <h5>Course Category</h5>
        <ul className="category-item list-unstyled">
          {
            (courseCategory && courseCategory.length) ?
            courseCategory.map((data, category_id) => (
              <li className="check-btn" key={category_id}>
                <label htmlFor="cat1">
                  <input type="checkbox" id="cat1" name='isCategory' className="check-box" onChange={(e) => handleCheck(e, data.category_id)} defaultChecked={true} />
                  {data.category_name}
                </label>
              </li>
            ))
            : <Loader.ThreeDots type="ThreeDots" color="#0071c1" height={50} width={50} className="loader text-center" />
          }
        </ul>
      </div>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  categories: state.course.userCategories
});

export default connect(mapStateToProps, { getCategories })(CourseCategory);
