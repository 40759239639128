import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import {  Formik, Form, Field  } from "formik";
import * as Yup from "yup";
import IntlTelInput from 'react-intl-tel-input';
import FormLoader from '../../components/FormLoader';
import { userRegister } from '../../actions/auth.js';
import { getUser } from '../../utils/UserDetails';
import {  months, currentYear } from '../../constants/DefaultValues';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import AuthService from '../../services/auth.service';

const Register = (props) => {
  const history = useHistory();
  let daysofMonth = Array.from(Array(32).keys());
  daysofMonth.shift();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeRole, setActiveRole] = useState(null);
  const [isOrganisation, setOrganisation] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    password: "",
    register_terms: (props.isFrom) ? true : false,
    birth_month: 1,
    birth_day: daysofMonth[0],
    birth_year: currentYear,
    role_type: activeRole,
    dob: '1/'+ daysofMonth[0] +'/'+ currentYear,
  };

  useEffect(() => {
    let getYears = [];
    for (var i = currentYear; i > 1920; i--) {
      getYears.push(i);
    }
    setYearList(getYears)
    setActiveRole('STUDENT');
    setCurrentUser(getUser())
  }, []);
  
  useEffect(() => {
    if(currentUser){
      history.push("/");
    }
  }, [currentUser])
  
  useEffect(() => {  
    if(props.activeRole) {
      let roledata = (props.isRole) ? props.isRole : props.activeRole;
      setActiveRole(roledata);
      setOrganisation(false)
      if(props.activeRole === 'INSTITUTION' || (props.isRole && props.isRole === "CLIENT")) {
        setOrganisation(true)
      }
    }
  }, [props.activeRole])
  
  useEffect(() => {
    if(props.registerError)
      setErrorMsg(props.registerError)
  }, [props.registerError])
  
  useEffect(() => {
    if(props.registerSuccess)
      setIsModalOpen(true)
      if(props.getClients) {
        props.getClients();
      }
  }, [props.registerSuccess])

  const checkUniqueEmail = async(email) => {
    let resultdata = await AuthService.checkUniqueEmail(email);

    if(resultdata.status === true) {
      return false;
    } else {
      return true;
    }
  }

  const checkUniquePhone = async(country_code, phone) => {
    let phoneData = {
      'country_code' : country_code,
      'phone' : phone,
    }
    let resultdata = await AuthService.checkUniquePhone(phoneData);

    if(resultdata.status === true) {
      return false;
    } else {
      return true;
    }
  }

  Yup.addMethod(Yup.string, "checkuniqueemail", function (errorMessage) {
    return this.test(`custom-email`, errorMessage, function (value) {
      const { path, createError } = this;
      if(value)
      return (
        checkUniqueEmail(value) ||
        createError({ path, message: errorMessage })
      );
    });
   });

   Yup.addMethod(Yup.string, "checkuniquephone", function (errorMessage) {
    return this.test(`custom-phone`, errorMessage, function (value) {

      const { path, createError, parent } = this;

      if(value)
      return (
        checkUniquePhone(parent.country_code, value) ||
        createError({ path, message: errorMessage })
      );
    });
  }); 
  
  Yup.addMethod(Yup.string, "minAge", function (errorMessage) {
    return this.test(`custom-min-age`, errorMessage, function (value) {
      const { path, createError } = this;
      var today = new Date();
      var birthDate = new Date(value);
      var min = 18;
      var age = today.getFullYear() - birthDate.getFullYear();
      if (age > min + 1) {
        return true;
      }
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return (
        age >= min ||
        createError({ path, message: errorMessage })
      );
    });
  });
  
  const registerSchema = Yup.object().shape({
    role_type: Yup.string()
    .required("Choose your role"),

    first_name: Yup.string()
      .required("First name is required"),

    last_name: Yup.string()
    .required("Last name is required"),

    email: Yup.string()
      .required("Email is required")
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Enter valid email")
      .checkuniqueemail("Email has already taken"),   
      phone: Yup.string()
      .required("Mobile number is required")
      .checkuniquephone("Mobile number has already taken"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be 8 chars minimum")
      .matches(/^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/, "atleast 1 uppercase letter, 1 special character and 1 number"),
    dob: Yup.string()
      .minAge("You must be at least 18 years old."),
    register_terms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
  });
  
  const handleRegister = (formData, resetForm, setSubmitting, setFieldValue) => {
    props.handleRegisterSubmit(formData, resetForm, setSubmitting, setFieldValue)
  }

  const  formatPhoneNumberOutput = (isValid, newNumber, countryData, fullNumber, isExtension, setFieldValue) => {
    setFieldValue("phone",newNumber);
    setFieldValue("country_code", '+'+countryData.dialCode);
  }
  
  const  formatCountryOutput = (newNumber, countryData, fullNumber, isExtension) => {
    return '+'+countryData.dialCode;
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper registration-page">

        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Sign Up" />

        {/* Registration Area */}
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Sign Up</h3>
                  </div>
                  <Formik 
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={registerSchema}
                    validateOnChange={false}          
                    onSubmit={(formData, {resetForm, setSubmitting, setFieldValue}) => {
                      setErrorMsg('');
                      handleRegister(formData, resetForm, setSubmitting, setFieldValue);
                    }}
                  >
                    { formProps => {
                      const { values, isSubmitting, setFieldTouched, setFieldValue,  errors, touched } = formProps;
                      return (
                        <Form>
                          <div className="form">
                            <p className="form-control">
                              <label htmlFor="fname">First Name</label>
                              <Field type="text" name="first_name" id="first_name" className="input-cls" placeholder="First name" />
                              <span className="error-message">{ (errors.first_name && touched.first_name) ? errors.first_name : null }</span> 
                            </p>
                            <p className="form-control">
                              <label htmlFor="lname">Last Name</label>    
                              <Field type="text" name="last_name" id="last_name"	className="input-cls" placeholder="Last name"/>
                              <span className="error-message">{ (errors.last_name && touched.last_name) ? errors.last_name : null }</span> 
                            </p>        
                            <p className="form-control">
                              <label htmlFor="email">Email</label> 
                              <Field type="email" name="email" id="email"	className="input-cls" placeholder="Email"/>
                              <span className="error-message">{ (errors.email && touched.email) ? errors.email : null }</span> 
                            </p>
                            <p className="form-control">
                              <label htmlFor="mobile_number">Mobile Number</label> 
                              <IntlTelInput
                                defaultCountry="in"
                                fieldId="phone"
                                fieldName="phone"
                                separateDialCode={true}
                                inputClassName="input-cls"
                                autoPlaceholder={false}
                                placeholder="Mobile number"
                                onPhoneNumberBlur={() => {
                                setFieldTouched("phone", true);
                                }}
                                onPhoneNumberChange={(...args) => {
                                formatPhoneNumberOutput(...args, setFieldValue);
                                }}
                                onSelectFlag={(...args) => {
                                setFieldValue("country_code", formatCountryOutput(...args));
                                }}
                              />
                              <Field type="hidden" name="country_code" id="country_code" className="input-cls" placeholder="country_code"/>
                              <span className="error-message d-block">{ (errors.phone && touched.phone) ? errors.phone : null }</span> 
                            </p>
                            <p className="form-control">
                              <label htmlFor="create_password">Create Password</label>
                              <Field type="password" name="password" id="password" className="input-cls"  placeholder="Create password"/>
                              <span className="error-message">{ (errors.password && touched.password) ? errors.password : null }</span> 
                            </p>
                            <p className="form-control">
                              <label htmlFor="dob">DOB</label>
                              <Row>
                                <Col>
                                  <Field name="birth_month" as="select" className="input-cls text-center">
                                    {
                                      months.map((v,i) => {
                                        return <option value={i+1}> {v} </option>
                                      })
                                    }
                                  </Field>
                                </Col>
                                <Col>
                                  <Field name="birth_day" as="select" className="input-cls text-center">
                                    {
                                      daysofMonth.map((v) => {
                                        return <option value={v}> {v} </option>
                                      })
                                    }
                                  </Field>
                                </Col>
                                <Col>
                                  <Field name="birth_year" as="select" className="input-cls text-center">
                                    {
                                      yearList.map((v) => {
                                        return <option value={v}> {v} </option>
                                      })
                                    }
                                  </Field>
                                </Col>    
                              </Row>
                              <Field type="hidden" name="dob" id="dob" className="form-control form-control-sm col text-center"  value={ values.dob = values.birth_month + '/'+ values.birth_day + '/' + values.birth_year }></Field>
                              <span className="error-message">{ (errors.dob && touched.dob) ? errors.dob : null }</span> 
                            </p> 
                            <div className="mb-2">
                              <small>Note: IP address is captured for lawful purposes.</small>
                            </div>  
                            {
                              (!props.isFrom) ?
                                <p className="form-control">
                                  <div className="form-check terms_check">
                                    <Field type="checkbox" name="register_terms" className="form-check-input" id="register_terms" />
                                    <p className="form-check-label mb-0">
                                      I agree to the <Link to="#" className="signup-policy" data-href="https://zointvc.com/terms-and-conditions">Terms and Conditions</Link> and<Link to="#" className="signup-policy" data-href="https://zointvc.com/privacy-policy"> Privacy Policy</Link>
                                    </p>
                                  </div>
                                  <span className="error-message">{ (errors.register_terms && touched.register_terms) ? errors.register_terms : null }</span> 
                                </p>
                              :
                                null
                              }
                              <span className="error-message">{errorMsg}</span>                                
                              <button  type="submit" className="text-center mt-2 btn btn-primary btn-block font-weight-bold" disabled={isSubmitting}>
                                {isSubmitting ? (
                                  <FormLoader />
                                ) : (
                                  <span>{(props.isFrom) ? props.isFrom : 'Sign Up'}</span>
                                )}
                              </button>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                  <div className="have_account-btn text-center">
                    <p>Already have an account? <Link to="/login">Sign In Here</Link></p>
                  </div>
                </div>
                <Modal show={ isModalOpen } onHide={ () => setIsModalOpen(false) } backdrop="static" keyboard={false} centered className="tj-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container text-center p-4">
            {
              (props.isFrom) ?
              <h5>Verification email is sent to your account. Please check Spam folder in case you have not received it in your inbox.</h5>
              :
              <h5>Verification email is sent. Please check Spam folder in case you have not received it in your inbox.</h5>
            }
            
          </div>
                      
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <button type="button" className="btn btn-primary  mx-2" onClick={() => setIsModalOpen(false)}>Ok</button>
        </Modal.Footer>
      </Modal>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />

      </div>
    </Styles>
     
  )
}

const mapStateToProps = state => ({
  registerSuccess: state.auth.registerSuccess,
  registerError: state.auth.registerError
})

const mapDispatchToProps = (dispatch) => {
  return {
    handleRegisterSubmit: (formData, resetForm, setSubmitting, setFieldValue) => dispatch(userRegister(formData, resetForm, setSubmitting, setFieldValue)),
  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(Register);