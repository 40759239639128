import React, { useEffect, useState } from 'react';
import { Styles } from './styles/admin';
import { connect } from 'react-redux';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';

import DataTable from 'react-data-table-component';

import { getContact } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import Layout from './layout/Layout';

const AdminContact = (props) => {
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    props.getContact();
  }, [])

  useEffect(() => {
    setContactData(props.allContact);
  }, [props.allContact])

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Subject',
      selector: row => row.subject,
      sortable: true,
    },
    {
        name: 'Message',
        selector: row => row.message,
        sortable: true,
    }
  ];


  return (
    <Styles>
      <Layout>
        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Contact List</h5>
                </div>
              </div>
              <Card>
                <Card.Body>
                <DataTableComponent
                  columns={columns}
                  data={contactData}
                />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  allContact: state.admin.contacts,
});

export default connect(mapStateToProps, { getContact })(AdminContact);