import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Loader from 'react-loader-spinner';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/mylearnings'
import { getEnrollment, user } from '../../actions/course';
import { getDate } from '../../utils/Common';

const MyLearnings = (props) => {

  const [isLoading, setIsLoading]  = useState(false);
  const [userData, setUserData] = useState([]);
  const [enrollCourseData, setEnrollCourseData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    props.getEnrollment();
    props.user();
  }, [])

  useEffect(() => {
    if (props.enrollmentDetails) {
      let enrollData = props.enrollmentDetails;
      setEnrollCourseData(enrollData.data)
    }
  }, [props.enrollmentDetails])

  useEffect(() => {
    if (props.users) {
      let newUserData = props.users;
      setUserData(newUserData);
    }
  }, [props.users])

  return (
    <div className="main-wrapper course-details-page" >

      {/* Header 2 */}
      < HeaderTwo />

      {/* Breadcroumb */}
      < BreadcrumbBox title="My Learnings" />

      <Styles>
        {/* Course Details */}
        <section className="course-details-area">
          <Container>
            <Row>
              <Col lg="8" md="8" sm="8">
                <div className="course-details-top">
                  <div className="heading">
                    <h4>{userData ? `${userData.first_name} ${userData.last_name}` : ''}</h4>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="4" sm="4">
                <div className="course-top-overview">
                  <div className="d-flex overviews">
                    <div className="category">
                      <p>{(userData.user_role === 'TRAINER') ? userData.user_role : ''}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Fragment>
              {/* Course Item */}
              <Row>
                {                  
                  (enrollCourseData && enrollCourseData.length) ?
                    enrollCourseData.map((data, index) => {
                      if (data.course.length) {
                        return (
                          <Col lg="6" md="12" key={index}>
                            <div className="course-item">
                              <div className="course-image" style={{ backgroundImage: `url(${data?.course?.[0]?.course_image_url})` }}>
                                <div className="author-img d-flex">
                                  <div className="img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/`} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="course-content">
                                <h6 className="heading">{data?.course?.[0]?.course_name}</h6>
                                <p className="desc text-truncate">{data.course?.[0]?.course_shortdescription}</p>
                                <div className="course-face d-flex justify-content-between">
                                  <div className="duration">
                                    <p><i className="las la-book"></i>{data.batch?.[0]?.coursebatch_name}</p>
                                  </div>
                                  <div className="student">
                                    <p><i className="las la-calendar"></i>{getDate(data.batch?.[0]?.coursebatch_startdate)}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      }

                    })
                  :   <p className='enroll-text'>Courses you have enrolled will appear here. Please 'enroll' a course to view it here.</p>
                }
              </Row>
            </Fragment>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />

    </div >
  )
}

const mapStateToProps = (state) => ({
  enrollmentDetails: state.course.userEnrollments,
  users: state.course.userDetails
});

export default connect(mapStateToProps, { getEnrollment, user })(MyLearnings);
