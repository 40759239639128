import axios from "axios";
import {
    getAdmin
} from "../utils/UserDetails";

const baseURL = process.env.REACT_APP_BACKEND_BASEURL + '/api';
const headerData = {
    'Accept': 'application/json',
};
const currentUser = getAdmin();

if (currentUser) {
    headerData.Authorization = `Bearer ${currentUser.api_token}`
}
const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headerData
});

axiosInstance.interceptors.response.use(
    (response) =>
    new Promise((resolve, reject) => {
        resolve(response);
    }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        } else {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    }
);

export default axiosInstance;