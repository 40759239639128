import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import GoogleMap from './GoogleMap';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/contact.js';
import FormLoader from '../../components/FormLoader';
import { userContact } from '../../actions/auth';

const Contact = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const signInSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name can't be blank"),

    email: Yup.string()
      .required("Email can't be blank")
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Not a valid email"),

    subject: Yup.string()
      .required("Subject can't be blank"),

    message: Yup.string()
      .required("Message can't be blank"),
  });

  const onSubmitHandler = (formData, resetForm, setSubmitting, setFieldValue) => {
    props.handleContactSubmit(formData, resetForm, setSubmitting, setFieldValue)
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper contact-page">

        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Contact Us" />

        {/* Contact Area */}
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="4">
                <div className="contact-box-title">
                  <h4>Contact Info</h4>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-map-marker"></i>
                  </div>
                  <div className="box-content">
                    <h5>Our Location</h5>
                    <p>Chennai, India</p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="box-content">
                    <h5>Email Address</h5>
                    <p>academy@cognitude.co.in</p>
                  </div>
                </div>
                {/* <div className="contact-icon-box d-flex">
                                    <div className="icon">
                                        <i className="las la-phone"></i>
                                    </div>
                                    <div className="box-content">
                                        <h5>Phone Number</h5>
                                        <p>+1 (396) 486 4709<br />+1 (396) 486 8419</p>
                                    </div>
                                </div> */}
                {/* <div className="contact-social">
                                    <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                    </ul>
                                </div> */}
              </Col>
              <Col md="8">
                <div className="contact-form">
                  <div className="form-title">
                    <Row>
                      <Col md="4"><h4>Get In Touch</h4></Col>
                      {(props.contactSuccess) ? <Col><label className='text-success'>Thank You! Your message has been registered.We will get back soon.</label></Col> : null}
                    </Row>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange={false}
                    onSubmit={(formData, { resetForm, setSubmitting, setFieldValue }) => {
                      onSubmitHandler(formData, resetForm, setSubmitting, setFieldValue);
                    }}
                  >
                    {formProps => {
                      const { values, isSubmitting, setFieldTouched, setFieldValue, errors, touched } = formProps;
                      return (
                        <Form>
                          <div className="form-box">
                            <div id="form_contact" className="form">
                              <Row>
                                <Col md="6">
                                  <p className="form-control">
                                    <Field type="text" className="input" placeholder="Full Name" name="name" id="name" />
                                    <span className="error-message">{(errors.name && touched.name) ? errors.name : null}</span>
                                  </p>
                                </Col>
                                <Col md="6">
                                  <p className="form-control">
                                    <Field type="text" className="input" placeholder="Email Address" name="email" id="email" />
                                    <span className="error-message">{(errors.email && touched.email) ? errors.email : null}</span>
                                  </p>
                                </Col>
                                <Col md="12">
                                  <p className="form-control">
                                    <Field type="text" className="input" placeholder="Subject" name="subject" id="subject" />
                                    <span className="error-message">{(errors.subject && touched.subject) ? errors.subject : null}</span>
                                  </p>
                                </Col>
                                <Col md="12">
                                  <p className="form-control">
                                    <Field type="textarea" className="textarea" name="message" id="message" placeholder="Enter Message" />
                                    <span className="error-message">{(errors.message && touched.message) ? errors.message : null}</span>
                                  </p>
                                </Col>
                                <Col md="12">
                                  <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                      <FormLoader />
                                    ) : (
                                      <span>Send Message</span>
                                    )}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Google Map */}
          {/* <GoogleMap /> */}
        </section>
        <div>
          <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/Cognitude map.png`} alt="cognitude map" />
        </div>
        <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row align-items-md-center">
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                        <i className="ti ti-location-pin" />{/*  ttm-icon */}
                      </div>
                    </div>
                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Chennai, India</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Dusseldorf, Germany</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Carolina, USA</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 order-md-1 res-767-mt-20">
                <div className="text-left">
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box left-icon icon-align-top">

                    <div className="featured-content">{/*  featured-content */}
                      <div className="featured-desc">
                        <p>Nigeria, West Africa</p>
                      </div>
                    </div>
                  </div>{/*  featured-icon-box END */}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-6 order-md-3 res-767-mt-20">
                <div className="text-sm-right">
                  <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" /> info@cognitude.co.in</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} backdrop="static" keyboard={false} centered className="tj-modal">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container text-center p-4">
              <h5>Message Sent</h5>
            </div>

          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <button type="button" className="btn btn-primary  mx-2" onClick={() => setIsModalOpen(false)}>Ok</button>
          </Modal.Footer>
        </Modal>

        {/* Footer 2 */}
        <FooterTwo />

      </div>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  contactSuccess: state.auth.contactSuccess,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleContactSubmit: (formData, resetForm, setSubmitting, setFieldValue) => dispatch(userContact(formData, resetForm, setSubmitting, setFieldValue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);