import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { Styles } from '../styles/courseSearch.js';

const CourseSearch = (props) => {

    return (
        <Styles>
            {/* Course Search */}
            <div className="course-search">
                <h5>Search Course</h5>
                <Form action="#">
                    <FormControl type="text" name="search" placeholder="Search Here" title='Enter search keyword' onChange= {props.searching}/>
                    <button type="submit" title='Search'><i className="las la-search"></i></button>
                </Form>
            </div>
        </Styles>
    )
} 
  
export default CourseSearch
