import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseSidebar from './components/CourseSidebar';
import CourseItemGrid from './components/CourseItemsGrid';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';

const CourseGrid = () => {

  return (
    <div className="main-wrapper course-page">

      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox title="Courses" />

      <Styles>
        {/* Course Grid */}
        <section className="course-grid-area">
          <Container>
            <CourseItemGrid />
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />

    </div>
  )

}

export default CourseGrid;