import React from "react";
import ReactDOM from "react-dom";
import AppRouter from './routers/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-intl-tel-input/dist/main.css';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const jsx = (
	<Provider store={store}>
		<AppRouter />
	</Provider>
);

let hasRendered = false;
const render = () => {
	if (!hasRendered) {
		ReactDOM.render(jsx, document.getElementById('root'));
		hasRendered = true;
	}
};

render();