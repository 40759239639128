import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";

function FooterTwo() {

  const today = new Date();
  const year = today.getFullYear();

  return (
    <Styles>
      {/* Footer Two */}
      <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/` }}>
        <Container>
          <Row>
            <Col md="4">
              <div className="footer-logo-info">
                <div className="f-links">
                  <h5>Locations</h5>
                  <ul className="list-unstyled">
                    <li><Link to={''}><i className="las la-map-marker" style={{ 'marginTop': '10px' }}></i>Chennai, India</Link></li>
                    <li><Link to={''}><i className="las la-map-marker" style={{ 'marginTop': '10px' }}></i>Aurangabad, India</Link></li>
                    <li><Link to={''}><i className="las la-map-marker" style={{ 'marginTop': '10px' }}></i>Dusseldorf, Germany</Link></li>
                    <li><Link to={''}><i className="las la-map-marker" style={{ 'marginTop': '10px' }}></i>Carolina, USA</Link></li>
                    <li><Link to={''}><i className="las la-map-marker" style={{ 'marginTop': '10px' }}></i>Nigeria, West Africa</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + "/contact"}><i className="las la-envelope" style={{ 'marginTop': '10px' }}></i>academy@cognitude.co.in</Link></li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="f-links">
                <h5>Useful Links</h5>
                <ul className="list-unstyled">
                  <li><Link to={process.env.PUBLIC_URL + "/course-grid"}><i className="las la-angle-right"></i>Our Courses</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/contact"}><i className="las la-angle-right"></i>Contact Us</Link></li>
                </ul>
              </div>
            </Col>
            {/* <Col md="3">
                            <div className="f-post">
                                <h5>Twitter Post</h5>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                                <div className="post-box d-flex">
                                    <div className="po-icon">
                                        <i className="fab fa-twitter"></i>
                                    </div>
                                    <div className="po-content">
                                        <Link to={process.env.PUBLIC_URL + "/blog-details"}>Lorem ipsum dolor sit ...</Link>
                                        <span>Mar 30, 2019</span>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
            <Col md="4">
              <div className="f-links">
                <h5>Terms of use</h5>
                <ul className="list-unstyled">
                  <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}><i className="las la-angle-right"></i>Privacy Policy</Link></li>
                  <li><Link to={process.env.PUBLIC_URL + "/terms"}><i className="las la-angle-right"></i>Terms and Conditions</Link></li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="copytext-area text-center">
                <p>Copyright &copy; {year}</p>
                {/* <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                </ul> */}
              </div>
            </Col>
          </Row>
        </Container>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default FooterTwo
