import React, { Component } from 'react';
import { Container, Row, Col, Table, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/privacypolicy';

class TermsAndCondition extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper cart-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Terms & Conditions" />

                    <section className="privacy-policy-area">
                        <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <div className="course-details-top">
                                    <div className="course-tab-list">
                                        <div className='tab-content'>
                                            <div eventKey="overview" className="overview-tab">
                                                <div className="course-desc">
                                                    <h5>Introduction</h5>   
                                                    <p>These Terms and Conditions of COGNITUDE ACADEMY which incorporate by reference the Terms and Conditions of Website Use and which are subject to any Additional Online Terms and Conditions (if any) (together, the “Online Terms”), apply to the sale of any Online Course/Class by COGNITUDE ACADEMY.<br /><br /> Please go through the Online Terms carefully before purchasing an Online Class and print off a copy for your records. By ordering an Online Class, you are confirming your agreement to be bound by the Online Terms.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Online Class Provider</h5> 
                                                    <p>“COGNITUDE ACADEMY” is a division of Cognitude Consultancy Private Limited, a private company incorporated under the (Indian) Companies Act, 2013, and having its registered office at No.15, Jains Kences Retreat, 4th Block, 3rd floor, Reddy Street, Virugambakkam, Chennai, Tamil Nadu India. The domain name, Website and the Apps are owned, registered and operated by Cognitude Consultancy Private Limited<br /><br /> We will provide the Online Class using reasonable care and skill and will also use our reasonable endeavours to provide the Online Class advertised by “COGNITUDE ACADEMY”.
                                                    <br /><br /> We may appoint independent Faculties and Tutors to assist in providing Online Class or use third parties to arrange or supply certain aspects of, or services in connection with, our Online Class. You agree that our obligation to you is to use reasonable care in selecting competent, independent faculties and tutors and third party suppliers to provide reasonable services related to the Online Class. You agree that Cognitude Academy is not responsible for the actions or omissions of such faculties or tutors or third party suppliers.</p>  
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Definitions</h5>
                                                    <p>Below terms used herein (and not otherwise defined) have the following meanings:
                                                    </p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. “Additional Online Terms and Conditions” means any additional terms and conditions relating to an Online Class agreed through any means of communication by Cognitude Academy with a particular student
                                                        </li>
                                                        <li>
                                                        2. “Brochure” means any online material produced by Cognitude Academy that provides detailed information with respect to the Online Class 
                                                        </li>
                                                        <li> 
                                                        3. “Course Fee” means the fee payable for an Online Class but excludes any delivery charges payable in relation to the delivery of Course Materials. 
                                                        </li>
                                                        <li> 
                                                        4. “Course Materials” means the materials provided by Cognitude Academy in the course of the delivery of any Online Class, which may be (i) Online Study Materials and/or (ii) Physical Study Materials(if needed).
                                                        </li>
                                                        <li> 
                                                        5. “Online Class” means either an On Demand Online Class or a Scheduled Online Class provided by “COGNITUDE ACADEMY”
                                                        </li>
                                                        <li> 
                                                        6. “Scheduled Online Class” means an online class which is only available for access by you on a predetermined start date
                                                        </li>
                                                        <li> 
                                                        7. “Terms and Conditions of Website Use” means the terms and conditions pursuant to which you may access the Website and which can be found.
                                                        </li>
                                                        <li> 
                                                        8. “Website” means www.cognitudeacademy.com which is operated by “COGNITUDE ACADEMY” “You” means the individual purchasing the Online Class.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p> The use of singular words herein also include the plural.
                                                    </p>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Purchasing and Confirmation</h5>
                                                    <p>In order to purchase an Online Class you must provide the required information and pay the amount specified. You must ensure that all information provided is complete and accurate. In order to access our Resources, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.
                                                    <br /><br />
                                                    When you place an order for an Online Class, you are offering to purchase that Online Class on the Online Terms. You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your accounts.
                                                    <br /><br />
                                                    A legally binding agreement shall only come into existence once:
                                                    
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. Cognitude Academy has accepted your offer to purchase an Online Class by sending you an order confirmation email which will provide you with a link to access the Online Class, along with log on details (if applicable); and Accessing (or attempting to access) any of our Resources by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or unconventional means.
                                                        </li>
                                                        <li>
                                                        2. Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.
                                                        </li>
                                                        <li> 
                                                        3. Attempting to copy, duplicate, reproduce, sell, trade, or resell our resources is strictly prohibited. You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability. 
                                                        </li>
                                                    </ul><br /><br />
                                                    The Online Terms, together with your purchase offer and the order confirmation email, constitute the entire agreement between us (the “Contract”) and supersedes and replaces any other terms and conditions previously published by us and any other understanding, undertaking, representation, warranty, arrangement, promise or statement of any nature whatsoever made by us to you, whether oral, written or otherwise, relating to the subject matter of the Online Terms. You acknowledge that you have not relied on any understanding, undertaking, representation, warranty, arrangement, promise or statement made or given by us or on our behalf, which is not set out in the Contract. 
                                                    <br /><br />
                                                     You must check the details on the order confirmation email when you receive it.
                                                     <br /><br />
                                                    Where your order consists of multiple Online Classes, each individual Online Class will be treated by Cognitude Academy as a separate offer to purchase. Acceptance of your offer to purchase one or more of the Online Classes will not be an acceptance by Cognitude Academy of your offer to purchase any other Online Class which make up your order.
                                                    <br /><br />
                                                     We reserve the right, in our sole and absolute discretion and without the need to give a reason, to refuse to accept an offer to purchase one or more Online Classes
                                                     <br /><br />
                                                    Before a Contract comes into existence between you and us, we reserve the right to increase or decrease the advertised Course Fee for an Online Class and/or to amend any of the information contained in the Online Class description. We also reserve the right to withdraw at any time Online Class advertised for sale on the Website and/or in any Brochure.
                                                    </p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Payment</h5> 
                                                    <p>The Course Fee for any Online Class at any given time and any additional delivery charges payable in relation to the delivery of Course Materials, if applicable, will be displayed on the Website and/or in a Brochure and/or will be notified to you by a Cognitude Academy’s customer services representative.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Online Class Content and Access Terms</h5> 
                                                    <p>The description of the Online Class on the Website and/or in any relevant Brochure will set out details of the contents of the available Online Class.<br /><br />Except as set out in the description of the Online Class on the Website and/or in any relevant Brochure, no additional Course Materials and/or tuition (either online, face to face or classroom) will be provided by Cognitude Academy.
                                                    <br /><br />Upon receipt of an order confirmation email from Cognitude Academy you will be notified of when you will have access to the Online Class purchased and for the length of time such access will be made available to you, unless any such Online Class is removed. <br /><br />The receipt of an Online Class is personal to you and, unless otherwise agreed by Cognitude Academy pursuant to Additional Terms and Conditions, you may not share or transfer your rights to access the Online Class or provide an Online Class to any other person.<br /><br />You may incur charges to your internet service provider while you are accessing and/or downloading the Course Materials. Charges may also be payable to third parties for use of the software necessary to access and/or download the Course Materials. You are responsible for paying any such charges.<br /><br />You acknowledge that Cognitude Academy operates a zero-tolerance policy in relation to inappropriate behaviour of students. In particular, abusive or violent behaviour directed at Cognitude Academy’s staff or other students and unfair or dishonest practices will not be tolerated under any circumstances. Cognitude Academy may, in its reasonable discretion and without liability or an obligation to refund any Course Fee, refuse to supply any Online Class to any student who engages in inappropriate behaviour.<br /><br />We reserve the right to monitor IP addresses that are used to access the Course Materials and, if a student if found to be in contravention of the access rules (set out in Clauses 5.4, 5.6) we may terminate a student’s access to the Online Class and the Course Material. In such circumstances, no refund will be given.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Modifications To Online Courses or Technology Enhancements</h5> 
                                                    <p>From time to time, Cognitude Academy may make modifications or enhancements to the Online Courses. You will have access to such changes free of charge only to the extent that such changes relate to the Online Classes purchased by you.</p>  
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Technical Support and Access</h5>
                                                    <p>If you are unable to access an Online Class, Cognitude Academy will use reasonable endeavours to provide a solution where Cognitude Academy has direct control over the system, software or settings involved. If the issue lies with your hardware, systems, software or settings thereof, Cognitude Academy may, in its discretion, provide assistance to you to help you resolve the issue but we cannot guarantee that any assistance provided will resolve your issue.
                                                    <br /><br />You accept and acknowledge that periods of downtime may be required in respect of the information technology infrastructure connected to the Website and that technical assistance may not be available during such periods of downtime. Further you accept that you will not have a claim for breach of contract or otherwise in respect of such period of unavailability.
                                                    <br /><br />Cognitude Academy will use reasonable endeavours to make the Online Course available but cannot guarantee uninterrupted, timely or error free availability or that defects will be corrected. Cognitude Academy reserves the right to suspend access to the Website for the purpose of scheduled or emergency maintenance, repairs or upgrades to improve the performance or functionality of the Website.
                                                    <br /><br />You also accept and acknowledge that Cognitude Academy cannot be held responsible for any delay or disruptions to your access to the Online Course as a result of such suspension or any of the following:
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The operation of the internet and the world wide web, including but not limited to viruses
                                                        </li>
                                                        <li>
                                                        2. Any firewall restrictions that have been placed on your network or the computer you are using to access the Online Course
                                                        </li>
                                                        <li> 
                                                        3. Failures of telecommunications links and equipment 
                                                        </li>
                                                        <li> 
                                                        4. Updated browser issues. 
                                                        </li>
                                                    </ul>
                                                    </p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Warranties</h5> 
                                                    <p>Cognitude Academy will provide assistance in accordance with the Online Class description, which is set out on the Website and/or in any relevant Brochure.<br /><br /> Cognitude Academy expects you to take reasonable care to verify that the Online Classes will meet your needs. Cognitude Academy does not make any commitment to you that you will obtain any particular result from your use of the Online Class or that you will obtain any particular qualification on completion of the Online Class (unless otherwise stated on the Website and/or in any relevant Brochure).
                                                    <br /><br /> Cognitude Academy does not make any representation, guarantee or commitment to you that the Online Classes will be error free. <br /><br /> Cognitude Academy does not make any commitment that the Online Course or Course Material will be compatible with or operate with your software or hardware.<br /><br />All representations, warranties and/or terms and/or commitments not expressly set out in the Online Terms (whether implied by law, conduct, statute or otherwise) are hereby excluded to the maximum extent permissible at law.</p>  
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Limitation of Liability</h5>
                                                    <p>The exclusions and limitations of liability contained in the Online Terms do not apply to a party’s liability: (i) for fraud or wilful default; (ii) for death or personal injury caused by its negligence; or (iii) where such limitation or exclusion cannot lawfully be excluded.
                                                    <br /><br />Except as set out in the Online Terms, Cognitude Academy shall not be responsible for losses that result from its failure to comply with the Online Terms including, but not limited to, losses that fall into the following categories:
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. indirect or consequential losses;
                                                        </li>
                                                        <li>
                                                        2. loss of income or revenue
                                                        </li>
                                                        <li> 
                                                        3. loss of business; 
                                                        </li>
                                                        <li> 
                                                        4. loss of anticipated savings;  
                                                        </li>
                                                        <li> 
                                                        5. loss or corruption of data. 
                                                        </li>
                                                    </ul><br /><br />Cognitude Academy is not responsible to you for any data that you lose either (a) as a result of accessing the Online Class; or (b) during completion of any Online Class via the Website. It is your responsibility to ensure that you regularly save and back up
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. All data which you hold on the computer from which you are accessing the Online Class; and 
                                                        </li>
                                                        <li>
                                                        2. All data that you are inputting when completing the Online Class.
                                                        </li>
                                                    </ul><br /><br />Cognitude Academy will not be held responsible for any delay or failure to comply with its obligations under the Online Terms if the delay or failure arises from any cause which is beyond Cognitude Academy’s reasonable control. This condition does not affect your statutory rights.
                                                    </p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Disclaimer</h5> 
                                                    <p>The Online Classes are for training purposes only. Cognitude Academy will not accept any responsibility to any party for the use of the Online Class for any purpose other than training for educational purposes, including but not limited to, the giving of advice by you to any third party.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Intellectual Property</h5> 
                                                    <p>At all times, Cognitude Academy remain the owner of the intellectual property in the Online Class. Other than as specified below, no Online Class and/or Course Materials, nor any part of it may be reproduced, stored in a retrieval system or transmitted in any form or by any means without the prior written permission of Cognitude Academy.<br /><br />All content and materials available on www.cognitudeacademy.com, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of Cognitude Consultancy Private Limited, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by Cognitude Consultancy Private Limited. 
                                                    <br /><br />All content and materials available on www.cognitudeacademy.com, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of Cognitude Consultancy Private Limited, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by Cognitude Consultancy Private Limited.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Data Protection and Privacy</h5> 
                                                    <p>Cognitude Academy will process the information it receives from you or otherwise holds about you in accordance with the Online Terms and its privacy policy. You consent to the use by Cognitude Academy of such information in accordance with Cognitude Academy’s privacy policy.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Changes By Us</h5> 
                                                    <p>An Online Class description constitutes only an indication of what the Online Course is planned to accomplish. The Online Class description does not form part of the Contract between you and us. We will use our reasonable endeavours to provide an Online Class as described.</p>  
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Inconsistency of Terms</h5>
                                                    <p>
                                                    If there is any conflict between these Terms and Conditions of Online Classes, any applicable Additional Online Terms and Conditions and the Terms and Conditions of Website Use, the conflict shall be resolved according to the following order of priority
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The Additional Online Terms and Conditions;
                                                        </li>
                                                        <li>
                                                        2. The Terms and Conditions of Online Classes; and
                                                        </li>
                                                        <li>
                                                        3. The Terms and Conditions of Website Use.
                                                        </li>
                                                    </ul>
                                                    </p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Governing Law</h5> 
                                                    <p>The website of Cognitude Academy is controlled by Cognitude Consultancy Private Limited from our head office located in Chennai, India. It can be accessed by most states around the country. As each state has laws that may differ from those of our State, India by accessing our website, you agree that the statutes and laws of Head office, India without regard to the conflict of laws. Furthermore, any action to enforce this shall be brought in the state courts located in our present Head office, India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Cancellation and Refund</h5> 
                                                    <p>Cancellation of order is not possible once the payment has been made. No refunds will be given except in the event of cancellation or non-performance of service by COGNITUDE ACADEMY. For further information, refer to the Cancellation and Refund Policy.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>General</h5> 
                                                    <p>Cognitude Academy reserves the right to recover any reasonable debt collection costs in connection with the Online Terms.<br/><br/>Cognitude Academy may update or amend the Online Terms from time to time to comply with law or to meet its changing business requirements or to correct any errors without notice to you. Any updates or amendments will be posted on the Website or would be notified through Circulars. <br/><br/>You may not assign or sub-contract any of your rights or obligations under the Online Terms to any third party unless agreed to by Cognitude Academy pursuant to Additional Online Terms and Conditions. <br/><br/>Cognitude Academy may assign, transfer or sub-contract any of its rights or obligations under the Online Terms to any third party at its discretion.<br/><br/> You must not engage any faculty or tutor to serve you independently or contact any Faculty privately and disrupt the trade practice by paying them a separate fee and take special assistance.<br/><br/>If any of the Online Terms are found to be illegal, invalid or unenforceable by any court of competent jurisdiction, the rest of the Online Terms shall remain in full force and effect. <br/><br/> Any notices required to be served on you by Cognitude Academy under the Online Terms will be deemed properly served if emailed or posted to the email address or address notified by you to Cognitude Academy respectively. Any notices required to be served on Cognitude Academy by you will be deemed properly served if sent by email or post to the address.<br/><br/>A notice sent by post is deemed to be given on the day it was posted as evidenced by the sender. A notice sent by email is deemed to be given on the day it was sent.<br /><br />The Contract between you and Cognitude Academy will be concluded in English only.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Contact Information</h5> 
                                                    <p>If you have any questions or comments about these our Terms of Service as outlined above, you can contact us: Cognitude Consultancy Private Limited, No.15, Jains Kences Retreat, 4th Block, 3rd floor, Reddy Street, Virugambakkam, Chennai, Tamil Nadu India.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Refund and Cancellation Policy</h5> 
                                                    <p>Our focus is complete customer satisfaction. In the event, if you are displeased with the services provided, we will refund back the money, provided the reasons are genuine and proved after investigation. Please read the fine prints of each deal before taking the services.<br /><br />In case of dissatisfaction from our services, clients have the liberty to cancel their services and request a refund from us. Our Policy for the cancellation and refund will be as follows:</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Cancellation Policy</h5> 
                                                    <p>Customer satisfaction is our main priority. If we fail to provide services from our end, but the customer has paid for the service then customer will be liable to mention the same to us within 24 hours. If the customer changes its mind for the services taken, we will not be liable to cancel the service.<br /><br /> Before processing any refund, we reserve the right to make best effort to complete the service. In case, you are not satisfied with the service, a cancellation fee of 20% + earned fee + fee or tax paid to government or any third party would be applicable.</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Force Majeure</h5> 
                                                    <p>Refund from Cognitdue Academy will not be considered if there is a breach of its satisfaction guarantee policy or default under any terms of service, and shall not be liable to the Client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labour strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a “Force Majeure Event”).</p>  
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Refund Policy</h5> 
                                                    <p>We will refund the money only in case we fail to provide service to our customers from our end. Applicant need to apply for the same via email at info@cognitude.co.in<br /><br />Refund will not be provided to the user, once it is earned by the company, because of the time and energy invested by our manpower for their work done, which is not returnable. So basically, all the processing fees, payment gateway charges, admin fees, and cost of resources for the work done, will be deducted. In short, further, we can’t refund any money which is paid to government bodies, such as fees or taxes, or to other third parties with a role in processing your order</p>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default TermsAndCondition
