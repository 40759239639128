import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from '../account/styles/account.js';
import FormLoader from '../../components/FormLoader';

import { getAdmin } from '../../utils/UserDetails'
import { loginUser, setLoginSuccessState } from '../../actions/admin.js';

const Login = (props) => {
  const history = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .required('Enter Your Email')
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Enter valid email"),

    password: Yup.string()
      .required("Password is required")
  });

  const [isPwdLabel, setPwdLabel] = useState(null);
  const [signInData, setSignInData] = useState({ errMsg: "", isLoading: false });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setPwdLabel('');
    setCurrentUser(getAdmin())
  }, [])

  useEffect(() => {
    if (currentUser) {
      history.push("/admin-home");
    }
  }, [currentUser])

  useEffect(() => {
    if (props.loginError) {
      setSignInData({
        errMsg: props.loginError,
      })
      setTimeout(() => {
        setSignInData(prevData => {
          return { ...prevData, errMsg: "" }
        })
      }, 2000);
    }
  }, [props.loginError])

  useEffect(() => {
    if (props.loginSuccess) {
      let redirectLink = "/admin-home";

      props.removeLoginSuccess('')
      history.push(redirectLink)
    }
  }, [props.loginSuccess])

  const onSignInHandler = (formData, setSubmitting, setFieldValue) => {
    let loginData = {};

    if (formData.email) {
      loginData.email = formData.email;
    }
    if (formData.password) {
      loginData.password = formData.password;
    }

    props.handleLoginSubmit(loginData, setSubmitting, setFieldValue)
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">
        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Admin Sign In</h3>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange={false}
                    onSubmit={(formData, { setSubmitting, setFieldValue }) => {
                      setSignInData({ errMsg: "", isLoading: true })
                      onSignInHandler(formData, setSubmitting, setFieldValue);
                    }}
                  >
                    {formProps => {
                      const { isSubmitting, errors, touched } = formProps;
                      return (
                        <Form>
                          <div className="form">
                            <p className="form-control">
                              <label htmlFor="login_user">Email</label>
                              <Field type="email" name="email" id="email" className="input-cls" placeholder="Email" />
                              <span className="error-message">{(errors.email && touched.email) ? errors.email : null}</span>
                            </p>
                            <p className="form-control">
                              <label htmlFor="login_password">Password</label>
                              <Field type="password" name="password" id="password" className="input-cls" placeholder="Password" />
                              <span className="error-message">{(errors.password && touched.password) ? errors.password : null}</span>
                            </p>
                            <span className="error-message mt-2">{signInData.errMsg}</span>
                            <button type="submit" className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                              {isSubmitting ? (
                                <FormLoader />
                              ) : (
                                <span>Sign In</span>
                              )}
                            </button>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  )
}

const mapStateToProps = state => ({
  loginSuccess: state.admin.loginSuccess,
  loginError: state.admin.loginError
})

const mapDispatchToProps = (dispatch) => {
  return {
    handleLoginSubmit: (userInfo, setSubmitting, setFieldValue) => dispatch(loginUser(userInfo, setSubmitting, setFieldValue)),
    removeLoginSuccess: () => dispatch(setLoginSuccessState(''))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);