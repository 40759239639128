import React, { Component } from 'react';
import HeaderTwo from './components/HeaderTwo';
import HeroSlider from './components/HeroSlider';
import IconBox from './components/IconBox';
import AboutUsTwo from './components/AboutUsTwo';
import CourseFilter from './components/CourseFilter';
import FooterTwo from './components/FooterTwo';

export default class HomeOne extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                {/* Header */}
                < HeaderTwo />

                {/* Hero Slider */}
                < HeroSlider />

                {/* Icon Box */}
                {/* < IconBox /> */}

                {/* About Area */}
                < AboutUsTwo />

                {/* Course Filter */}
                < CourseFilter />

                {/* Testimonial Slider */}
                {/* < TestimonialSlider /> */}

                {/* Faq & Event Area */}
                {/* < FaqEvent /> */}

                {/* Team Slider */}
                {/* < TeamSlider /> */}

                {/* Help Area */}
                {/* < HelpArea /> */}

                {/* Blog Area */}
                {/* < HomeBlog /> */}

                {/* Campus Tour */}
                {/* < CampusTour /> */}

                {/* Newsletter Form */}
                {/* < NewsletterForm /> */}

                {/* Footer */}
                < FooterTwo />

            </div >
        )
    }
}
