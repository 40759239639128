import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";
import { getUser, removeUserSession } from '../utils/UserDetails';
import { logoutUser } from '../actions/auth';

const HeaderTwo = (props) => {

  const history = useHistory();
  const currentUser = getUser();

  const onLogoutHandler = () => {
    removeUserSession();
    props.logoutUser(history);
  };

    return (
      <Styles>
        {/* Topbar 2 */}
        {/* <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-phone"></i>+1 (396) 486 4709</li>
                                        <li className="list-inline-item"><i className="las la-envelope"></i>enquery@domain.com</li>
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>795 South Park Avenue, CA
                                    </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline bar-lang">
                                        <li className="list-inline-item">
                                            <Dropdown>
                                                <Dropdown.Toggle><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" /> English</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/fra.png"} alt="" /> French</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/ger.png"} alt="" /> German</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/spa.png"} alt="" /> Spanish</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brazilian</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-social">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-instagram"></i></a></li>
                                    </ul>

                                    <ul className="list-unstyled list-inline sidebar-button">
                                        <li className="list-inline-item nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container className='header-container'>
            <Row>
              <Col md="3">
                <div className="logo" style={{margin : '-11px 0px 0px 0px'}}>
                  <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/COGNiTUDE Academy Logo3.jpg"} alt="" /></Link>
                </div>
              </Col>
              <Col md="9">
                <div className="menu-box d-flex justify-content-end">
                  <ul className="nav menu-nav">
                    <li className="nav-item">
                      <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/"}>Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/about") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
                    </li>

                    <li className="nav-item">
                      <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/course-grid") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/course-grid"}>Courses</Link>
                      {/* <ul className="dropdown list-unstyled">
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                            </ul> */}
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/contact") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                    </li>

                    {(!currentUser) ?
                      <>
                        <li className="nav-item">
                          <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/login") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/login"}>Sign In</Link>
                        </li>
                        <li className="nav-item">
                          <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/registration") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/registration"}>Sign Up</Link>
                        </li>
                      </>
                    :
                      <>
                        <li className="nav-item">
                          <Link className={`nav-link ${(history.location.pathname === process.env.PUBLIC_URL + "/my-learnings") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/my-learnings"}>My learnings</Link>
                        </li>
                        <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle profile-nav" data-toggle="dropdown" >{<img className="profile-icon rounded-circle currentprfl img-padding"
                                alt="profile-logo" src={'/assets/images/profile1.jpg'}
                                onError={(e)=>{e.target.onerror = null; e.target.src = '/assets/images/profile1.jpg'}}
                              />}</Link>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <span className="nav-link ">{currentUser ? (currentUser?.user_name) : ''}</span>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link " to={""} onClick={() => onLogoutHandler()}>Sign Out</Link>
                          </li>
                        </ul>
                      </li>
                      </>
                    }
                  </ul>
                
                  {/* <div className="language-cls">
                                        <ul className="list-unstyled list-inline bar-lang">
                                            <li className="list-inline-item">
                                                <Dropdown>
                                                    <Dropdown.Toggle as="a"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                    <Dropdown.Menu as="ul">
                                                        <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" /> English</Dropdown.Item>
                                                        <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/fra.png"} alt="" /> French</Dropdown.Item>
                                                        <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/ger.png"} alt="" /> German</Dropdown.Item>
                                                        <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/spa.png"} alt="" /> Spanish</Dropdown.Item>
                                                        <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brazilian</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>   
                                    </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    )
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { logoutUser })(HeaderTwo);
