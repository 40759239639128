import Axios from '../config/axios';
import {
  getUser
} from '../utils/UserDetails';

class CourseService {

  async getCourses() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get-courses', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': (currentUser) ? `Bearer ${currentUser.api_token}` : ''
        }
      }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const courses = data;
      return courses;
    })
  }

  async getCategories() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get-categories', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': (currentUser) ? `Bearer ${currentUser.api_token}` : ''
        }
      }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const categories = data;
      return categories;
    })
  }

  async getEnrollment() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/get-enrollment', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${currentUser.api_token}`
        }
      }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const enrollments = data;
      return enrollments;
    })
  }

  async user() {
    let currentUser = getUser();

    return await fetch(
      process.env.REACT_APP_BACKEND_BASEURL + '/api/user', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${currentUser.api_token}`
        }
      }
    ).then(function (res) {
      return res.json();
    }).then((data) => {
      const users = data;
      return users;
    })
  }

  addEnrollment = async (enrollmentData) => {
    let returnData;

    await Axios.post('/add-enrollment', enrollmentData).then(function (response) {
      if (response.data.details.status === true) {
        returnData = {
          'status': true,
          'enrollData': response.data.details,
        }
      } else {
        returnData = {
          'status': false,
        }
      }
    }).catch((error) => {
      console.log(error);
    })

    return returnData;
  }
}
export default new CourseService();