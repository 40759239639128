import Axios from '../config/axios.js';

class AuthService {

  userLogin = (loginData) => {
    return Axios.post("login", loginData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  registerLearningHub = (formdata) => {
    return Axios.post('register', formdata)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  addContact = (formdata) => {
    return Axios.post('/add-contact', formdata)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  checkUniqueEmail = (email) => {
    let emailData = {
      'email': email,
    }
    return Axios.post('/check-unique-email', emailData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  verifyEmail = (verifyData) => {
    return Axios.post('/check-verify-email', verifyData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  checkUniquePhone = (phoneData) => {
    return Axios.post('/check-unique-phone', phoneData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  googleLogin = (googleData) => {
    return Axios.post('/login', googleData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }


  googleRegister = (googleData) => {
    return Axios.post('/register', googleData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }


  checkUserExist = (googleData) => {
    return Axios.post('/check-unique-email', googleData)
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  logoutUser = () => {
    return Axios.post('/logout', [])
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }
}
export default new AuthService();