import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Styles } from './styles/admin.js';
import { Container, Card, Row, Col, Button, Modal } from 'react-bootstrap';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";

import { getCategories } from '../../actions/admin';
import { updateCategory, deleteCategory } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { ModalStyles } from './styles/home';
import Layout from './layout/Layout.js';

const Categories = (props) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState({}); //particular row
  const [categories, setCategories] = useState([]);
  const [groupedOptions, setGroupedOptions] = useState({});

  useEffect(() => {
    props.getCategories();
  }, []);

  useEffect(() => {
    setCategories(props.allCategories);
  }, [props.allCategories])

  const columns = useMemo(clickHandler => [
    {
      name: 'ID',
      selector: row => row.category_id,
      sortable: true,
    },
    {
      name: 'Category Name',
      selector: row => row.category_name,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (row.status_id === 1) ? 'Active' : 'Deactivated',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.category_id)}>Edit</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Delete',
      cell: (row) => <Button variant="danger" onClick={() => handleDelete(row.category_id)}>Delete</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]);

  const initialValues = {
    category_id: "",
    category_name: "",
    category_parent_id: "",
    status_id: 1
  }

  const handleButtonClick = (state) => {
    let course = props.allCategories.filter(item => {
      return item.category_id === state
    });
    setCategory(course[0]);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const categorySchema = Yup.object().shape({
    category_name: Yup.string()
      .required('Enter Category Name'),

    category_parent_id: Yup.number()
      .typeError('Must be a number')
      .required("Category Parent is required"),
  });

  const onSubmitHandler = (formData) => {
    let categoryData = {};

    if (formData.category_name) {
      categoryData.category_name = formData.category_name;
    }

    if (formData.status_id) {
      categoryData.status_id = category.status_id;
    }

    categoryData.category_id = formData.category_id;
    categoryData.category_parent_id = formData.category_parent_id;

    let newCategories = [...categories];
    let index = newCategories.findIndex(item => item.category_id === formData.category_id);
    newCategories[index]['category_name'] = formData.category_name;
    newCategories[index]['category_parent_id'] = formData.category_parent_id;
    newCategories[index]['status_id'] = category.status_id;

    setCategories(newCategories);

    props.updateCategory(categoryData);
    setShow(false);
  }

  const handleRadioChange = (val) => {
    let newCategory = { ...category };
    newCategory.status_id = val;
    setCategory(newCategory);
  }

  const handleDelete = (data) => {
    let newCategories = [...categories];
    let index = newCategories.findIndex(item => item.category_id === data);

    newCategories[index].status_id = 2;
    setCategories(newCategories);

    let delInfo = {
      category_id: data
    }
    props.deleteCategory(delInfo);
  }

  const onCreateHandler = (formData) => {
    let temp = categories.find(item => item.category_id === parseInt(formData.category_parent_id));

    let categoryData = {};
    categoryData.category_name = formData.category_name;
    categoryData.status_id = formData.status_id;
    categoryData.category_parent_id = temp.category_parent_id;

    props.updateCategory(categoryData);
    setTimeout(() => {
      props.getCategories();
      setShowModal(false);
    }, 3000);  
  }

  return (
    <Styles>
      <Layout>

        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Categories List</h5>
                </div>
                <div className='col' style={{textAlign : 'end'}}>
                <Button variant="primary"  onClick={() => setShowModal(true)}>Add New</Button>
                </div>
              </div>
              <Card>
              <Card.Body>
                <DataTableComponent
                  columns={columns}
                  data={categories}
                />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* edit category modal */}
        <Modal show={show} onHide={handleClose}>
        <ModalStyles>
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Edit Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <Formik
              initialValues={category}
              validationSchema={categorySchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onSubmitHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Category Name</label>
                        <Field type="text" name="category_name" id="categoryName" className="form-control mb-3" placeholder="Category Name" defaultValue={category.category_name} />
                        <span className="error-message">{(errors.category_name && touched.category_name) ? errors.category_name : null}</span>
                      </p>
                      <p>
                        <label htmlFor="parent" style={{fontWeight: 500}}>Category Parent</label>
                        <Field as="select" name="category_parent_id" className="form-control mb-3">
                          <option value="">Select</option>
                          {categories.map((cate, index) => {
                            if (cate.category_parent_id === 0) {
                              return <option value={cate.category_id} key={index} selected={(category.category_parent_id === cate.category_parent_id) ? 'selected' : null}>{cate.category_name}</option>
                            }
                          })}
                        </Field>
                        <span className="error-message">{(errors.category_parent_id && touched.category_parent_id) ? errors.category_parent_id : null}</span>
                      </p>
                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className='mb-3 mr-3'>
                          <Field type="radio" name="status_id" defaultValue="1" checked={(category.status_id === 1) ? 'checked' : null} onChange={() => handleRadioChange(1)} />
                          Yes
                        </label>
                        <label className='mb-3'>
                          <Field type="radio" name="status_id" defaultValue="2" checked={(category.status_id === 2) ? 'checked' : null} onChange={() => handleRadioChange(2)} />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles>
        </Modal>
        {/* create category modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <ModalStyles>
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{textAlign : 'center'}}>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Formik
              initialValues={initialValues}
              validationSchema={categorySchema}
              validateOnChange={false}
              onSubmit={(formData) => {
                onCreateHandler(formData);
              }}
            >
              {formProps => {
                const { isSubmitting, errors, touched } = formProps;
                return (
                  <Form>
                    <div className="form modal-padding">
                      <p>
                        <label htmlFor="name" style={{fontWeight: 500}}>Category Name</label>
                        <Field type="text" name="category_name" id="categoryName1" className="form-control mb-3" placeholder="Category Name" />
                        <span className="error-message">{(errors.category_name && touched.category_name) ? errors.category_name : null}</span>
                      </p>
                      <p>
                        <label htmlFor="parent" style={{fontWeight: 500}}>Category Parent</label>
                        <Field as="select" name="category_parent_id" className="form-control mb-3">
                          <option value="">Select</option>
                          {categories.map((cate, index) => {
                            if (cate.category_parent_id === 0) {
                              return <option value={cate.category_id} key={index}>{cate.category_name}</option>
                            }
                          })}
                        </Field>
                        <span className="error-message">{(errors.category_parent_id && touched.category_parent_id) ? errors.category_parent_id : null}</span>
                      </p>
                      <div id="my-radio-group" className='mb-2' style={{fontWeight: 500}}>Active</div>
                      <div role="group" aria-labelledby="my-radio-group">
                        <label className='mb-3 mr-3'>
                          <Field type="radio" name="status_id" value="1" checked />
                          Yes
                        </label>
                        <label className='mb-3'>
                          <Field type="radio" name="status_id" value="2" />
                          No
                        </label>
                      </div>
                      <button type="submit" style={{fontWeight: 500}} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <FormLoader />
                        ) : (
                          <span>Create</span>
                        )}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
          </ModalStyles>
        </Modal>
      
      </Layout>
    </Styles >
  )
}

const mapStateToProps = (state) => ({
  allCategories: state.admin.adminCategories,
  categoryUpdate: state.admin.updateCategory
});

export default connect(mapStateToProps, { getCategories, updateCategory, deleteCategory })(Categories);