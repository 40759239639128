import {
  GET_CATEGORIES,
  GET_COURSES,
  USER,
  GET_ENROLLMENT,
  ADD_ENROLLMENT
} from './types';
import CourseService from '../services/course.service';

export const getCategories = () => async (dispatch) => {
  try {
    const resultData = await CourseService.getCategories();
    dispatch({
      type: GET_CATEGORIES,
      payload: resultData.data,
    });
  } catch (err) {
    console.log(err)
  }
}

export const getCourseTypes = (getAll = 0) => async (dispatch) => {
  const resultData = await CourseService.getCourseTypes(getAll);

  if (resultData?.status && resultData.status === true) {
    console.log(resultData);
  }
}

export const getCourses = () => async (dispatch) => {
  try {
    const res = await CourseService.getCourses();

    dispatch({
      type: GET_COURSES,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
}

export const getEnrollment = () => async (dispatch) => {
  try {
    const res = await CourseService.getEnrollment();

    dispatch({
      type: GET_ENROLLMENT,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
}

export const user = () => async (dispatch) => {
  try {
    const res = await CourseService.user();

    dispatch({
      type: USER,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
}

export const addEnrollment = (enrollmentData) => async (dispatch) => {
  try {
    const res = await CourseService.addEnrollment(enrollmentData);

    if (res.status) {
      console.log('success')
    } else {
      console.log('Try again')
    }

    dispatch({
      type: ADD_ENROLLMENT,
      payload: res,
    });
  } catch (err) {
    console.log(err);
  }
}