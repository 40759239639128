import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAdmin } from '../utils/UserDetails';

function AdminPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest}
      render={
        props =>
        (
          getAdmin() ? <Component {...props} /> : <Redirect to={
            {
              pathname: '/admin'
            }
          }
          />
        )
      }
    />
  )
}

export default AdminPrivateRoute;