import React, { useEffect, useState } from 'react';
import { Styles } from './styles/admin';
import { connect } from 'react-redux';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';

import DataTable from 'react-data-table-component';

import { getUsers, updateRole } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import Layout from './layout/Layout';

const Users = (props) => {
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    props.getUsers();
  }, [])

  useEffect(() => {
    setUsersData(props.allUsers);
  }, [props.allUsers])

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
    },
    {
      name: 'First Name',
      selector: row => row.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.last_name,
      sortable: true,
    },
    {
      name: 'User Role',
      selector: row => row.user_role,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => <Button variant={(row.user_role === 'TRAINER') ? 'warning' : 'success'} onClick={() => handleButtonClick(row.id, row.user_role)}>{(row.user_role === 'TRAINER') ? 'Remove Trainer' : 'Make as Trainer'}</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const handleButtonClick = (id, role) => {
    let temp = [...usersData];
    let user = temp.filter(item => {
      return item.id === id
    });

    let index = temp.findIndex(item => item.id === id);
    let userInfo = {
      userId: user[0].user_id,
      role: (role === 'TRAINER') ? 'STUDENT' : 'TRAINER'
    }
    temp[index].user_role = (role === 'TRAINER') ? 'STUDENT' : 'TRAINER';

    setUsersData(temp);

    props.updateRole(userInfo);
  }

  return (
    <Styles>
      <Layout>
        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                <h5>Users List</h5>
                </div>
              </div>
              <Card>
                <Card.Body>
                <DataTableComponent
                  columns={columns}
                  data={usersData}
                />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  allUsers: state.admin.users,
});

export default connect(mapStateToProps, { getUsers, updateRole })(Users);