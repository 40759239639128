import React, { Component } from 'react';
import { Container, Row, Col, Table, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/privacypolicy';

class PrivacyPolicy extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper cart-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Privacy Policy" />

                    <section className="privacy-policy-area">
                        <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <div className="course-details-top">
                                    <div className="course-tab-list">
                                        <div className='tab-content'>
                                            <div eventKey="overview" className="overview-tab">
                                            <div className="course-desc">
                                                    <p>Welcome to Cognitude Academy! Your privacy is important to us. To better protect your privacy, and to enhance video conferencing experience for all visitors, we provide this privacy policy.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Scope</h5>
                                                    <p>Cognitude Academy website/platform/application (hereinafter referred to as “App”, “we”, “our”, “us”) is committed to protecting and respecting your privacy.<br /><br />This policy (this “Privacy Policy”), together with the Terms and Conditions of the website/platform outlines the App’s privacy practices regarding the collection, use and safeguard of your information through the App and the services offered thereupon including Cognitude Academy platform.<br /><br />By using the App or registering or signing up for the App and submission of personal information; or if you are a minor, the submission of your personal information either by your parents (or your guardian) or by you with the permission of your parents (or guardian) to the App will be deemed as your acceptance of this Privacy Policy.<br /><br />The Terms are an electronic record in terms of the Information Technology Act, 2000 (as amended / re-enacted) (“IT Act") and rules thereunder, and is published in accordance with the provisions of Rule 3 (1) of the of the Information Technology (Intermediaries Guidelines) Rules, 2011, which mandates for publishing of rules and regulations, privacy policy and terms of use for access or usage of Application. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Changes to the Privacy Policy</h5>
                                                        <p>
                                                        We are obligated to protect your information in accordance with applicable laws, and regulations
                                                        <br /><br />
                                                        This Privacy Policy is subject to modification based on changes in the business, legal and regulatory requirements and will be updated online.
                                                        <br /><br />
                                                        We will make all efforts to communicate any significant changes to this Privacy Policy to you. You are encouraged to periodically visit this page to review the Privacy Policy and any changes to it.</p>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Third Party Services</h5>
                                                    <p>Please note that your mobile service provider, mobile operating system provider, third party applications on your device, social media platforms and websites that you access, third party operators may also collect, use and share information about you and your usage. We cannot control how these third parties collect, use, share or secure this information. For information about third-party privacy practices, please consult their respective privacy policies.</p>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Personal Information we collect</h5>
                                                    <p>Personal information is defined as information that can be used to identify you and may include details such as your name, age, gender, contact information, products and services you are interested in or require more information about. Insofar as sensitive personal information is concerned, it will carry the meaning as may be defined by applicable laws from time to time.<br /><br />
                                                    The following is the manner in which we collect, use, share and retain personal information:
                                                    </p><br /><br />
                                                    <p><b>1. Collection:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. You agree that the App may collect such personal information, whenever relevant, to help providing you with information and to complete any transaction or provide any product you have requested or authorized. You also consent to the collection of certain personal information in the course of your applying for the products and/or services.
                                                        </li>
                                                        <li>
                                                        2. You agree that the App allows you to share your screen with other users. The users with whom your screen has been shared would be able to take screenshots of your shared screen (“Screenshot”) without any notification to you.
                                                        </li>
                                                        <li> 
                                                        3. You agree that the App allows the Host to record the video or audio conferencing (“Recording”). Once the recording has started you would be able to see the recording text or icon on your App conference screen.
                                                        </li>
                                                        <li> 
                                                        4. The Recording shall be made available to the Host in the application, in My recordings section.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>2. Usage:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The App seeks this information either to (i) validate and process your request for the services of the App or information; (ii) to improve the quality of the App; (iii) to assist you to determine which services best meet your needs; or (iv) to facilitate our internal business operations, including the fulfilment of any legal and regulatory requirements; or (v) to provide you with recommendation about services you may be interested in, based on your use of the App; or (vi) to provide you with marketing communications and advertising that the App believe may be of interest of you ;or (vii) to facilitate your purchases .
                                                        </li>
                                                        <li>
                                                        2. The App does not use any information pertaining to the Screenshot and the Recording. The Screenshot available on the device of the user who has taken the Screenshot. The Recording is available with the Host and can share the recording link to anyone he/she wants to.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>3. Sharing:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. We may disclose personal information to our affiliates when necessary to perform services on our behalf or on your behalf, to provide display advertising and promotional services, providing search results and links (including paid listings and links), processing credit card payments, providing customer service etc.
                                                        </li>
                                                        <li>
                                                        2. We may also share personal information with external organizations or individuals if we believe that access, use, preservation or disclosure of the information is reasonably necessary to: (a)meet any applicable law, regulation, legal process or enforceable governmental request; (b)detect, prevent or otherwise address fraud, security or technical issues; (c)protect against harm to the rights, property or safety of our customers or the public, as required or permitted by law.
                                                        </li>
                                                        <li> 
                                                        3. The Host with whom the Recording is shared can share the Recording with any third party and/or user(s).
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>4. Retention:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The information so collected shall be retained only for a limited duration necessary to fulfil the purposes outlined herein unless a longer retention period is required or permitted by law and only for the purposes defined above. Once the purpose is achieved, all personal information is deleted in a safe and secure mode.
                                                        </li>
                                                        <li>
                                                        2. The Screenshot is not stored in our servers and are stored in the device of the user who has taken the screenshot.
                                                        </li>
                                                        <li> 
                                                        3. The Recording is stored on our servers for the duration based on the subscription plan. User will have option to delete his/her recordings any time.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Non-Personal Information</h5>
                                                    <p>Non-personal information is defined as any data that does not personally identify you and may include unique system or hardware identifiers, system or application software, and peripherals.<br /><br />
                                                    Any non-personal information, when clubbed with personal information shall be treated as personal information. <br/><br/>
                                                    The following is the manner in which we collect, use, share and retain nonpersonal information.
                                                    </p><br /><br />
                                                    
                                                    <p><b>1. Collection:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. You agree that the App may collect diagnostic, technical, usage related information, for the usage purposes described below.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>2. Usage:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The above mentioned information is gathered periodically to provide and improve the App and services therein, facilitate the provision of software updates, product support and other services to you (if any) and to verify compliance with the terms of this Policy.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>3. Sharing:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. To enable the App partners and third party developers to improve their software, hardware and services designed for use with the App, we may also provide any such partner or third party developer with a subset of diagnostic information that is relevant to that partner’s or developer’s software, hardware and/or services, as long as the diagnostic information is in a form that does not personally identify you.
                                                        </li>
                                                    </ul><br /><br />
                                                    <p><b>4. Retention:</b></p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. The information so collected shall be retained only for a limited duration necessary to fulfil the purposes outlined herein unless a longer retention period is required or permitted by law and only for the purposes defined above. Once the purpose is achieved, all information is deleted in a safe and secure mode.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Location Services</h5>
                                                    <p>The App, its licensees and agents, may access, collect and analyse your usage of the App and other information related to your location on a regular basis through Bluetooth and Wi-Fi signals and other technologies and data for the performance of the services provided by the App and to help improve the design, functionality, performance, and content of the App. In order to collect geo-location information, the location settings must be enabled on your device. Therefore, in some cases, the App may request that you enable the location settings.<br /><br />Except in cases where any specific services requested by you require or involve personal identification, the App collects location data anonymously and in a form that does not personally identify you. By using the App, you agree and consent to transmission, collection, maintenance, processing and use of your location data and queries to provide and improve such services of the App.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Cookies</h5>
                                                    <p>Information collected by cookies and other technologies are treated as nonpersonal information. However, to the extent that IP addresses or similar identifiers are considered personal information by local law, we treat any information linked to such identifiers as personal information. Similarly, to the extent that non-personal information is combined with personal information, we will treat the combined information as personal information..</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Other Information the App collects</h5>
                                                    <p>The App also may collect other information about you in ways that the App describe to you or otherwise with your consent. You can choose not to provide us with certain types of information, but doing so may affect your ability to avail and/or use some services.<br/><br />By clicking “I Accept” button or by usage of the App you permit to enable certain functions of the device to find the location of your device, modify or delete SD card contents, read phone statistics and identity, mount and unmount file systems, contacts saved in your device, conferencing logs and history etc.<br /><br />The features of the App may allow you to post content such as profile information, status update, comments, and other content and/or information (User Content). The ownership over the User Content created by you remains with you; however, by sharing User Content through the App, you agree to allow others to view and/or share your User Content in accordance with this Privacy Policy.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Third Party Applications, Websites & Services</h5>
                                                    <p>The App may include links to other websites/applications or may display advertisements from third parties and other content that links to third party websites. Such websites/applications are governed by their respective privacy policies, which are out of the App’s control. Use of any information you provide while using a third party website or application through the App, is governed by the privacy policy of the operator of the website / application / operator you are using/ visiting. That policy may differ from that of the App. If you can't find the privacy policy of any of such websites /application via a link from the website's homepage, you should contact the relevant website /application operator directly for more information.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5> Access, Correction and Deletion</h5>
                                                    <p>The App strives hard to keep its records updated and accurate with your latest information. You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide such information or data.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Queries and Complaints</h5>
                                                    <p>We are committed to protect your personal information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding protection of your personal information, or any privacy-related feedback or concerns you may contact: info@cognitude.co.in with subject line as “Request for clarification on Privacy Policy”.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default PrivacyPolicy
