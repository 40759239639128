export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const REGISTER_SUCCESS = 'register_success';
export const CONTACT_SUCCESS = 'contact_success';
export const REGISTER_ERROR = 'register_error';

//courses
export const GET_CATEGORIES = 'get_categories';

// admin
export const UPDATE_CATEGORY = 'update_category';
export const DELETE_CATEGORY = 'delete_category';
export const GET_COURSES = 'get_courses';
export const GET_ENROLLMENT = 'get_enrollment';
export const GET_COURSE_TYPES = 'get_course_types';
export const UPDATE_COURSE = 'update_course';
export const DELETE_COURSE = 'delete_course';
export const ADMIN_LOGIN_SUCCESS = 'admin_login_success';
export const ADMIN_LOGIN_ERROR = 'admin_login_error';
export const GET_BATCHES = 'get_batches';
export const UPDATE_BATCH = 'update_batch';
export const DELETE_BATCH = 'delete_batch';
export const GET_BATCH_TYPES = 'get_batch_types';
export const GET_USERS = 'get_users';
export const USER = 'user';
export const UPDATE_ROLE = 'update_role';
export const GET_TOPICS = 'get_topics';
export const UPDATE_TOPIC = 'update_topic';
export const DELETE_TOPIC = 'delete_topic';
export const UPDATE_BATCH_TYPE = 'update_batch_type';
export const DELETE_BATCH_TYPE = 'delete_batch_type';
export const ASSIGN_COURSE = 'assign-course';
export const ADD_ENROLLMENT = 'add-enrollment';
export const GET_ADMIN_COURSES = 'get_admin_courses';
export const GET_DEACTIVATED_COURSES = 'get_deactivated_courses';
export const GET_HOME_DETAILS = 'get_home_details';
export const GET_ENROLL = 'get_enroll';
export const ADD_COURSE = 'add_course';
export const GET_CONTACT = 'get_contact';
export const GET_CURRENCIES = 'get_currencies';
