import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Styles } from './styles/admin';
import { Container, Card, Row, Col, Button, Modal } from 'react-bootstrap';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import Layout from './layout/Layout';
import { getCourses, getBatch, updateBatch, deleteBatch, getBatchTypes, getUsers, assignCourse, getEnroll } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { getDate, getDays } from '../../utils/Common';
import { ModalStyles } from './styles/home';

const Batch = (props) => {

  const [batchData, setBatchData] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [particularBatch, setParticularBatch] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [batch, setBatch] = useState([]); //for grid
  const [batchName, setBatchName] = useState([]);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [courseName, setCourseName] = useState(null);
  const [courseData, setCourseData] = useState({});
  const [courseId, setCourseId] = useState(null);
  const [normalUsers, setNormalUsers] = useState([]);
  const [newTrainer, setNewTrainer] = useState([]);
  const [trainer, setTrainer] = useState([]);
  const [showListModal, setShowListModal] = useState(false);
 
  useEffect(() => {
    props.getCourses();
    props.getBatch();
    props.getBatchTypes();
    props.getUsers();
  }, [])

  useEffect(() => {
    setBatchData(props.allBatches)
  }, [props.allBatches])

  useEffect(() => {
    if (props.allUsers && props.allUsers.length) {
      let temp = props.allUsers.filter((item) => {
        return item.user_role === 'TRAINER'
      });
      setUsersData(temp);
    }

    setNormalUsers(props.allUsers);
  }, [props.allUsers])

  useEffect(() => {
  if (props.updatedBatch && props.updatedBatch.length){
    setBatch(props.updatedBatch)
  }
  }, [props.updatedBatch])
  
  const columns = [
    {
      name: 'ID',
      selector: row => row.coursebatch_id,
      sortable: true,
      width: '75px'
    },
    {
      name: 'Batch Name',
      selector: row => row.coursebatch_name,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => getDate(row.coursebatch_startdate),
      sortable: true,
    },
    {
      name: 'Join By Date',
      selector: row => row?.join_by_date ? getDate(row.join_by_date) : '',
      sortable: true,
    },
    {
      name: 'End Date',
      selector: row => getDate(row.coursebatch_enddate),
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => row.coursebatch_starttime,
      sortable: true,
    },
    {
      name: 'No.of Days',
      selector: row => getDays(getDate(row.coursebatch_startdate), getDate(row.coursebatch_enddate)),
      sortable: true,
    },
    {
      name: 'Batch Status',
      selector: row => row.coursebatch_status,
      sortable: true,
    },
    {
      name: 'Max Students',
      selector: row => row.coursebatch_max_students,
      sortable: true,
    },
    {
      name: 'Discounted Fee',
      selector: row => row?.discounted_fee ? (row?.discounted_fee) : 0,
      sortable: true,
    },
    {
      name: 'Trainer',
      selector: row => row?.trainer ? (row.trainer.first_name + ' ' + row.trainer.last_name) : '-',
      sortable: true,
    },
    {
      name: 'Students Joined',
      selector: row => <Button variant="light" onClick={() => handleModal(row.coursebatch_id)} disabled={(row.students && row.students === null) ? 'disabled' : null}>{(row.students && row.students === null) ? row.students.length : 0}</Button>,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (row.status_id === 1) ? 'Active' : 'Deactivated',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.coursebatch_id)}>Edit</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Delete',
      cell: (row) => <Button variant="danger" onClick={() => handleDelete(row.coursebatch_id)}>Delete</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const userColumns = [
    {
      name: 'ID',
      selector: row => row.coursebatch_id,
      sortable: true,
      width: '75px'
    },
    {
      name: 'First Name',
      selector: row => (row.user) ? row.user.first_name : null,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row.user.last_name,
      sortable: true,
    },
    {
      name: 'User Role',
      selector: row => row.user.user_role,
      sortable: true,
    },
    {
      name: 'Join Status',
      selector: row => (row.amount_paid === 1) ? 'Paid' : 'Assigned',
      sortable: true,
    }
  ];

  const initialValues = {
    coursebatch_id: "",
    coursebatch_courseid: courseData?.course_id,
    coursebatch_userid: courseData?.trainer ? (courseData?.trainer?.id) : '-',
    coursebatch_name: "",
    coursebatch_startdate: new Date(),
    coursebatch_enddate: new Date(),
    join_by_date: new Date(),
    batch_url:"",
    coursebatch_starttime: "",
    status_id: 1,
    coursebatch_status: "",
    coursebatch_type_id: "",
    coursebatch_max_students: "",
    course_fee: courseData?.course_fee,
    discounted_fee: ""
  }

  const courseValues = {
    batch_id: 0,
  }

  const duplicateNameCheck = (batchName, value) => {
      return !batchName.includes(value);
    };

  const batchSchema = Yup.object().shape({

    coursebatch_name: Yup.string()
      .required("Batch Name is required")
      .test("Unique", "Batch Name needs to be unique", (values) => {
      return duplicateNameCheck(batchName, values);
      }),

    coursebatch_startdate: Yup.date().required('Start date required'),
    coursebatch_enddate: Yup.date()
      .required('End date required')
      .min(
          Yup.ref('coursebatch_startdate'),
          "End date can't be before Start date"
      ),
    join_by_date: Yup.date()
    .required('Join By Date required')
    .min(
        Yup.ref('coursebatch_startdate'),
        "Join By Date can't be before Start date"
    )
    .max(
      Yup.ref('coursebatch_enddate'),
      "Join By Date can't be after End date"
    ),
    coursebatch_type_id: Yup.number().required('Batch Type required'),
    coursebatch_userid: Yup.number().required('Trainer required'),
    discounted_fee: Yup.number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .max(
      Yup.ref('course_fee'),
      "Discounted Price can't be greater than course Price"
    )
    
  });

  const editBatchSchema = Yup.object().shape({

    coursebatch_name: Yup.string()
      .required("Batch Name is required"),
    coursebatch_startdate: Yup.date().required('Start date required'),
    coursebatch_enddate: Yup.date()
      .required('End date required')
      .min(
          Yup.ref('coursebatch_startdate'),
          "End date can't be before Start date"
      ),
    join_by_date: Yup.date()
    .required('Join By Date required')
    .min(
        Yup.ref('coursebatch_startdate'),
        "Join By Date can't be before Start date"
    )
    .max(
      Yup.ref('coursebatch_enddate'),
      "Join By Date can't be after End date"
    ),
    coursebatch_type_id: Yup.number().required('Batch Type required'),
    coursebatch_userid: Yup.number().required('Trainer required'),
    discounted_fee: Yup.number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .max(
      Yup.ref('course_fee'),
      "Discounted Price can't be greater than course Price"
    )
    
  });

  const courseSchema = Yup.object().shape({
    batch_id: Yup.number()
      .required("Batch is required"),
  });

  const handleButtonClick = (state) => {
    let temp = batch.find(item => item.coursebatch_id === parseInt(state));
    temp.coursebatch_startdate = new Date(temp.coursebatch_startdate);
    temp.coursebatch_enddate = new Date(temp.coursebatch_enddate);
    temp.join_by_date = new Date(temp.join_by_date);
    temp.course_fee = courseData?.course_fee;

    setParticularBatch(temp);
    setShow(true);
  }

  const handleDelete = (data) => {
    let newBatches = [...batch];

    let index = newBatches.findIndex(item => item.coursebatch_id === data);

    newBatches.splice(index,1);
    setBatch(newBatches);

    let delInfo = {
      coursebatch_id: data
    }

    props.deleteBatch(delInfo);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const onEditSubmitHandler = (info) => {
    let batchInfo = {};
    batchInfo.coursebatch_id = info.coursebatch_id;
    batchInfo.coursebatch_courseid = courseData?.course_id;
    batchInfo.coursebatch_name = info.coursebatch_name;
    batchInfo.coursebatch_userid = info.coursebatch_userid;
    batchInfo.coursebatch_startdate = (info.coursebatch_startdate).toISOString().split('T')[0];
    batchInfo.coursebatch_enddate = (info.coursebatch_enddate).toISOString().split('T')[0];
    batchInfo.join_by_date = (info.join_by_date).toISOString().split('T')[0];
    batchInfo.coursebatch_starttime = info.coursebatch_starttime;
    batchInfo.batch_url = info.batch_url;
    batchInfo.coursebatch_status_id = info.coursebatch_status_id;
    batchInfo.coursebatch_status = info.coursebatch_status;
    batchInfo.coursebatch_type_id = info.coursebatch_type_id;
    batchInfo.coursebatch_max_students = info.coursebatch_max_students;
    batchInfo.discounted_fee = info.discounted_fee;
    batchInfo.status_id = particularBatch.status_id;

    let newBatches = [...batch];
    let index = newBatches.findIndex(item => item.coursebatch_id === info.coursebatch_id);
    newBatches[index]['coursebatch_name'] = courseData?.course_id;
    newBatches[index]['coursebatch_name'] = info.coursebatch_name;
    newBatches[index]['coursebatch_userid'] = info.coursebatch_userid;
    newBatches[index]['coursebatch_startdate'] = (info.coursebatch_startdate).toISOString().split('T')[0];
    newBatches[index]['coursebatch_enddate'] = (info.coursebatch_enddate).toISOString().split('T')[0];
    newBatches[index]['join_by_date'] = (info.join_by_date).toISOString().split('T')[0];
    newBatches[index]['coursebatch_starttime'] = info.coursebatch_starttime;
    newBatches[index]['batch_url'] = info.batch_url;
    newBatches[index]['coursebatch_status_id'] = info.coursebatch_status_id;
    newBatches[index]['coursebatch_status'] = info.coursebatch_status;
    newBatches[index]['coursebatch_type_id'] = info.coursebatch_type_id;
    newBatches[index]['coursebatch_max_students'] = info.coursebatch_max_students;
    newBatches[index]['discounted_fee'] = info.discounted_fee;
    newBatches[index]['status_id'] = particularBatch.status_id;

    setBatch(newBatches);

    props.updateBatch(batchInfo);
    setShow(false);
  }

  const handleRadioChange = (val) => {
    let newBatch = { ...particularBatch };
    newBatch.status_id = val;
    setParticularBatch(newBatch);
  }

  const onCreateSubmitHandler = (info) => {
    const formData = new FormData()

    formData.append("coursebatch_name", info.coursebatch_name);
    formData.append("coursebatch_courseid", courseData?.course_id);
    formData.append("coursebatch_userid", info.coursebatch_userid);
    formData.append("coursebatch_startdate", (info.coursebatch_startdate).toISOString().split('T')[0]);
    formData.append("coursebatch_enddate", (info.coursebatch_enddate).toISOString().split('T')[0]);
    formData.append("join_by_date", (info.join_by_date).toISOString().split('T')[0]);
    formData.append("coursebatch_starttime", info.coursebatch_starttime);
    formData.append("batch_url", info.batch_url);
    formData.append("coursebatch_status", info.coursebatch_status);
    formData.append("coursebatch_type_id", info.coursebatch_type_id);
    formData.append("coursebatch_max_students", info.coursebatch_max_students);
    formData.append("discounted_fee", info.discounted_fee);
    formData.append("status_id", info.status_id);
   
   
    props.updateBatch(formData);
   
    setTimeout(() => {
      
      setShowModal(false);
    }, 3000);
  }

  const handleCourseChange = (id) => {
    let course = props.allCourses.find(item => item.course_id === parseInt(id));
    setCourseData(course);
    setCourseName(course?.course_name);
    setCourseId(course?.course_id);
    setBatch(course?.batch);

    const allBatchName = course?.batch.map((data, key) => {
      return data.coursebatch_name
    })
    setBatchName(allBatchName)
  }

  const handleTrainer = (id,coursebatch_id) => {
    let selectedTrainer = usersData.filter(item => item.id === parseInt(id));
    setNewTrainer(selectedTrainer);

    let temp = [...batch];
    let index = temp.findIndex(item => item.coursebatch_id === coursebatch_id);
    temp[index]['trainer'] = null;
    temp[index]['trainer'] = selectedTrainer[0];

    setBatch(temp);
  }

  const handleCloseCourseModal = () => {
    setShowCourseModal(false);
  }

  const onCourseSubmitHandler = (info) => {
    let assignInfo = {
      courseId: courseId,
      batchId: info.batch_id,
      userId: info.user_id
    }
    props.assignCourse(assignInfo)
  }

  const handleModal = (id) => {
    props.getEnroll(id);
    setShowListModal(true)
  }

  const handleCloseListModal = () => {
    setShowListModal(false)
  }

  return (
    <Styles>
      <Layout>
        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                  <h5>Batch List</h5>
                </div>
              </div>
              <Card>
                <Card.Body>
                  <Row className="m-3">
                    <Col style={{ textAlign: 'end' }}>
                      <label htmlFor="course" className='mt-2'>Select Course</label>
                    </Col>
                    <Col>
                      <select className='form-control mb-2' name="course_id" onChange={(e) => handleCourseChange(e.target.value)}>
                        <option value="">Select</option>
                        {(props.allCourses && props.allCourses.length) ? props.allCourses.map((course, index) => {
                          return <option value={course.course_id} key={index}>
                            {course.course_name}</option>
                        }) : ''}
                      </select>
                    </Col>
                    <Col>
                      {(batch && batch.length) ? <Button variant="primary" onClick={() => setShowCourseModal(true)}>Assign Student</Button> : ''}
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                      <Button variant="primary" onClick={() => setShowModal(true)}>Add New</Button>
                    </Col>


                    {(batch && batch.length) ?
                      <DataTableComponent
                        columns={columns}
                        data={batch}
                      /> : ''}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* edit batch modal */}
        <Modal show={show} size={"lg"} onHide={handleClose}>
          <ModalStyles >
            <Modal.Header closeButton>
              <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Edit Batch for {courseName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={particularBatch}
                validationSchema={editBatchSchema}
                validateOnChange={false}
                enableReinitialize={true}
                onSubmit={(formData) => {
                  onEditSubmitHandler(formData);
                }}
              >
                {formProps => {
                  const { isSubmitting, errors, touched, values, setFieldValue } = formProps;
                  return (
                    <Form>
                      <div className="form modal-padding">
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="name" style={{ fontWeight: 500 }}>Batch Name</label>
                            <Field type="text" name="coursebatch_name" id="batchName" className="form-control mb-3" placeholder="Batch Name" defaultValue={particularBatch.coursebatch_name} />
                            <span className="error-message">{(errors.coursebatch_name && touched.coursebatch_name) ? errors.coursebatch_name : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Batch Types</label>
                            <Field as="select" name="coursebatch_type_id" className="form-control mb-3">
                              <option value="">Select</option>
                              {(props.allBatchTypes && props.allBatchTypes.length) ? props.allBatchTypes.map((batch, index) => {
                                return <option value={batch.batch_type_id} key={index} selected={(batch.batch_type_id === particularBatch.coursebatch_type_id) ? 'selected' : null}>
                                  {batch.batch_type_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.coursebatch_type_id && touched.coursebatch_type_id) ? errors.coursebatch_type_id : null}</span>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="startdate" style={{ fontWeight: 500 }}>Start Date</label>
                             <DatePicker 
                              selected={values.coursebatch_startdate}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="coursebatch_startdate"
                              onChange={date => setFieldValue('coursebatch_startdate', date)}
                            /> 
                            <span className="error-message">{(errors.coursebatch_startdate && touched.coursebatch_startdate) ? errors.coursebatch_startdate : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="enddate" style={{ fontWeight: 500 }}>End Date</label>
                            <DatePicker 
                              selected={values.coursebatch_enddate}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="coursebatch_enddate"
                              onChange={date => setFieldValue('coursebatch_enddate', date)}
                            /> 
                            <span className="error-message">{(errors.coursebatch_enddate && touched.coursebatch_enddate) ? errors.coursebatch_enddate : null}</span>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="joindate" style={{ fontWeight: 500 }}>Join By Date</label>
                            <DatePicker 
                              selected={values.join_by_date}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="join_by_date"
                              onChange={date => setFieldValue('join_by_date', date)}
                            />
                            <span className="error-message">{(errors.join_by_date && touched.join_by_date) ? errors.join_by_date : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="url" style={{ fontWeight: 500 }}>Batch Meet URL</label>
                            <Field type="text" name="batch_url" id="batch_url" className="form-control mb-3" placeholder="Batch Meet URL" defaultValue={values.batch_url} />
                            <span className="error-message">{(errors.batch_url && touched.batch_url) ? errors.batch_url : null}</span>
                          </p>  
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="desc" style={{ fontWeight: 500 }}>Start Time</label>
                            <TimePicker 
                              disableClock={true} 
                              name="coursebatch_starttime" 
                              className="form-control mb-3" 
                              onChange={time => setFieldValue('coursebatch_starttime', time)} 
                              defaultValue={values.coursebatch_starttime} 
                            />
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="batchstatus" style={{ fontWeight: 500 }}>Batch Status</label>
                            <Field as="select" name="coursebatch_status" className="form-control mb-3">
                              <option value="">Select</option>
                              <option value="Ready to start" selected={(values.coursebatch_status === "Ready to start") ? 'selected' : null}>Ready to start</option>
                              <option value="Running" selected={(values.coursebatch_status === "Running") ? 'selected' : null}>Running</option>
                              <option value="Completed" selected={(values.coursebatch_status === "Completed") ? 'selected' : null}>Completed</option>
                              <option value="Cancelled" selected={(values.coursebatch_status === "Cancelled") ? 'selected' : null}>Cancelled</option>
                              <option value="Draft" selected={(values.coursebatch_status === "Draft") ? 'selected' : null}>Draft</option>
                            </Field>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="trainer" style={{ fontWeight: 500 }}>Trainer</label>
                            <Field as="select" name="coursebatch_userid" className="form-control mb-3" onClick={(e) => handleTrainer(e.target.value,values.coursebatch_id)}>
                              <option value="">Select</option>
                              {usersData.map((user, index) => {
                                return <option value={user.id} key={index} selected={(user.id === values.coursebatch_userid) ? 'selected' : null}>
                                  {user.first_name + ' ' + user.last_name}</option>
                              })}
                            </Field>
                            <span className="error-message">{(errors.coursebatch_userid && touched.coursebatch_userid) ? errors.coursebatch_userid : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="discounted price" style={{ fontWeight: 500 }}>Discounted Fee (course price {courseData?.course_fee})</label>
                            <Field type="number" name="discounted_fee" id="discounted_fee" className="form-control mb-3" placeholder="Discounted Fee" defaultValue={values.discounted_fee} />
                            <span className="error-message">{(errors.discounted_fee && touched.discounted_fee) ? errors.discounted_fee : null}</span>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Max students</label>
                            <Field type="number" name="coursebatch_max_students" id="max_students" className="form-control mb-3" placeholder="Max students" defaultValue={values.coursebatch_max_students} />
                          </p>
                          <p className='col-md-6'>
                            <div id="my-radio-group" className='mb-2 col-md-6' style={{ fontWeight: 500, paddingLeft: '0px' }}>Active</div>
                            <div role="group" aria-labelledby="my-radio-group">
                              <label className="mb-3 mr-3 col-md-3">
                                <Field type="radio" name="status_id" defaultValue="1" checked={(values.status_id === 1) ? 'checked' : null} onChange={() => handleRadioChange(1)} />
                                Yes
                              </label>
                              <label className="mb-3 col-md-3">
                                <Field type="radio" name="status_id" defaultValue="2" checked={(values.status_id === 2) ? 'checked' : null} onChange={() => handleRadioChange(2)} />
                                No
                              </label>
                            </div>
                          </p>
                        </div>
                        <button type="submit" className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </ModalStyles >
        </Modal>

        {/* add batch modal */}
        <Modal show={showModal} size={"lg"} onHide={handleCloseModal}>
          <ModalStyles >
            <Modal.Header closeButton className='d-flex'>
              <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Add Batch for {courseName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Formik
                initialValues={initialValues}
                validationSchema={batchSchema}
                validateOnChange={false}
                onSubmit={(formData) => {
                onCreateSubmitHandler(formData);
                }}
              >
                {formProps => {
                  const { isSubmitting, errors, touched, values, setFieldValue } = formProps;
                  return (
                    <Form>
                      <div className="form modal-padding">
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="name" style={{ fontWeight: 500 }}>Batch Name</label>
                            <Field type="text" name="coursebatch_name" id="bname" className="form-control mb-3" placeholder="Batch Name" />
                            <span className="error-message">{(errors.coursebatch_name && touched.coursebatch_name) ? errors.coursebatch_name : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Batch Types</label>
                            <Field as="select" name="coursebatch_type_id" className="form-control mb-3">
                              <option value="">Select</option>
                              {(props.allBatchTypes && props.allBatchTypes.length) ? props.allBatchTypes.map((batch, index) => {
                                return <option value={batch.batch_type_id} key={index}>
                                  {batch.batch_type_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.coursebatch_type_id && touched.coursebatch_type_id) ? errors.coursebatch_type_id : null}</span>
                          </p>
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="short" style={{ fontWeight: 500 }} className="mr-3">Start Date</label>
                            <DatePicker 
                              selected={values.coursebatch_startdate}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="coursebatch_startdate"
                              onChange={date => setFieldValue('coursebatch_startdate', date)}
                            /> 
                            <span className="error-message">{(errors.coursebatch_startdate && touched.coursebatch_startdate) ? errors.coursebatch_startdate : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="desc" style={{ fontWeight: 500 }} className="mr-3">End Date</label>
                            <DatePicker 
                              selected={values.coursebatch_enddate}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="coursebatch_enddate"
                              onChange={date => setFieldValue('coursebatch_enddate', date)}
                            />
                            <span className="error-message">{(errors.coursebatch_enddate && touched.coursebatch_enddate) ? errors.coursebatch_enddate : null}</span>
                          </p>
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="desc" style={{ fontWeight: 500 }} className="mr-3">Join By Date</label>
                            {/* <Field as="date" name="join_by_date">
                              {({ field }) => <input type="date" className="form-control mb-3" value={field.value} onChange={field.onChange(field.name)} />}
                            </Field> */}
                            <DatePicker 
                              selected={values.join_by_date}
                              dateFormat="dd-MMM-yyyy"
                              className="form-control mb-3"
                              name="join_by_date"
                              onChange={date => setFieldValue('join_by_date', date)}
                            />
                            <span className="error-message">{(errors.join_by_date && touched.join_by_date) ? errors.join_by_date : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="url" style={{ fontWeight: 500 }}>Batch Meet URL</label>
                            <Field type="text" name="batch_url" id="batch_url" className="form-control mb-3" placeholder="Batch Meet URL" />
                            <span className="error-message">{(errors.batch_url && touched.batch_url) ? errors.batch_url : null}</span>
                          </p>
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="desc" style={{ fontWeight: 500 }} className="mr-3">Start Time</label>
                            <TimePicker 
                              disableClock={true} 
                              name="coursebatch_starttime" 
                              className="form-control mb-3" 
                              onChange={time => setFieldValue('coursebatch_starttime', time)} 
                            />
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Batch Status</label>
                            <Field as="select" name="coursebatch_status" className="form-control mb-3">
                              <option value="">Select</option>
                              <option value="Ready to start" >Ready to start</option>
                              <option value="Running">Running</option>
                              <option value="Completed">Completed</option>
                              <option value="Cancelled">Cancelled</option>
                              <option value="Draft">Draft</option>
                            </Field>
                          </p>
                        </div> 
                        <div className='row'> 
                          <p className='col-md-6'>
                            <label htmlFor="trainer" style={{ fontWeight: 500 }}>Trainer</label>
                            <Field as="select" name="coursebatch_userid" className="form-control mb-3">
                              <option value="">Select</option>
                              {usersData.map((user, index) => {
                                return <option value={user.id} key={index}>
                                  {user.first_name + ' ' + user.last_name}</option>
                              })}
                            </Field>
                            <span className="error-message">{(errors.coursebatch_userid && touched.coursebatch_userid) ? errors.coursebatch_userid : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="discounted price" style={{ fontWeight: 500 }}>Discounted Fee (course price {courseData?.course_fee})</label>
                            <Field type="number" name="discounted_fee" id="discounted_fee" className="form-control mb-3" placeholder="Discounted Fee" />
                            <span className="error-message">{(errors.discounted_fee && touched.discounted_fee) ? errors.discounted_fee : null}</span>
                          </p>   
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Max students</label>
                            <Field type="number" name="coursebatch_max_students" id="max_students" className="form-control mb-3" placeholder="Max students" />
                          </p> 
                          <p className='col-md-6'>
                            <div id="my-radio-group" className="mb-2 col-md-6" style={{ fontWeight: 500, paddingLeft: '0px' }}>Active</div>
                            <div role="group" aria-labelledby="my-radio-group">
                              <label className="mb-3 mr-3 col-md-3">
                                <Field type="radio" name="status_id" value="1" checked />
                                Yes
                              </label>
                              <label className="mb-3 col-md-3">
                                <Field type="radio" name="status_id" value="2" />
                                No
                              </label>
                            </div>
                          </p>
                        </div>
                        <button type="submit" className="text-center mt-2 btn btn-primary btn-block" style={{ fontWeight: 500 }} disabled={isSubmitting}>
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>Create</span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </ModalStyles >
        </Modal>

        {/* assign course modal */}
        <Modal show={showCourseModal} onHide={handleCloseCourseModal}>
          <ModalStyles >
            <Modal.Header closeButton className='d-flex'>
              <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Assign Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Formik
                initialValues={courseValues}
                validationSchema={courseSchema}
                validateOnChange={false}
                onSubmit={(formData) => {
                  onCourseSubmitHandler(formData);
                }}
              >
                {formProps => {
                  const { isSubmitting, errors, touched } = formProps;
                  return (
                    <Form>
                      <div className="form modal-padding">
                        <p>
                          <label htmlFor="course" style={{ fontWeight: 500 }}>Course Name : </label>
                          <div className='form-control mb-3'>{courseName}</div>
                        </p>

                        <p>
                          <label htmlFor="batch" style={{ fontWeight: 500 }}>Batches</label>
                          <Field as="select" name="batch_id" className="form-control mb-3">
                            <option value="">Select</option>
                            {(batch && batch.length) ? batch.map((batch, index) => {
                              return <option value={batch.coursebatch_id} key={index}>
                                {batch.coursebatch_name}</option>
                            }) : null}
                          </Field>
                          <span className="error-message">{(errors.batch_id && touched.batch_id) ? errors.batch_id : null}</span>
                        </p>

                        <p>
                          <label htmlFor="users" style={{ fontWeight: 500 }}>Users</label>
                          <Field as="select" name="user_id" className="form-control mb-3">
                            <option value="">Select</option>
                            {normalUsers.map((user, index) => {
                              return <option value={user.user_id} key={index}>
                                {user.first_name + ' ' + user.last_name}</option>
                            })}
                          </Field>
                          <span className="error-message">{(errors.batch_id && touched.batch_id) ? errors.batch_id : null}</span>
                        </p>

                        <button type="submit" style={{ fontWeight: 500 }} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>Assign Course</span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCourseModal}>
                Close
              </Button>
            </Modal.Footer>
          </ModalStyles >
        </Modal>

        {/* students list modal */}
        <Modal size="lg" show={showListModal} onHide={handleCloseListModal}>
          <Modal.Header closeButton className='d-flex'>
            <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Students Joined</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(props.enrollData && props.enrollData.length) ?
              <DataTableComponent
                title="Students List"
                columns={userColumns}
                data={props.enrollData} /> : <div className='text-center'><FormLoader /></div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseListModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </Layout>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  allCourses: state.admin.courses,
  allBatches: state.admin.batches,
  allBatchTypes: state.admin.batchTypes,
  allUsers: state.admin.users,
  enrollData: state.admin.enroll,
  updatedBatch: state.admin.updateBatch
});

export default connect(mapStateToProps, { getCourses, getBatch, updateBatch, deleteBatch, getBatchTypes, getUsers, assignCourse, getEnroll })(Batch);