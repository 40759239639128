import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import FormLoader from '../../components/FormLoader';

import { getUser } from '../../utils/UserDetails'
import { loginUser, setLoginSuccessState } from '../../actions/auth.js';
import GoogleLoginComponent from './GoogleLoginComponent';

const Login = (props) => {
  const history = useHistory();
  const initialValues = {
    username: "",
    password: "",
  };

  const signInSchema = Yup.object().shape({
    username: Yup.string()
      .required('Enter Your Email')
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Enter valid email"),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password should be 8 chars minimum"),
  });

  const [activeRole, setActiveRole] = useState('');
  const [selectRole, setSelectRole] = useState('');
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [counter, setCounter] = useState(0)
  const [isPwdLabel, setPwdLabel] = useState(null);
  const [signInData, setSignInData] = useState({ errMsg: "", isLoading: false });
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setPwdLabel('');
    setCurrentUser(getUser())
  }, [])

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [currentUser])

  useEffect(() => {
    if (props.loginError) {
      setSignInData({
        errMsg: props.loginError,
      })
      console.log(props.loginError)
      setTimeout(() => {
        setSignInData(prevData => {
          return { ...prevData, errMsg: "" }
        })
      }, 2000);
    }
  }, [props.loginError])

  useEffect(() => {
    if (props.loginSuccess) {
      let resultData = props.loginSuccess;
      let redirectLink = props.history.location.state?.from?.pathname || '/';

      props.removeLoginSuccess('')
      history.push(redirectLink)
    }
  }, [props.loginSuccess])

  const setRoleData = () => {
    setActiveRole(selectRole);
  }

  const onSignInHandler = (formData, setSubmitting, setFieldValue) => {
    let loginData = {};

    if (formData.username) {
      loginData.username = formData.username;
    }
    if (formData.password) {
      loginData.password = formData.password;
    }
    props.handleLoginSubmit(loginData, setSubmitting, setFieldValue)
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">

        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Log In" />

        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Log In</h3>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange={false}
                    onSubmit={(formData, { setSubmitting, setFieldValue }) => {
                      setSignInData({ errMsg: "", isLoading: true })
                      onSignInHandler(formData, setSubmitting, setFieldValue);
                    }}
                  >
                    {formProps => {
                      const { values, isSubmitting, setFieldTouched, setFieldValue, errors, touched } = formProps;
                      return (
                        <Form>
                          <div className="form">
                            <p className="form-control">
                              <label htmlFor="login_user">Email</label>
                              <Field type="email" name="username" id="username" className="input-cls" placeholder="Email" />
                              <span className="error-message">{(errors.username && touched.username) ? errors.username : null}</span>
                            </p>
                            <p className="form-control">
                              <label htmlFor="login_password">Password</label>
                              <Field type="password" name="password" id="password" className="input-cls" placeholder="Password" />
                              <span className="error-message">{(errors.password && touched.password) ? errors.password : null}</span>
                            </p>
                            <span className="error-message mt-2">{signInData.errMsg}</span>
                            <button type="submit" className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                              {isSubmitting ? (
                                <FormLoader />
                              ) : (
                                <span>Log In</span>
                              )}
                            </button>
                            <div className="save-forget-password d-flex justify-content-between">
                              <div className="forget-password">
                                <Link to={process.env.PUBLIC_URL + "/"}>Forget Password?</Link>
                              </div>
                            </div>
                            <div className="not_account-btn text-center">
                              <p>Haven't Any Account Yet? <Link to={process.env.PUBLIC_URL + "/registration"}>Click Here</Link></p>
                            </div>
                            <div className="social-login text-center">
                              <GoogleLoginComponent hist={history} />
                            </div>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />

      </div>
    </Styles>
  )
}

const mapStateToProps = state => ({
  loginSuccess: state.auth.loginSuccess,
  loginError: state.auth.loginError
})

const mapDispatchToProps = (dispatch) => {
  return {
    handleLoginSubmit: (userInfo, setSubmitting, setFieldValue) => dispatch(loginUser(userInfo, setSubmitting, setFieldValue)),
    removeLoginSuccess: () => dispatch(setLoginSuccessState(''))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);