import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "../../config/axios";

import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AuthService from "../../services/auth.service"
import { getUser, setUserSession } from "../../utils/UserDetails";
import jwt_decode from "jwt-decode";

const GoogleLoginComponent = (props) => {
  const [googleUserData, setGoogleUserData] = useState({});
  const history = useHistory();

  const responseGoogleSuccess = (token, data) => {
    let googleData = {};
    if (data) {
      googleData = {
        'first_name': data.given_name,
        'last_name': data.family_name,
        'email': data.email,
        'provider': 'google',
        'provider_id': data.jti,
        'token': token,
        'expires_in': data.exp,
      }
    }

    if (googleData && googleData.email) {
      setGoogleUserData(googleData);
      register(googleData)
    } else {
      alert("Please Try Agian!")
    }
  }

  const register = async (googleData) => {
    let emailData = {
      'email': googleData.email,
    }
    let userData = await AuthService.checkUserExist(emailData);

    if (userData.status === true) {
      let resultData = await AuthService.googleLogin(googleData);

      if (resultData?.status && resultData.status === true) {
        Axios.interceptors.request.use(function (config) {
          config.headers['Authorization'] = (resultData?.data?.api_token) ? `Bearer ${resultData.data.api_token}` : '';
          return config;
        });
        setUserSession(resultData.data);

        if (getUser()) {
          setTimeout(() => {
            if (props.hist && props.hist.location.state) {
              history.push(props.hist.location.state?.from?.pathname)
            } else {
              history.push('/')
            }
          }, 500)
        }
      } else {
        alert("Please Try Again!")
      }
    } else {
      googleRegister(googleData);
    }
  }

  const googleRegister = async (googleData) => {
    googleData.user_role = 'STUDENT';

    let resultData = await AuthService.googleRegister(googleData);
    console.log(resultData)
    if (resultData?.status && resultData.status === true) {
      setUserSession(resultData.data);

      Axios.interceptors.request.use(function (config) {
        config.headers['Authorization'] = (resultData?.data?.api_token) ? `Bearer ${resultData.data.api_token}` : '';
        return config;
      });

      if (resultData.data) {
        if (props.hist && props.hist.location.state) {
          history.push(props.hist.location.state?.from?.pathname)
        } else {
          history.push('/')
        }
      } else {
        history.push("/login");
      }
    } else {
      alert("Please Try Again!")
    }
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID} className="text-center">
      <GoogleLogin
        onSuccess={credentialResponse => {
          responseGoogleSuccess(credentialResponse.credential, jwt_decode(credentialResponse.credential));
        }}
        onError={() => {
          alert('Try Again')
        }}
      />
    </GoogleOAuthProvider>
  )
}

export default GoogleLoginComponent;
