import {
  GET_CATEGORIES,
  GET_COURSE_TYPES,
  GET_COURSES,
  USER,
  ADD_ENROLLMENT,
  GET_ENROLLMENT
} from "../actions/types";

const initialState = {
  keyTable: 1,
  commonError: '',
  text: {
    searchTerm: '',
  }
}

const courseReducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_COURSE_TYPES: {
      return {
        ...state,
        userCoursestypes: payload
      }
    }
    case GET_COURSES: {
      return {
        ...state,
        userCourses: payload
      }
    }
    case GET_CATEGORIES: {
      return {
        ...state,
        userCategories: payload
      }
    }
    case GET_ENROLLMENT: {
      return {
        ...state,
        userEnrollments: payload
      }
    }
    case USER: {
      return {
        ...state,
        userDetails: payload
      }
    }
    case ADD_ENROLLMENT: {
      return {
        ...state,
        enroll: action.payload
      }
    }
    default:
      return state;
  }
}

export default courseReducer;