import React, { Component } from 'react';
import Datas from '../data/about-us/about-us.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";

class AboutUs extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="about-image">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/about-1.jpg`} className="main-img" alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="about-content">
                                    <h4 className="about-title">Cognitude is a global consulting company formed by consultants having more than 2 decades of experience in providing IT Solution and Human Resource Management.</h4>
                                    <p className="about-para"><span>While recruiting the candidates, we found that there is a need to make young graduates employable. We have noticed that the curriculum during graduation were often outdated and many a times it was not supported with right amount of hands-on. In order to accelerate the skill building we started in-house training we started Cognitude Academy for our new recruits. It was big success.</span>
<span>  Now that we have training experience and can share actual development setup with the learners, Cognitude Academy is matured enough to cater to the needs of newly graduated who wish to make a career in IT industry. We have right mix of theoretical and practical training for Real World Industry Problems. Our curriculums are curated by industry leaders and professionals to make it relevant.</span>
<span> We also cater for experienced IT professionals and workforce of corporates in upskilling them in the area of new upcoming courses that are in high demand. For corporates, we even engage with them to offer Tailor-Made courses for their needs.</span>
<span>  We believe that access to right training along with hands-on is the biggest enabler for developing the skills. Through our expert curriculum and involvement of development team in designing the assignments we have made coding, very engaging and easy.</span>
<span>  Our job-ready programs are for students and newly graduated students who need hand-holding and mentorship along the journey. We also promise students assured job opportunities at the end of the program based on their commitment and performance. For working professionals who want to sky-rocket their career we offer courses that enhances their skills in newer technologies.</span>
</p>
                                    {/* <Row>
                                        <Col sm="4">
                                            <div className="counter-box box1 text-center">
                                                <h3><CountUp end={970} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Happy Students</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box2 text-center">
                                                <h3><CountUp end={130} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Teachers</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box3 text-center">
                                                <h3><CountUp end={340} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Courses</p>
                                            </div>
                                        </Col>
                                    </Row> 
                                    <Link className="readmore-btn" to={process.env.PUBLIC_URL + "/about"}>Read More</Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUs
