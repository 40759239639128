import React, { Component } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { PopularStyles } from '../styles/popularCourse.js';
import { getCourses } from '../../../actions/course';

const PopularCourse = (props) => {
    const history = useHistory();
    const [popularCourses, setPopularCourses] = useState([]);

    useEffect(() => {
        if (props.courses && props.courses.data.length) {
          setPopularCourses(props.courses.data);
        }
    }, [props.courses])

    const handleClick = (id) => { 
        history.push('/course/'+id, id)
	}

    return (
        <PopularStyles>
            {/* Popular Course */}
            <div className="popular-course">
                <h5>Popular Courses</h5>
                <div className="popular-items">
                    {
                        popularCourses.map((data, id) => (
                            <div className="item-box d-flex" key={id}>
                                <div className="item-img">
                                    <Link to={process.env.PUBLIC_URL + data.courseLink}><img src={data.course_image_url} alt="" /></Link>
                                </div>
                                <div className="item-content">
                                    <p className="title" style={{cursor:'pointer'}} onClick={() => handleClick(data.course_id)}>{data.course_name}</p>
                                    <ul className="list-unstyled list-inline rating">
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                    </ul>
                                    <p className="price">{data.price}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </PopularStyles>
    )
}


const mapStateToProps = (state) => ({
    courses: state.course.userCourses
});
  
export default connect(mapStateToProps, { getCourses })(PopularCourse);
