import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import ScrollToTop from '../helper/ScrollToTop';
import { GlobalStyle } from "../components/common/styles/global.js";
import HomeOne from '../HomeOne';
import HomeTwo from '../HomeTwo';
import About from '../pages/about/About';
import CourseGrid from '../pages/courses/CourseGrid';
import CourseList from '../pages/courses/CourseList';
import CourseDetails from '../pages/courses/CourseDetails';
import Instructor from '../pages/instructor/Instructors';
import InstructorDetails from '../pages/instructor/InstructorDetails';
import Gallery from '../pages/gallery/Gallery';
import Events from '../pages/events/Events';
import EventDetails from '../pages/events/EventsDetails';
import Login from '../pages/account/Login';
import Register from '../pages/account/Register';
import Contact from '../pages/contact/Contact';
import Faq from '../pages/faq/Faq';
import PageNotFound from '../pages/404/PageNotFound';
import ComingSoon from '../pages/comingsoon/ComingSoon';
import BlogClassic from '../pages/blog/BlogClassic';
import BlogGrid from '../pages/blog/BlogGrid';
import BlogDetails from '../pages/blog/BlogDetails';
import Product from '../pages/shop/Products';
import ProductDetails from '../pages/shop/ProductDetails';
import Cart from '../pages/shop/Cart';
import Home from '../pages/admin/Home';
import AdminLogin from '../pages/admin/Login';
import PublicRoute from './PublicRoute';
import AdminPrivateRoute from './AdminPrivateRoute';
import MyLearnings from '../pages/mylearnings/MyLearnings';
import PrivacyPolicy from '../pages/privacypolicy/PrivacyPolicy';
import TermsAndCondition from '../pages/privacypolicy/TermsAndConditions';
import LearnAndWork from '../pages/privacypolicy/LearnAndWork';
import EmailVerification from '../components/EmailVerification';
import Batch from '../pages/admin/Batch';
import Categories from '../pages/admin/Categories';
import Trainer from '../pages/admin/Users';
import Topics from '../pages/admin/Topics';
import BatchTypes from '../pages/admin/BatchTypes';
import StudentToCourse from '../pages/admin/StudentToCourse';
import HomeComponent from '../pages/admin/HomeComponent';
import Course from '../pages/admin/Courses';
import Courses from '../pages/admin/Courses';
import DeactivatedCourses from '../pages/admin/DeactivatedCourses';
import AdminContact from '../pages/admin/Contact';

const AppRouter = () => (

    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
            <PublicRoute exact path={`${"/"}`} component={HomeOne} />
            <PublicRoute path={`${"/home-two"}`} component={HomeTwo} />
            <PublicRoute path={`${"/about"}`} component={About} />
            <PublicRoute path={`${"/course-grid"}`} component={CourseGrid} />
            <PublicRoute path={`${"/course-list"}`} component={CourseList} />
            <PublicRoute path={`${"/course/:id"}`} children={<CourseDetails />} />
            <PublicRoute path={`${"/instructor"}`} component={Instructor} />
            <PublicRoute path={`${"/instructor-details"}`} component={InstructorDetails} />
            <PublicRoute path={`${"/gallery"}`} component={Gallery} />
            <PublicRoute path={`${"/events"}`} component={Events} />
            <PublicRoute path={`${"/event-details"}`} component={EventDetails} />
            <PublicRoute path={`${"/login"}`} component={Login} />
            <PublicRoute path={`${"/registration"}`} component={Register} />
            <PublicRoute path={`${"/verify-user/:token/:email"}`} component={EmailVerification} />
            <PublicRoute path={`${"/my-learnings"}`} component={MyLearnings} />
            <PublicRoute path={`${"/terms"}`} component={TermsAndCondition} />
            <PublicRoute path={`${"/learn-and-work"}`} component={LearnAndWork} />
            <PublicRoute path={`${"/privacy-policy"}`} component={PrivacyPolicy} />
            <PublicRoute path={`${"/contact"}`} component={Contact} />
            <PublicRoute path={`${"/faq"}`} component={Faq} />
            <PublicRoute path={`${"/404"}`} component={PageNotFound} />
            <PublicRoute path={`${"/coming-soon"}`} component={ComingSoon} />
            <PublicRoute path={`${"/blog-classic"}`} component={BlogClassic} />
            <PublicRoute path={`${"/blog-grid"}`} component={BlogGrid} />
            <PublicRoute path={`${"/blog-details"}`} component={BlogDetails} />
            <PublicRoute path={`${"/products"}`} component={Product} />
            <PublicRoute path={`${"/product-details"}`} component={ProductDetails} />
            <PublicRoute path={`${"/cart"}`} component={Cart} />
            <PublicRoute path={`${"/admin"}`} component={AdminLogin} />
            <AdminPrivateRoute path="/admin-home" component={HomeComponent} />
            <AdminPrivateRoute path="/admin-course" component={Courses} />
            <AdminPrivateRoute path="/deactivated-courses" component={DeactivatedCourses} />
            <AdminPrivateRoute path="/admin-batch" component={Batch} />
            <AdminPrivateRoute path="/admin-batch-types" component={BatchTypes} />
            <AdminPrivateRoute path="/admin-categories" component={Categories} />
            <AdminPrivateRoute path="/admin-trainer" component={Trainer} />
            <AdminPrivateRoute path="/admin-topics" component={Topics} />
            <AdminPrivateRoute path="/admin-student-to-course" component={StudentToCourse} />
            <AdminPrivateRoute path="/admin-contact" component={AdminContact} />
        </Switch>
    </BrowserRouter>
);

export default AppRouter;