import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Container, Card, Row, Col, Button, Modal } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';

import { Formik, Form, Field } from "formik";
import FormLoader from '../../components/FormLoader';
import * as Yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Layout from './layout/Layout';
import { getDeactivatedCourses, getCourseTypes, updateCourse, deleteCourse, getTopics, getCategories, addCourse, getCurrencies, getUsers } from '../../actions/admin';
import DataTableComponent from './DataTableComponent';
import { Styles } from './styles/admin.js';
import AdminHeader from './layout/AdminHeader';
import { ModalStyles } from './styles/home';


const DeactivatedCourses = (props) => {
  const [courseData, setCourseData] = useState([]);
  const [filterTopic, setFilterTopic] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editOptions, setEditOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [particularCourse, setParticularCourse] = useState({});
  const [goal, setGoal] = useState(null);
  const [description, setDescription] = useState(null);
  const [prerequisite, setPrerequisite] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState({});
  const [pending, setPending] = useState(true);
  const FILE_SIZE = 2097152;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  useEffect(() => {
    props.getCourseTypes();
    props.getDeactivatedCourses();
    props.getCategories();
    props.getTopics();
    props.getCurrencies();
    props.getUsers();
  }, [])

  useEffect(() => {
    setCourseData(props.allCourses);
  }, [props.allCourses])

  useEffect(() => {
    if (props.allUsers && props.allUsers.length) {
      let temp = props.allUsers.filter((item) => {
        return item.user_role === 'TRAINER'
      });
      setUsersData(temp);
    }
  }, [props.allUsers])

  useEffect(() => {
    if (props.allCategories && props.allCategories.length) {
      setPending(false);
      props.allCategories.map(option => {
        if (!groupedOptions[getParentName(option.category_parent_id)]) groupedOptions[getParentName(option.category_parent_id)] = [];
        groupedOptions[getParentName(option.category_parent_id)].push({
          value: option.category_id,
          text: option.category_name,
          parent: option.category_parent_id
        });
      });
    }
  }, [props.allCategories])


  useEffect(() => {
    if (filterTopic) {

      let multiTopics = filterTopic.map((topic, index) => {
        return { name: topic.topic_name, id: topic.topic_id }
      })
      setOptions(multiTopics)
    }
  }, [filterTopic])

  const getParentName = (id) => {
    let parent = props.allCategories.find(item => item.category_parent_id === id);
    return parent.category_name;
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.course_id,
      sortable: true,
      width: '75px'
    },
    {
      name: 'Course Name',
      selector: row => row.course_name,
      sortable: true,
    },
    {
      name: 'Topic',
      selector: row => (row.topic !== null) ?
        row.topic.map((item, index) => {
          return (
            <span className='display-flex'>
              <p>{(index ? ', ' : '') + item.topic_name}</p>
            </span>
          )
        }) : null,
      sortable: true,
    },
    {
      name: 'Category',
      selector: row =>  (row.category?.category_name) ? row.category?.category_name : '',
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (row.status_id === 4) ? 'Draft' : 'UnPublished',
      sortable: true,
    },
    {
      name: 'No.of Batches',
      selector: row => row.batch.length,
      sortable: false
    },
    {
      name: 'Course Fee',
      selector: row => row?.course_fee,
      sortable: true,
    },
    {
      name: 'Duration',
      selector: row => row?.duration,
      sortable: false,
    },
    {
      name: 'Trainer',
      selector: row => row?.trainer?.first_name ? (row?.trainer?.first_name + ' ' + row?.trainer?.last_name) : '',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => <Button variant="success" onClick={() => handleButtonClick(row.course_id)}>Edit</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Delete',
      cell: (row) => <Button variant="danger" onClick={() => handleDelete(row.course_id)}>Delete</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  let formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ]

  let modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  const initialValues = {
    course_id: "",
    course_name: "",
    course_shortdescription: "",
    course_description: "",
    course_goal: "",
    course_category_id: "",
    prerequisite: "",
    course_type_id: "",
    currency_id:"",
    course_fee:"",
    duration:"",
    duration_time:"",
    season:"",
    trainer_id:"",
    course_tag_id: "",
    course_image: "",
    status_id: 4
  }

  Yup.addMethod(Yup.mixed, "checkfiletype", function (errorMessage) {
    return this.test(`custom-file-type`, errorMessage, function (value) {

      const { path, createError } = this;

      if (value?.size) {
        return (
          (SUPPORTED_FORMATS.includes(value.type)) || createError({ path, message: errorMessage })
        );
      }

      if (!value) {
        return true;
      }

    });
  });

  const courseSchema = Yup.object().shape({
    course_name: Yup.string()
      .required('Enter Course Name'),
    course_image: Yup.mixed()
      .when('course_id', {
        is: null,
        then: Yup.mixed().required('Image is required'),
        otherwise: Yup.mixed(),
      })
      .checkfiletype("Only the following formats are accepted: .jpeg, .png,  and .jpg"),

    course_category_id: Yup.number()
      .required('Category required')
      .typeError("Select category"),  
    course_tag_id: Yup.number()
      .required('Topic required')
      .typeError("Select topic"),
    course_type_id: Yup.number()
      .typeError("Select course type")
      .required('Course type required'),
    currency_id: Yup.number()
      .typeError("Select currency type")
      .required('Currency type required'),
    course_fee: Yup.number()
      .required('Course Fee required'),
    duration_time: Yup.number()
      .required('Duration required'),        
  });

  const handleButtonClick = (state) => {
    let course = props.allCourses.filter(item => {
      return item.course_id === state
    });
    setParticularCourse(course[0]);
    const topics = course[0].topic.map(item => {
      return { name: item.topic_name, id: item.topic_id }
    });
    setEditOptions(topics);
    setShow(true);
  }

  const handleDelete = (data) => {
    let newCourses = [...courseData];
    let index = newCourses.findIndex(item => item.course_id === data);

    newCourses[index].status_id = 2;
    setCourseData(newCourses);

    let delInfo = {
      course_id: data
    }
    props.deleteCourse(delInfo);
  }

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const onEditSubmitHandler = (info) => {
    const formData = new FormData()
    let temp = getId()

    formData.append("course_id", info.course_id);
    formData.append("course_name", info.course_name);
    formData.append("course_shortdescription", info.course_shortdescription);
    formData.append("course_description", description);
    formData.append("course_goal", goal);
    formData.append("course_category_id", info.course_category_id);
    formData.append("prerequisite", prerequisite);
    formData.append("course_type_id", info.course_type_id);
    formData.append("course_tag_id", JSON.stringify(temp));
    formData.append("currency_id", info.currency);
    formData.append("course_fee", info.course_fee);
    formData.append("duration", info.duration_time + '-' + info.season);
    formData.append("duration_time", info.duration_time);
    formData.append("season", info.season);
    formData.append("trainer_id", info.trainer_id);
    formData.append("course_image", (info?.course_image?.size) ? info.course_image : particularCourse.course_image_url);
    formData.append("status_id", particularCourse.status_id);

    let newCourses = [...courseData];
    let index = newCourses.findIndex(item => item.course_id === info.course_id);
    newCourses[index]['course_name'] = info.course_name;
    newCourses[index]['course_shortdescription'] = info.course_shortdescription;
    newCourses[index]['course_description'] = description;
    newCourses[index]['course_goal'] = goal;
    newCourses[index]['course_category_id'] = info.course_category_id;
    newCourses[index]['prerequisite'] = prerequisite;
    newCourses[index]['course_type_id'] = info.course_type_id;
    newCourses[index]['currency_id'] = info.currency;
    newCourses[index]['course_fee'] = info.course_fee;
    newCourses[index]["duration"] = info?.duration_time + '-' + info?.season;
    newCourses[index]['duration_time'] = info.duration_time;
    newCourses[index]['season'] = info.season;
    newCourses[index]['trainer_id'] = info.trainer_id;
    newCourses[index]['course_tag_id'] = JSON.stringify(temp);
    newCourses[index]['status_id'] = particularCourse.status_id;

    setCourseData(newCourses);

    props.updateCourse(formData);
    setTimeout(() => {
      props.getAdminCourses();
      setShow(false);
    }, 3000);
  }

  const handleRadioChange = (val) => {
    let newCourse = { ...particularCourse };
    newCourse.status_id = val;
    setParticularCourse(newCourse);
  }

  const handleChange = (html) => {
    setDescription(html);
  }

  const handlePre = (html) => {
    setPrerequisite(html);
  }

  const handleGoal = (html) => {
    setGoal(html);
  }

  const onCreateSubmitHandler = (info) => {

    const formData = new FormData()
    let temp = getId()

    formData.append("course_id", info.course_id);
    formData.append("course_name", info.course_name);
    formData.append("course_shortdescription", info.course_shortdescription);
    formData.append("course_description", info.course_description);
    formData.append("course_goal", info.course_goal);
    formData.append("course_category_id", info.course_category_id);
    formData.append("prerequisite", info.prerequisite);
    formData.append("course_type_id", info.course_type_id);
    formData.append("currency_id", info.currency);
    formData.append("course_fee", info.course_fee);
    formData.append("duration", info?.duration_time + '-' + info?.season);
    formData.append("trainer_id", info.trainer_id);
    formData.append("course_tag_id", JSON.stringify(temp));
    formData.append("course_image", info.course_image);
    formData.append("status_id", info.status_id);

    props.addCourse(formData);
    setTimeout(() => {
      props.getAdminCourses();
      setShowModal(false);
    }, 3000);


  }

  const getId = () => {
    let id = []
    id = selectedOptions.map(value => value.id);
    return id
  }

  const renderOptions = options => {
    return options.map(option => {
      return (
        <option key={option.value} value={option.value} selected={option.value === particularCourse.course_category_id}>
          {option.text}
        </option>
      );
    });
  };

  const showNewModal = () => {
    setShowModal(true);
    setParticularCourse({});
  }

  const handleOnChange = (e) => {
    setSelectedOptions(e)
  }

  const handleCategory = (id) => {
    let selectedTopics = props.allTopics.filter(item => {
      return item.category_id === parseInt(id)
    });
    
    setFilterTopic(selectedTopics);
  }

  const history = useHistory();
  const activatedCourse = () =>{ 
    let path = `/admin-course`; 
    history.push(path);
  }

  return (
    <Styles>
      <Layout>

        <Container fluid>
          <Row>
            <Col className='main-content'>
              <div className='row mt-3 mb-3'>
                <div className='col'>
                  <h5>Courses List</h5>
                </div>
                <div className='col' style={{ textAlign: 'end' }}>
                  <Button variant="primary" onClick={activatedCourse} >Show Published Courses</Button>
                </div>
                <div className='col' style={{ textAlign: 'end' }}>
                  <Button variant="primary" onClick={() => setShowModal(true)}>Add New</Button>
                </div>
              </div>
              <Card>
                <Card.Body>
                  <DataTableComponent
                    columns={columns}
                    data={courseData}
                    pending={pending}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* edit course modal */}
        <Modal show={show} size={"lg"} onHide={handleClose}>
          <ModalStyles>
            <Modal.Header closeButton className='d-flex'>
              <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Edit Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Formik
                initialValues={particularCourse}
                validationSchema={courseSchema}
                validateOnChange={false}
                onSubmit={(formData) => {

                  onEditSubmitHandler(formData);
                }}
              >
                {formProps => {
                  const { isSubmitting, values, errors, touched, setFieldValue } = formProps;
                  return (
                    <Form encType="multipart/form-data">
                      <div className="form modal-padding">
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="name" style={{ fontWeight: 500 }}>Course Name</label>
                            <Field type="text" name="course_name" id="courseName" className="form-control mb-3" placeholder="Course Name" defaultValue={particularCourse.course_name} />
                            <span className="error-message">{(errors.course_name && touched.course_name) ? errors.course_name : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="short" style={{ fontWeight: 500 }}>Short Description</label>
                            <Field type="text-area" name="course_shortdescription" id="shortDesc" className="form-control mb-3 text-truncate" placeholder="Short Description" defaultValue={particularCourse.course_shortdescription} />
                          </p>
                        </div>

                        <p>
                          <label htmlFor="desc" style={{ fontWeight: 500 }}>Description</label>
                          <ReactQuill
                            theme={'snow'}
                            onChange={handleChange}
                            defaultValue={particularCourse.course_description}
                            modules={modules}
                            formats={formats}
                            bounds={'.app'}
                            placeholder={'Description'}
                            className="mb-3"
                            key={0}
                          />
                        </p>
                        <p>
                          <label htmlFor="learn" style={{ fontWeight: 500 }}>What you will learn</label>
                          <ReactQuill
                            theme={'snow'}
                            onChange={handleGoal}
                            defaultValue={particularCourse.course_goal}
                            modules={modules}
                            formats={formats}
                            bounds={'.app'}
                            placeholder={'WHAT YOU WILL LEARN'}
                            className="mb-3"
                            key={1}
                          />
                        </p>

                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="category" style={{ fontWeight: 500 }}>Category</label>
                            <Field as="select" name="course_category_id" className="form-control mb-3" onClick={(e) => handleCategory(e.target.value)}>
                              <option value="">Select Category</option>
                              {Object.keys(groupedOptions).map((group, index) => {

                                if (group.parent !== 0) {
                                  return (
                                    <optgroup key={index} label={group}> {renderOptions(groupedOptions[group])}</optgroup>
                                  );
                                }
                              })}
                            </Field>
                            <span className="error-message">{(errors.course_category_id && touched.course_category_id) ? errors.course_category_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="topic" style={{ fontWeight: 500 }}>Topic</label>
                            <Multiselect
                              name="course_tag_id"
                              isObject={true}
                              displayValue="name"
                              selectedValues={editOptions}
                              onRemove={(event) => handleOnChange(event)}
                              onSelect={(event) => handleOnChange(event)}
                              options={options}
                              showCheckbox
                            />
                            <span className="error-message">{(errors.course_tag_id && touched.course_tag_id) ? errors.course_tag_id : null}</span>
                          </p>
                        </div>
                        <p>
                          <label htmlFor="image" style={{ fontWeight: 500 }}>Course Image</label>
                          <input
                            type="file"
                            name="course_image"
                            className="form-control mb-3"
                            onChange={(e) => {
                              setFieldValue("course_image", e.currentTarget.files[0])
                            }}
                          />

                          <span className="error-message">{(errors.course_image && touched.course_image) ? errors.course_image : null}</span>
                        </p>
                        <p>
                          <img src={particularCourse.course_image_url} alt="course image" className="mb-3" width="55" height="55" />
                        </p>
                        <p>
                          <label htmlFor="pre" style={{ fontWeight: 500 }}>Pre Requisite</label>

                          <ReactQuill
                            theme={'snow'}
                            onChange={handlePre}
                            defaultValue={particularCourse.prerequisite}
                            modules={modules}
                            formats={formats}
                            bounds={'.app'}
                            placeholder={'Prerequisite'}
                            className="mb-3"
                            key={3}
                          />
                        </p>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Course Types</label>
                            <Field as="select" name="course_type_id" className="form-control mb-3">
                              <option value="">Select Course Type</option>
                              {(props.allTypes && props.allTypes.length) ? props.allTypes.map((cour, index) => {
                                return <option value={cour.course_type_id} key={index} selected={(cour.course_type_id === particularCourse.course_type_id) ? 'selected' : null}>
                                  {cour.course_type_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.course_type_id && touched.course_type_id) ? errors.course_type_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Currency</label>
                            <Field as="select" name="course_type_id" className="form-control mb-3">
                              <option value="">Select Currency Type</option>
                              {(props.currencies.data && props.currencies.data.length) ? props.currencies.data.map((cour, index) => {
                                return <option value={cour.currency_id} key={index} selected={(cour.currency_id === particularCourse.currency_id) ? 'selected' : null}>
                                  {cour.currency_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.currency_id && touched.currency_id) ? errors.currency_id : null}</span>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Fee</label>
                            <Field type="number" name="course_fee" id="course_fee" className="form-control mb-3" placeholder="Course Fee" defaultValue={particularCourse.course_fee} />
                          </p>
                          <p className='col-md-6 input-group'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Duration</label>
                            <div className='input-group'>
                              <Field type="number" name="duration_time" id="duration_time" className="form-control mb-3" placeholder="Duration" defaultValue={particularCourse.duration_time} />
                              <span className='col' style={{ marginTop: 5 }}> Days</span>
                            </div>
                          </p>
                        </div>
                        <div className="row">
                          <p className='col-md-6'>
                            <label htmlFor="trainer" style={{ fontWeight: 500 }}>Trainer</label>
                            <Field as="select" name="trainer_id" className="form-control mb-3">
                              <option value="">Select</option>
                              {usersData.map((user, index) => {
                                return <option value={user.id} key={index} selected={(user.id === particularCourse.trainer_id) ? 'selected' : null}>
                                  {user.first_name + ' ' + user.last_name}</option>
                              })}
                            </Field>
                            <span className="error-message">{(errors.trainer_id && touched.trainer_id) ? errors.trainer_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Status</label>
                            <Field as="select" name="status_id" className="form-control mb-3">
                              <option value="">Select Status Type</option>
                              <option value="4" label="Draft">Draft</option>  
                              <option value="1" label="Publish">Publish</option>
                              <option value="2" label="Unpublish">UnPublish</option>
                            </Field>
                            <span className="error-message">{(errors.status_id && touched.status_id) ? errors.status_id : null}</span>
                          </p>
                        </div>
                        <button type="submit" style={{ fontWeight: 500 }} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </ModalStyles>
        </Modal>

        {/* add course modal */}
        <Modal show={showModal} size={"lg"} onHide={handleCloseModal} className='addcourse-modal'>
          <ModalStyles>
            <Modal.Header closeButton className='d-flex'>
              <Modal.Title className="flex-grow-1 justify-content-center" style={{ textAlign: 'center' }}>Add Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={courseSchema}
                validateOnChange={false}
                onSubmit={(formData) => {
                  onCreateSubmitHandler(formData);
                }}
              >
                {formProps => {
                  const { values, isSubmitting, errors, touched, setFieldValue } = formProps;
                  return (
                    <Form>
                      <div className="form modal-padding">
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="name" style={{ fontWeight: 500 }}>Course Name</label>
                            <Field type="text" name="course_name" id="courseName" className="form-control mb-3" placeholder="Course Name" />
                            <span className="error-message">{(errors.course_name && touched.course_name) ? errors.course_name : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="short" style={{ fontWeight: 500 }}>Short Description</label>
                            <Field type="text" name="course_shortdescription" id="shortDesc" className="form-control mb-3" placeholder="Short Description" />
                          </p>
                        </div>

                        <p>
                          <label htmlFor="desc" style={{ fontWeight: 500 }}>Description</label>
                          {/* <Field type="text" name="course_description" id="longDesc" className="form-control mb-3" placeholder="Description" /> */}
                          <Field name="course_description" className="mb-3">
                            {({ field }) => <ReactQuill theme={'snow'} value={field.value} onChange={field.onChange(field.name)} modules={modules}
                              formats={formats}
                              bounds={'.app'}
                              placeholder={'Description'}
                              className="mb-3" />}
                          </Field>
                        </p>
                        <p>
                          <label htmlFor="learn" style={{ fontWeight: 500 }}>What you will learn</label>
                          <Field name="course_goal" className="mb-3">
                            {({ field }) => <ReactQuill theme={'snow'} value={field.value} onChange={field.onChange(field.name)} modules={modules}
                              formats={formats}
                              bounds={'.app'}
                              placeholder={'WHAT YOU WILL LEARN'}
                              className="mb-3" />}
                          </Field>
                        </p>

                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="category" style={{ fontWeight: 500 }}>Category</label>
                            <Field as="select" name="course_category_id" className="form-control mb-3" onClick={(e) => handleCategory(e.target.value)}>
                              <option value="">Select Category</option>
                              {Object.keys(groupedOptions).map((group, index) => {
                                if (group.parent !== 0) {
                                  return (
                                    <optgroup key={index} label={group}>{renderOptions(groupedOptions[group])}</optgroup>
                                  );
                                }
                              })}
                            </Field>
                            <span className="error-message">{(errors.course_category_id && touched.course_category_id) ? errors.course_category_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="topic" style={{ fontWeight: 500 }}>Topic</label>
                            <Multiselect
                              name="course_tag_id"
                              isObject={true}
                              displayValue="name"
                              selectedValues={selectedOptions}
                              onRemove={(event) => handleOnChange(event)}
                              onSelect={(event) => handleOnChange(event)}
                              options={options}
                              showCheckbox
                            />

                            <span className="error-message">{(errors.course_tag_id && touched.course_tag_id) ? errors.course_tag_id : null}</span>
                          </p>
                        </div>

                        <p>
                          <label htmlFor="image" style={{ fontWeight: 500 }}>Course Image</label>
                          <input
                            type="file"
                            name="course_image"
                            className="form-control mb-3"
                            onChange={(e) => {
                              setFieldValue("course_image", e.currentTarget.files[0])
                            }}
                          />

                          <span className="error-message">{(errors.course_image && touched.course_image) ? errors.course_image : null}</span>
                        </p>
                        <p>
                          <label htmlFor="pre" style={{ fontWeight: 500 }}>Pre Requisite</label>
                          {/* <Field type="text" name="prerequisite" id="prerequisite" className="form-control mb-3" placeholder="prerequisite" /> */}
                          <Field name="prerequisite" className="mb-3">
                            {({ field }) => <ReactQuill theme={'snow'} value={field.value} onChange={field.onChange(field.name)} modules={modules}
                              formats={formats}
                              bounds={'.app'}
                              placeholder={'Prerequisite'}
                              className="mb-3" />}
                          </Field>
                        </p>

                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Course Types</label>
                            <Field as="select" name="course_type_id" className="form-control mb-3">
                              <option value="">Select course type</option>
                              {(props.allTypes && props.allTypes.length) ? props.allTypes.map((cour, index) => {
                                return <option value={cour.course_type_id} key={index}>
                                  {cour.course_type_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.course_type_id && touched.course_type_id) ? errors.course_type_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Currency</label>
                            <Field as="select" name="currency" className="form-control mb-3">
                              <option value="">Select currency type</option>
                              {(props.currencies.data && props.currencies.data.length) ? props.currencies.data.map((cour, index) => {
                                return <option value={cour.currency_id} key={index}>
                                  {cour.currency_name}</option>
                              }) : ''}
                            </Field>
                            <span className="error-message">{(errors.currency_id && touched.currency_id) ? errors.currency_id : null}</span>
                          </p>
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Fee</label>
                            <Field type="number" name="course_fee" id="course_fee" className="form-control mb-3" placeholder="Course Fee" />
                            <span className="error-message">{(errors.course_fee && touched.course_fee) ? errors.course_fee : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="pre" style={{ fontWeight: 500 }}>Duration</label>
                            <div className='input-group'>
                              <Field type="number" name="duration_time" id="duration_time" className="form-control mb-3" placeholder="Duration" />
                              <span className='col' style={{ marginTop: 5 }}> Days</span>
                            </div>
                            <span className="error-message">{(errors.duration_time && touched.duration_time) ? errors.duration_time : null}</span>
                          </p>
                        </div>
                        <div className='row'>
                          <p className='col-md-6'>
                            <label htmlFor="trainer" style={{ fontWeight: 500 }}>Trainer</label>
                            <Field as="select" name="trainer_id" className="form-control mb-3">
                              <option value="">Select</option>
                              {usersData.map((user, index) => {
                                return <option value={user.id} key={index}>
                                  {user.first_name + ' ' + user.last_name}</option>
                              })}
                            </Field>
                            <span className="error-message">{(errors.trainer_id && touched.trainer_id) ? errors.trainer_id : null}</span>
                          </p>
                          <p className='col-md-6'>
                            <label htmlFor="parent" style={{ fontWeight: 500 }}>Status</label>
                            <Field as="select" name="status_id" className="form-control mb-3">
                              <option value="">Select Status Type</option>  
                              <option value="1" label="Publish">Publish</option>
                              <option value="2" label="UnPublish">UnPublish</option>
                              <option value="4" label="Draft">Draft</option>
                            </Field>
                            <span className="error-message">{(errors.status_id && touched.status_id) ? errors.status_id : null}</span>
                          </p>
                        </div>
                        <button type="submit" style={{ fontWeight: 500 }} className="text-center mt-2 btn btn-primary btn-block" disabled={isSubmitting}>
                          {isSubmitting ? (
                            <FormLoader />
                          ) : (
                            <span>Create</span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </ModalStyles>
        </Modal>

      </Layout>
    </Styles >

  )
}

const mapStateToProps = (state) => ({
  allCategories: state.admin.adminCategories,
  allCourses: state.admin.deactivatedCourses,
  allTypes: state.admin.courseTypes,
  allTopics: state.admin.topics,
  courseAdd: state.admin.addCourse,
  allUsers: state.admin.users,
  currencies:state.admin.currencies
});

export default connect(mapStateToProps, { getDeactivatedCourses, getCategories, getCourseTypes, updateCourse, deleteCourse, getTopics, addCourse, getCurrencies, getUsers })(DeactivatedCourses);