import React, { Component } from 'react';
import { Container, Row, Col, Table, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/privacypolicy';

class LearnAndWork extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper cart-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Learn & Work" />

                    <section className="privacy-policy-area">
                        <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <div className="course-details-top">
                                    <div className="course-tab-list">
                                        <div className='tab-content'>
                                            <div eventKey="overview" className="overview-tab">
                                                <div className="course-desc">
                                                    <h5>Are you looking for Internship in Frontend Web development?</h5>   
                                                    <p>Here is the opportunity for you to Learn from basics of Web Development and gain 3 months work experience in our Cognitude Technologies through 3 months of internship on live projects.</p>
                                                </div>
                                                <div className="course-desc">
                                                    <h5>Learn from Basics?</h5> 
                                                    <p>Complete the course in 1 and a half month covering HTML 5, CSS, JavaScript and React JS with project after completion of each module</p>  
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Gain 3 months of Work Experience?</h5>
                                                    <p>After successful* completion of course you will be put into aggressive internship program in Cognitude Technologies Live projects for 3 months.
                                                    </p>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Why Us ?</h5>
                                                    <p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1. Learn & Work From Home Option
                                                        </li>
                                                        <li>
                                                        2. Online instructor driven training program
                                                        </li>
                                                        <li> 
                                                        3. Internship on Live project 
                                                        </li>
                                                        <li> 
                                                        4. Team of Experience Developers will provide the Internship. 
                                                        </li>
                                                        <li> 
                                                        5. Experience Certificate will be provided after competition of Internship
                                                        </li>
                                                        <li> 
                                                        6. Job after Internship** 
                                                        </li>
                                                        <li> 
                                                        7. Mentorship with the developers 
                                                        </li>
                                                        <li> 
                                                        8. Networking with the Industry experts 
                                                        </li>
                                                    </ul>
                                                    </p>
                                                </div>
                                                <div className="course-learn">
                                                    <h5>Prerequisite:</h5>
                                                    <p>
                                                    <ul className="list-unstyled" style={{padding: '0px 0px 0px 40px'}}>
                                                        <li>
                                                        1.Diploma / Any Degree (with Logical reasoning skills) 
                                                        </li>
                                                        <li>
                                                        2. Access to your own individual Laptop 
                                                        </li>
                                                        <li> 
                                                        3. Stable Internet Connection 
                                                        </li>
                                                        <li> 
                                                        4. Good Attitude to work   
                                                        </li>
                                                    </ul>
                                                    </p>
                                                </div>
                                                <div className="course-desc">
                                                  
                                                    <p>* The student needs to clear the assessment program  
                                                    <br /><br /> ** The interns need to clear the selection process to get into Job with Cognitude <br /><br /> To know details of next Learn and Work batch Kindly contact
                                                    <a href="mailto:Sugitha@cognitude.co.in"><b> Sugitha@cognitude.co.in </b></a> through mail or through mobile: 7305008220</p>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default LearnAndWork
