import React, { Component } from 'react';
import Datas from '../data/hero/hero-slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/heroSlider.js";

class HeroSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev'
            },
            renderPrevButton: () => (
                <div className="swiper-btn slider-button-prev" style={{display:'none'}}><i className="flaticon-arrow-left-th"></i></div>
            ),
            renderNextButton: () => (
                <div className="swiper-btn slider-button-next" style={{display:'none'}}><i className="flaticon-arrow-right-th"></i></div>
            )
        };

        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
                    <Swiper {...settings}>  
                        <div className="slider-item">
                            <div className="image-container">
                                <img src={process.env.PUBLIC_URL + `/assets/images/slider-1.jpg`} className="slider-image" alt="backgroundImage" />
                            </div>
                            <div className="slider-table">
                                <div className="slider-tablecell">
                                    <Container>
                                        <Row>
                                            <Col md="12">
                                                <div className="slider-box slider-box2 text-right">
                                                    <div className="slider-title">
                                                        <p>Welcome To COGNiTUDE Academy. &nbsp;
                                                        <span style={{'fontSize':'12px'}}>( Division of COGNiTUDE Technologies)</span></p>
                                                    </div>
                                                    {/* <div className="slider-title">
                                                        <p>( Division of COGNiTUDE Technologies)</p>
                                                    </div> */}
                                                    <div className="slider-desc">
                                                        <h1>Acquire Knowledge and Experience through <span className='onthejob'>On-The-Job training.</span></h1>
                                                    </div>
                                                    <div className="slider-btn">
                                                        <Link className="slider-btn1" to={process.env.PUBLIC_URL + `/learn-and-work`}>Learn & Work</Link>
                                                        <Link className="slider-btn2" to={process.env.PUBLIC_URL + `/course-grid`}>Our Courses</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </Swiper>
                </section>
            </Styles>
        )
    }
}

export default HeroSlider
