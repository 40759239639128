// export const getDate = (val) => {
//   const date = new Date(val);
//   const lastValue = date.toLocaleDateString('en-GB');

//   return lastValue;
// }

export const getDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MMM-YYYY');
  return lastValue;
}

export const getPriceFormatter =(val, cur) =>{
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: cur,
  
  });

  return formatter.format(val);

}

export const getDays = (start, end) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((new Date(start) - new Date(end)) / oneDay));

  return diffDays;
}