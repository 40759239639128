import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getCourses } from '../../../actions/course';
import CourseSearch from './CourseSearch';
import CourseCategory from './CourseCategory';
import InfiniteScroll from 'react-infinite-scroll-component';

const CourseItemGrid = (props) => {

  const history = useHistory();

  const [courseData, setCourseData] = useState([]);
  const [filterCourseData, setFilterCourseData] = useState(Array.from({length:5}));
  const [search, setSearch] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true)

  useEffect(() => {
    props.getCourses();
  }, [])

  useEffect(() => {
    if (props.courses) {
      let newcategoryCourse = props.courses.data.map((data) => {
        data.isVisible = true
        return data
      })
      setCourseData(newcategoryCourse)
      setFilterCourseData(newcategoryCourse)
    }
  }, [props.courses])

    useEffect(() => {
      if(props?.courses && props.courses.length && (props.courses.length === 0)){
        setHasMoreItems(false)
      }
    },[props.courses])

    const fetchMoreData=()=>{
      setTimeout(() =>{
        setFilterCourseData(filterCourseData.concat(Array.from({length:5})))
      }, 500);
    }

  return (
    <Fragment>
      <Row>
        <Col lg="3" md="4" sm="5">
          <div className="course-sidebar">
            <Row>
              <Col md="12">
                <CourseSearch searching={e => { setSearch(e.target.value); }} />
              </Col>
              <Col md="12">
                <CourseCategory courseData={courseData} setFilterCourseData={setFilterCourseData} />
              </Col>
              {/* <Col md="12">
                <CoursePrice />
              </Col>
              <Col md="12">
                <PopularCourse />
              </Col>
              <Col md="12">
                <CourseTag />
              </Col> */}
            </Row>
          </div>
        </Col>
        <Col lg="9" md="8" sm="7">
          {
            (filterCourseData && filterCourseData.length) ?
             
                // <InfiniteScroll
                //   dataLength={filterCourseData.length}
                //   next={fetchMoreData}
                //   hasMore={hasMoreItems}
                // loader={<Loader.ThreeDots type="ThreeDots" color="#0071c1" height={50} width={50} className="loader text-center" />}
                // scrollableTarget="parentScrollDiv"
                // >
                  <div className="course-items">
                    <Row>
                      {
                        filterCourseData.filter((data) => {
                          if (search === "") {
                            return data
                          }
                          else if (data.course_name.toLowerCase().includes(search.toLowerCase())) {
                            return data
                          }
                        })
                        .map((data, course_id) => {
                          if (data.isVisible) {
                            return (
                              <Col lg="6" md="12" key={course_id}>
                                <div className="course-item" style={{ cursor: 'pointer' }}>
                                  <Link to={`/course/${data.course_id}/${data.course_name}`.split(" ").join("-").toLowerCase()}>
                                    <div className="course-image" style={{ backgroundImage: `url(${data.course_image_url})` }}>
                                      <div className="author-img d-flex">
                                        <div className="img">
                                          <img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="course-content">
                                      <h6 className="heading">{data.course_name}</h6>
                                      <p className="desc text-truncate">{data.course_shortdescription}</p>
                                      {/* <div className="course-face d-flex justify-content-between">
                                    <div className="duration">
                                      <p><i className="las la-clock"></i>120</p>
                                    </div>
                                    <div className="rating">
                                      <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                        <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                        </li>
                                        <li className="list-inline-item">(4.5)</li>
                                      </ul>
                                    </div>
                                    <div className="student">
                                      <p><i className="las la-chair"></i>60</p>
                                    </div>
                                  </div> */}
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            )
                          }
                        })
                      }
                    </Row>
                  </div>
                // </InfiniteScroll>
            
            :  <p className="text-center no-data-found my-2"> No Data Found</p>
          }
        </Col>
      </Row>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  courses: state.course.userCourses
});

export default connect(mapStateToProps, { getCourses })(CourseItemGrid);

