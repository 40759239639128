import React from 'react';
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

import { Styles } from '../styles/admin';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-bootstrap-accordion/dist/index.css';

const LeftMenu = () => {

  const history = useHistory();
  
  return (
    <Styles>
      {/* <div className="d-flex"> */}
        <div className="min-vh-100 d-lg-block d-xl-block d-xxl-block sidebar-style w-25">
          <ul className="list-unstyled components">
            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-home") ? 'active' : ''}`} to="/admin-home">Home</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-course") ? 'active' : ''}`} to="/admin-course">Courses</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-batch") ? 'active' : ''}`} to="/admin-batch">Batches</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-trainer") ? 'active' : ''}`} to="/admin-trainer">Users</Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-student-to-course") ? 'active' : ''}`} to="/admin-student-to-course">Assign Student To Course</Link>
            </li>

            <li className="nav-item dropdown">
              <button className="nav-link menu-items dropdown-toggle" data-toggle="dropdown">Masters <i className="las la-angle-down"></i></button>
              <ul className="dropdown list-unstyled">
                <li className="nav-item">
                  <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-batch-types") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/admin-batch-types"}>Batch Types</Link>
                </li>

                <li className="nav-item">
                  <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-categories") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/admin-categories"}>Categories</Link>
                </li>
                
                <li className="nav-item">
                  <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-topics") ? 'active' : ''}`} to={process.env.PUBLIC_URL + "/admin-topics"}>Topics</Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className={`nav-link menu-items ${(history.location.pathname === process.env.PUBLIC_URL + "/admin-contact") ? 'active' : ''}`} to="/admin-contact">Contacts</Link>
            </li>
          </ul>
        </div>
      {/* </div>  */}
    </Styles>
  );
}

export default LeftMenu;
