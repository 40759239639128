import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`

    .course-details-area {
        padding : 70px 0 78px;
        .course-details-top {
            .heading {
                h4 {
                    color : ${colors.black1};
                    font-weight: 600;
                    line-height: 35px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size : 20px;
                    }
                }
            }

            .course-top-overview {
                margin-bottom : 25px;
                .overviews {
                    .category {
                        margin-right : 20px;
                        padding-right : 20px;
                        border-right : 1px solid ${colors.border3};
                        h6 {
                            color : ${colors.black2};
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                        p {
                            font-size : 15px;
                            color : ${colors.text3};
                            font-weight: 500;

                            @media(max-width: 767px) {
                                font-size : 14px;
                            }
                        }
                    }
                }

                @media(max-width: 480px) {
                    display : none;
                }
            }
        }

        .course-item {
            border: 1px solid ${colors.border1};
            border-radius : 5px;
            transition : all 0.2s ease;
            overflow : hidden;
            margin-bottom: 30px;

            .course-image {
                width              : 100%;
                height             : 240px;
                background-size    : cover;
                background-position: center;
                background-repeat  : no-repeat;
                border-radius : 5px 5px 0 0;
                position: relative;

                .author-img {
                    position: absolute;
                    left    : 20px;
                    bottom  : 20px;

                    img {
                        max-width: 40px;
                        border-radius : 50%;
                        margin-right: 5px;
                    }

                    .title {
                        background: #ffffff;
                        padding   : 3px 8px;
                        border-radius : 5px;

                        p {
                            font-size    : 12px;
                            color        : ${colors.black1};
                            font-weight  : 500;
                            margin-bottom: -4px;
                        }

                        span {
                            font-size  : 11px;
                            color      : ${colors.text3};
                            font-weight: 500;
                        }
                    }

                }

                .course-price {
                    p {
                        font-size  : 16px;
                        color      : #ffffff;
                        background : ${colors.bg1};
                        position   : absolute;
                        right      : 20px;
                        bottom     : 20px;
                        padding    : 8px 10px;
                        font-weight: 500;
                        border-radius : 5px;
                    }
                }

                @media(max-width: 767px) {
                    height: 185px;
                }
            }

            .course-content {
                background: #fff;
                padding   : 20px 25px;
                
                    h6.heading {
                        color        : ${colors.black1};
                        font-weight  : 600;
                        display      : inline-block;
                        margin-bottom: 12px;

                        &:hover {
                            color: ${colors.green};
                        }
                    }

                p.desc {
                    font-size     : 14px;
                    color         : ${colors.text3};
                    line-height   : 25px;
                    // border-bottom : 1px solid ${colors.border1};
                    padding-bottom: 10px;
                    margin-bottom : 12px;
                }

                .course-face {

                    .duration,
                    .student {
                        p {
                            font-size: 13px;
                            color    : ${colors.text3};

                            i {
                                font-size     : 16px;
                                color         : ${colors.green};
                                vertical-align: text-bottom;
                                margin-right  : 3px;
                            }
                        }
                    }

                    .rating {
                        ul {
                            li {
                                margin-right: 0;

                                i {
                                    font-size: 14px;
                                    color    : ${colors.yellow};
                                }

                                &:last-child {
                                    font-size: 13px;
                                    color    : ${colors.text3};
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            }
        }
    }
    .enroll-text {
        font-size: 15px;
        color: #969696;
        line-height: 25px;
        margin-bottom: 40px;
    }
`;